import { useEffect } from "react";
import { fetchWebViewData, getCode } from "../../services/bi.api";
import "../../styles/client/budgetInsightWebView.scss";
import { WebViewData } from "../../types/webviewData.type";
import { useNavigate } from "react-router-dom";
import { routeType } from "../../routes";
import { uniq, flatten } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, userUpdate } from "../../slice/login.slice";
import { useTranslation } from "react-i18next";
import {
  BudgetInsightConnector,
  Case,
  ClientDocumentType,
} from "../../types/caseForm.type";
import Loader from "../../components/common/loading";
import { getMe } from "../../services/user.api";
import { customBackContext, clearBackContext } from "../../slice/back.slice";
import { JwtPayload } from "../../types/JwtPayload.type";

export default function ClientBi(props: {
  isCoBorrower: boolean;
  // isMaster: boolean;
  selectedDocumentTypes: ClientDocumentType[];
  user: JwtPayload;
  endRedirectionPage?: string;
  selectedConnectors?: BudgetInsightConnector[];
}) {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const webview = await fetchWebViewData();
        setUrlIframe(webview);
      } catch (err) {
        navigate(routeType.home);
      }
    })();
  });

  const setUrlIframe = async (webviewData: WebViewData) => {
    let biCode = "";
    if (props.user.biConnected) {
      biCode = (await getCode(props.user.id)).code;
    }
    const selectedCdts = props.selectedDocumentTypes.filter(
      (cdt) =>
        cdt.clientId === null ||
        (props.isCoBorrower
          ? cdt.clientId !== props.user.id
          : cdt.clientId === props.user.id)
    );

    if (webviewData && selectedCdts.length > 0) {
      const stateToSend =
        selectedCdts.map((c) => c.id).join("|") +
        "_" +
        props.user.id +
        (props.endRedirectionPage ? `_${props.endRedirectionPage}` : "");
      const queryParams = new URLSearchParams({
        client_id: webviewData.clientId,
        redirect_uri: webviewData.webviewRedirectUrl,
        state: stateToSend,
      });

      let budgetInsightConnectors = uniq(
        flatten(
          selectedCdts.map((c) => c.documentType?.budgetInsightConnectorIds)
        ).filter(Boolean)
      );
      if (props.selectedConnectors && props.selectedConnectors.length > 0) {
        budgetInsightConnectors = budgetInsightConnectors.filter((bic) =>
          props.selectedConnectors!.map((c) => "" + c.id).includes(bic!)
        );
      }
      if (budgetInsightConnectors.length > 0) {
        let capabilities = ["document"];
        // if (
        //   [
        //     "PERSONAL_ACCOUNT_STATEMENT",
        //     "BUSINESS_ACCOUNT_STATEMENT",
        //     "SCI_ACCOUNT_STATEMENT",
        //   ].includes(clientDocumentType.documentType?.name || "")
        // ) {
        //   // if bank accounts, need to add bank management to have account ownership
        //   capabilities.push("bank");
        // }
        queryParams.append("connector_capabilities", capabilities.join(","));
        queryParams.append("connector_ids", budgetInsightConnectors?.join(","));
      }

      let url: string = webviewData.webviewConnectUrl;
      if (biCode !== "") {
        queryParams.append("code", biCode);
      }
      //TODO: do proper url encode and use https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams (native) or https://www.npmjs.com/package/qs instead of string concatenation
      // CARE : budget insight doesn't accept redirect_uri or code  when url encoded
      window.location.href = url + "?" + queryParams.toString();
    }
  };

  return (
    <div className="webview-container">
      <Loader />
    </div>
  );
}
