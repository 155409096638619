import { Button, Grid } from "@mui/material";
import { uniq } from "lodash";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getBiDocumentsForConnectionIds } from "../../services/bi.api";
import { getBIClientDoucmentTypesChildren } from "../../services/case.api";
import {
  ClientDocumentType,
  ClientDocumentTypeStatus,
} from "../../types/caseForm.type";
import CaseDocumentBiRow from "./caseDocumentBiRow";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getSignedUrl } from "../../services/upload.api";
import React from "react";
import Loader from "../common/loading";
import { DocumentType } from "../../types/documentList";
import "../../styles/case/caseValidation.scss";
import { ReactComponent as ValidatedIcon } from "../../resources/icons/restitution/validated.svg";

function showDocument(document: { name: string }, documentType: DocumentType) {
  const currentYear = new Date().getFullYear();
  const rules = [
    {
      docTypeName: (name: string) => name.includes("N-1"),
      docName: (name: string) => name.includes("" + (currentYear - 1)),
    },
    {
      docTypeName: (name: string) => name.includes("N-2"),
      docName: (name: string) => name.includes("" + (currentYear - 2)),
    },
    {
      docTypeName: (name: string) => name.includes("N-3"),
      docName: (name: string) => name.includes("" + (currentYear - 3)),
    },
    {
      docTypeName: (name: string) => name.includes("TAX_RETURN"),
      docName: (name: string) => name.toUpperCase().includes("REVENUS"),
    },
    {
      docTypeName: (name: string) => name.includes("HOUSING_TAX"),
      docName: (name: string) => name.toUpperCase().includes("HABITATION"),
    },
    {
      docTypeName: (name: string) => name.includes("PROPERTY_TAX"),
      docName: (name: string) => name.toLowerCase().includes("foncières"),
    },
  ];
  return rules
    .map((rule) =>
      rule.docTypeName(documentType.name) ? rule.docName(document.name) : true
    )
    .every((res) => res);
}

export default function BudgetInsightSelectDocument(props: {
  cdt: ClientDocumentType;
  onSubmit: Function;
  onCancel: Function;
}) {
  const { t } = useTranslation();
  const params = useParams();
  const [clientDocumentTypes, setClientDocumentTypes] =
    useState<ClientDocumentType[]>();
  const [biDocuments, setBiDocuments] = useState<{ [op: string]: any }>();
  const [cdtShowMore, setCdtShowMore] = useState<{ [op: string]: boolean }>({});
  const [clientDocumentTypesDocuments, setClientDocumentTypesDocuments] =
    useState<{ [op: string]: number[] }>();
  useEffect(() => {
    (async () => {
      if (params.id) {
        const cdts = await getBIClientDoucmentTypesChildren(props.cdt.id);
        setClientDocumentTypes(cdts);
        const cdtd: { [op: string]: number[] } = {};
        const showMore = cdtShowMore;
        cdts.forEach((x) => {
          if (x.status !== ClientDocumentTypeStatus.VALIDATED) {
            cdtd[x.id] = [];
          }

          if (!showMore[x.id]) {
            showMore[x.id] = false;
          }
        });
        setCdtShowMore({ ...showMore });
        setClientDocumentTypesDocuments(cdtd);

        const bIConnectionIds = uniq(
          cdts.flatMap((x) => x.budgetInsightConnectionIds)
        );
        setBiDocuments(
          await getBiDocumentsForConnectionIds(params.id, bIConnectionIds)
        );
      }
    })();
  }, [props.cdt]);

  const openDocUrl = async (docId: string) => {
    const url = await getSignedUrl(docId);
    window.open(url, "_blank", "fullscreen=yes");
  };

  const getBiDocumentRows = (
    cdt: ClientDocumentType,
    doc: {
      name: string;
      url: string;
      id: number;
      access_token: string;
    }
  ) => {
    return (
      <Grid item xs={12}>
        <CaseDocumentBiRow
          biDoc={doc}
          handleClick={() => {
            let tmp = clientDocumentTypesDocuments;

            if (
              clientDocumentTypesDocuments &&
              clientDocumentTypesDocuments[cdt.id] &&
              clientDocumentTypesDocuments[cdt.id].find((x) => x === doc.id)
            ) {
              if (tmp) {
                tmp[cdt.id] = tmp[cdt.id].filter((x) => x !== doc.id);
                setClientDocumentTypesDocuments({
                  ...tmp,
                });
              }
            } else {
              if (!tmp) {
                tmp = {};
              }
              if (!tmp[cdt.id]) {
                tmp[cdt.id] = [];
              }
              tmp[cdt.id].push(doc.id);

              setClientDocumentTypesDocuments({
                ...tmp,
              });
            }
          }}
          isSelected={
            clientDocumentTypesDocuments
              ? !!clientDocumentTypesDocuments[cdt.id].find(
                  (selectedDoc) => selectedDoc === doc.id
                )
              : false
          }
        />
      </Grid>
    );
  };

  const getDocTypeTitleRow = (cdt: ClientDocumentType) => {
    return (
      <>
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            alignItems="stretch"
            justifyContent={"center"}
          >
            <h4>{t("doc-type." + cdt?.documentType?.name)} </h4>
          </Grid>
          {cdt.status === ClientDocumentTypeStatus.VALIDATED && (
            <Button
              onClick={() => {
                openDocUrl(cdt.document.id);
              }}
              variant="outlined"
            >
              {t("documents.show-selected")}&nbsp;
              <ValidatedIcon />
            </Button>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      {(!biDocuments || !clientDocumentTypes) && <Loader />}
      {biDocuments && clientDocumentTypes && (
        <Grid container rowSpacing={2} alignItems="center" marginBottom={1}>
          {clientDocumentTypes?.map((cdt) => {
            return (
              <React.Fragment>
                <Grid
                  container
                  alignItems="center"
                  direction={"row"}
                  spacing={2}
                  key={"lines_" + cdt.id}
                >
                  {getDocTypeTitleRow(cdt)}
                  {cdt.status !== ClientDocumentTypeStatus.VALIDATED &&
                    cdt.budgetInsightConnectionIds.map((connectionId) => {
                      return (
                        <>
                          {biDocuments &&
                            biDocuments[connectionId] &&
                            biDocuments[connectionId]
                              .filter(
                                (doc: {
                                  name: string;
                                  url: string;
                                  id: number;
                                  access_token: string;
                                }) => {
                                  if (
                                    !cdtShowMore[cdt.id] &&
                                    cdt.documentType
                                  ) {
                                    return showDocument(doc, cdt.documentType);
                                  }

                                  return true;
                                }
                              )
                              .map(
                                (doc: {
                                  name: string;
                                  url: string;
                                  id: number;
                                  access_token: string;
                                }) => {
                                  return getBiDocumentRows(cdt, doc);
                                }
                              )}
                        </>
                      );
                    })}
                </Grid>
                {cdt.status !== ClientDocumentTypeStatus.VALIDATED && (
                  <Grid
                    container
                    spacing={2}
                    rowSpacing={2}
                    marginBottom={2}
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      xs={11}
                      justifyContent={"flex-end"}
                      style={{ marginTop: "5px" }}
                    >
                      <Grid item>
                        <Button
                          style={{ textTransform: "uppercase" }}
                          onClick={() => {
                            const showMore = cdtShowMore;
                            showMore[cdt.id] = !showMore[cdt.id];
                            setCdtShowMore({ ...showMore });
                          }}
                        >
                          {cdtShowMore[cdt.id]
                            ? t("global.show-less")
                            : t("global.show-more")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      )}
      <Grid container paddingTop={3} justifyContent={"flex-end"}>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              props.onCancel();
            }}
            variant="outlined"
          >
            {t("global.cancel")}
          </Button>
        </Grid>
        {clientDocumentTypes && biDocuments && (
          <Grid item xs={2}>
            <Button
              onClick={async () => {
                const clientDocumentTypesRes = clientDocumentTypes?.map((x) => {
                  if (
                    clientDocumentTypesDocuments &&
                    clientDocumentTypesDocuments[x.id] &&
                    clientDocumentTypesDocuments[x.id].length > 0
                  ) {
                    x.status = ClientDocumentTypeStatus.VALIDATED;
                  }
                  return x;
                });
                await props.onSubmit(
                  clientDocumentTypesRes,
                  props.cdt,
                  clientDocumentTypesDocuments
                );
              }}
              variant="contained"
            >
              {t("global.confirm")}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
