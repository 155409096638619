import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import Loader from "../components/common/loading";
import { sendMagicLink } from "../services/user.api";
import { useTranslation } from "react-i18next";
import { clearBackContext, customBackContext } from "../slice/back.slice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "../styles/common/magicLinkForm.scss";
import { ReactComponent as ProfileIcon } from "../resources/icons/profile.svg";
function MagicLinkForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [email, setEmail] = useState<string>("");
  const [sentEmail, setSentEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(
      customBackContext({
        disabled: true,
        className: "hide-back-button",
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  const callSendMagicLink = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email) {
      return;
    }
    if (!executeRecaptcha) {
      enqueueSnackbar(t("errors.recaptcha"), { variant: "error" });
      return;
    }
    try {
      setLoading(true);
      if (executeRecaptcha) {
        const token = await executeRecaptcha("submit");
        enqueueSnackbar(t("login.email-sent", { email }), {
          variant: "success",
        });

        await sendMagicLink(email, token);
        setSentEmail(email);
        setLoading(false);
        setEmail("");
      }
    } catch (e) {
      enqueueSnackbar(t("errors.mail-failed"), { variant: "error" });

      setLoading(false);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          direction={"row"}
          alignItems={"left"}
          justifyContent={"flex-start"}
          textAlign={"left"}
        >
          <Grid item display={{ xs: "block", md: "block" }} mt={2} mx={0}>
            <img
              style={{ objectFit: "contain", maxWidth: "300px" }}
              alt={"Logo"}
              height={"30px"}
              src="Lendy.png"
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          textAlign={"left"}
          minHeight={"70vh"}
        >
          <Grid item display={{ xs: "none", md: "block" }} md={5} ml={0}>
            <Typography mb={2} textAlign={"left"}>
              <div className="title-text">
                {t("login.simplify")}
                <span className="title-and">{t("login.&")}</span>
                {t("login.secure")}
              </div>
              <div className="title-subtext">{t("login.loan")}</div>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} textAlign={"center"}>
            {" "}
            <form className="right-side" onSubmit={callSendMagicLink}>
              <h2 className="right-side-title">{t("login.title")}</h2>
              <Typography m={2}>{t("login.subtitle")}</Typography>
              <TextField
                type="email"
                id="magic-link-form-email"
                label={t("login.form-email")}
                sx={{ width: "70%" }}
                placeholder={t("login.form-email-placeholder") as string}
                value={email}
                onChange={(event) => {
                  if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      event.target.value
                    )
                  ) {
                    setError(true);
                    setEmail(event.target.value);

                    return;
                  }
                  setError(false);
                  setEmail(event.target.value);
                }}
                error={error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ProfileIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <br />
              <Button
                type="submit"
                sx={{ marginTop: "10px", marginBottom: "10px", width: "70%" }}
                className="right-side-button"
                variant="contained"
                disabled={
                  loading || error || (sentEmail !== "" && email === sentEmail)
                }
              >
                {t("login.validate")}
              </Button>

              {loading && <Loader />}
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default MagicLinkForm;
