import { configureStore } from "@reduxjs/toolkit";
import alertReducer from "../slice/alert.slice";
import loginReducer from "../slice/login.slice";
import backReducer from "../slice/back.slice";

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    login: loginReducer,
    back: backReducer,
  },
});
