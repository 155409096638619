import { getHeaderWithAuth } from "./utils";

export async function reloadCategories() {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/category/init`,
    {
      method: "POST",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not reload categories");
  }
}
