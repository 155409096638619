import { Box, Grid } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/common/loading";
import { getClient } from "../../services/user.api";
import { Borrower } from "../../types/caseForm.type";

export default function ClientStatut() {
  const params = useParams();
  const [client, SetClient] = useState<Borrower>();
  useEffect(() => {
    (async () => {
      if (params.id) {
        SetClient(await getClient(params.id));
      }
    })();
  }, [params.id]);

  return (
    <Box alignContent={"center"} sx={{ pt: 1 }}>
      {!client && <Loader />}

      {client && (
        <Grid container sx={{ textAlign: "left" }} spacing={2} rowSpacing={4}>
          <Grid item xs={12}>
            <div className="title">
              {client.firstName} {client.lastName}
            </div>
            <h3> {t("client.your-case-title")}</h3>

            {t("client.case-completed")}
            {t("client.information-submition")}
          </Grid>
          <Grid item xs={12}>
            <div className="sub-title">{t("case.form-status")}</div>
            <div className="group-row">{t("client.under-review")}</div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
