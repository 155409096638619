import "./App.scss";
import { useRoutes } from "react-router-dom";
import mainRoutes from "./routes";

function App() {
  const routes = useRoutes(mainRoutes);
  return (
    <div
      className="App"
      style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
    >
      {routes}
    </div>
  );
}

export default App;
