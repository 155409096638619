import { Box } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { ReactElement, JSXElementConstructor } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  ControllerFieldState,
  UseFormStateReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function PhoneInput(props: { index: number }) {
  const { t } = useTranslation();

  // ✅ work on refresh entire field array

  return (
    <Box display={"contents"} className={"form-input"}>
      <Controller
        render={function ({
          field,
          fieldState,
          formState,
        }: {
          field: ControllerRenderProps<FieldValues, string>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<FieldValues>;
        }): ReactElement<any, string | JSXElementConstructor<any>> {
          return (
            <MuiTelInput
              defaultCountry="FR"
              value={field.value}
              className="form-input"
              label={t("case.form-phone")}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          );
        }}
        name={`borrowers.${props.index}.phone`}
      ></Controller>
    </Box>
  );
}
