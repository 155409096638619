import { t } from "i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/common/loading";
import { routeType } from "../../routes";
import { createAccessToken } from "../../services/bi.api";
import { getMe } from "../../services/user.api";
import { enqueueSnackbar } from "notistack";

import { userUpdate } from "../../slice/login.slice";

export default function BudgetInsightWebViewResult() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const handleNewAccount = async (
    cdtId: string,
    code?: string,
    connectionId?: string
  ) => {
    if (!connectionId && !code) {
      enqueueSnackbar(t("errors.NO_BI_CODE", { variant: "error" }));
    } else {
      try {
        await createAccessToken(cdtId, code, connectionId);
        const payload = await getMe();
        dispatch(userUpdate(payload));
      } catch (err) {
        console.log("error BI connection : ", err);
        enqueueSnackbar("BI connection NoK", { variant: "error" });
      }
    }
  };

  const handleResult = async () => {
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const connectionId = searchParams.get("connection_id");
    if (state) {
      const [cdtId, userId, customRoute] = state?.split("_");

      if (cdtId) {
        await handleNewAccount(
          cdtId,
          code || undefined,
          connectionId || undefined
        );
      }
      if (customRoute) {
        const nextRoute =
          routeType.client +
          "/" +
          userId +
          customRoute +
          (connectionId ? "?display-ok=true" : "");
        navigate(nextRoute);
      } else {
        navigate(routeType.client + "/" + userId);
      }
    } else {
      navigate(routeType.home);
    }
  };

  useEffect(() => {
    handleResult();
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
}
