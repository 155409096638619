import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchLogin } from "../services/user.api";
import { routeType } from "../routes";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { Roles } from "../types/role.enum";
import { userLogin } from "../slice/login.slice";
import Loader from "../components/common/loading";
import { useTranslation } from "react-i18next";
import { clearBackContext, customBackContext } from "../slice/back.slice";
function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      customBackContext({
        shoulBlockIncrement: true,
      })
    );
  });

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  const callFetchLogin = async () => {
    if (!token) {
      // TODO: handle error with error service
      enqueueSnackbar(t("errors.no-user"), { variant: "error" });
      navigate(routeType.home);
    }

    try {
      const jwtUser = await fetchLogin(token!);
      dispatch(userLogin(jwtUser));
      enqueueSnackbar(t("successes.connected"), { variant: "success" });
      switch (jwtUser.role) {
        case Roles.CLIENT:
          if (jwtUser.hasAcceptedCgu) {
            navigate(routeType.client + "/" + jwtUser.id);
          } else {
            navigate(routeType.client + "/" + jwtUser.id + "/cgu");
          }
          // if (jwtUser.biConnected) {
          //   navigate(routeType.account);
          // } else {
          //   navigate(routeType.webview);
          // }
          break;
        case Roles.ADMIN:
          navigate(routeType.userList);
          break;
        case Roles.USER:
          navigate(routeType.caseList);
          break;
        default:
          break;
      }

      //TODO: we should not have to reload here (...)
      //window.location.reload();
      // TODO: Redirect to do to homepage with login
    } catch (e) {
      console.log("errors.no-redirection", e);
      // TODO: handle error with error service
      navigate(routeType.home);
    }
  };

  useEffect(() => {
    callFetchLogin();
  });

  return (
    <div>
      <Loader />
    </div>
  );
}

export default Login;
