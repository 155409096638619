import {
  Box,
  BoxProps,
  Button,
  ButtonBase,
  Grid,
  Typography,
  TypographyProps,
  lighten,
  useTheme,
} from "@mui/material";
import { useState, useEffect, Component } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Borrower,
  Case,
  ClientDocumentType,
  ClientDocumentTypeStatus,
} from "../../types/caseForm.type";

import React from "react";
import "../../styles/case/caseValidation.scss";
import { ReactComponent as CommonFilesIcon } from "../../resources/icons/restitution/common-files.svg";
import { ReactComponent as PdfFileIcon } from "../../resources/icons/restitution/pdf-file.svg";
import Loader from "../common/loading";
import { ReactComponent as NotYetValidatedIcon } from "../../resources/icons/restitution/circle-validate-white.svg";
import { ReactComponent as ValidatedIcon } from "../../resources/icons/restitution/validated-white.svg";

import { MultiStateDisplay } from "./MultiStateDisplay";
import {
  BudgetInsightConnection,
  BudgetInsightSubscription,
  SubscriptionDisplayStatus,
} from "../../types/budgetInsight.type";
import { orderBy, uniq } from "lodash";
import {
  getBudgetInsightConnectionsForConnectionIds,
  getBudgetInsightDocumentSignedUrl,
  updateBudgetInsightDocumentsWithClientDocumentTypes,
} from "../../services/bi.api";
import { format } from "date-fns";
import BudgetInsightDocumentSelectBorrower from "./budgetInsightDocumentSelectBorrower";
import { AntSwitch } from "../common/switchCustom";
import BudgetInsightHeaderBorrowers from "./budgetInsightHeaderBorrowers";

export type subscriptionsBorrowers = {
  [key: string]: {
    borrowers: string[];
    displayStatus: SubscriptionDisplayStatus;
    filteredDisplayed: boolean;
  };
};

export const mainBoxProps: BoxProps = {
  textAlign: "left",
  bgcolor: "primary.main",
  borderRadius: "4px",
  padding: "6px 12px",
  marginBottom: 1,
};

export const mainBoxTypoProps: TypographyProps = {
  fontWeight: "bold",
  color: "primary.contrastText",
};

export default function BudgetInsightAddressSelectDocument(props: {
  cdt: ClientDocumentType;
  aCase: Case;
  onSubmit: Function;
  onCancel: Function;
}) {
  const theme = useTheme();

  const mainBoxLightProps: BoxProps = {
    ...mainBoxProps,
    bgcolor: lighten(theme.palette.primary.main, 0.2),
  };
  const { t } = useTranslation();
  const params = useParams();
  const [subscriptionsBorrowers, setSubscriptionsBorrowers] =
    useState<subscriptionsBorrowers>({});
  const [clientDocumentTypesToUpdate, setClientDocumentTypesToUpdate] =
    useState<ClientDocumentType[]>();
  const [budgetInsightConnections, setBudgetInsightConnections] =
    useState<BudgetInsightConnection[]>();
  const [selectedBorrowers, setSelectedBorrowers] = useState<string[]>([]);
  const [validatedBorrowers, setValidatedBorrowers] = useState<string[]>([]);
  const [hasCommonFeature, setHasCommonFeature] = useState(false);

  useEffect(() => {
    (async () => {
      const cdtsToUpdate = props.aCase.clientDocumentTypes.filter(
        (cdtTmp) => cdtTmp.documentType?.name === "PROOF_OF_ADDRESS"
      );

      setClientDocumentTypesToUpdate(cdtsToUpdate);

      setHasCommonFeature(cdtsToUpdate.find((c) => !c.clientId) !== undefined);
      const bIConnectionIds = uniq(
        cdtsToUpdate
          .flatMap((x) => x.budgetInsightConnectionIds)
          .filter(Boolean)
      );
      let connections = await getBudgetInsightConnectionsForConnectionIds(
        props.aCase.id,
        bIConnectionIds
      );
      const subBor: subscriptionsBorrowers = {};
      connections = connections.filter(
        (c) => c.subscriptions && c.subscriptions?.length > 0
      );

      connections.forEach((c) => {
        c.subscriptions!.filter(
          (s) => s.documents && s.documents?.length > 0
        ).forEach((s) => {
          subBor[s.id] = {
            filteredDisplayed: true,
            displayStatus: s.displayStatus,
            borrowers: s.documents![0].clientDocumentType
              ? s.documents![0].clientDocumentType.clientId
                ? [s.documents![0].clientDocumentType.clientId]
                : props.aCase.borrowers.map((b) => b.id!)
              : [],
          };
        });
      });
      const selectedByDefault = props.aCase.borrowers.map((b) => b.id!);
      if (selectedByDefault.length > 1) {
        selectedByDefault.push("both");
      }
      setSelectedBorrowers(selectedByDefault);
      const initialValidatedBorrowers: string[] = [];
      cdtsToUpdate
        .filter((c) => c.status === ClientDocumentTypeStatus.VALIDATED)
        .forEach((cdt) => {
          if (cdt.clientId) {
            initialValidatedBorrowers.push(cdt.clientId);
          } else {
            initialValidatedBorrowers.push("both");
          }
        });
      setValidatedBorrowers(initialValidatedBorrowers);

      setSubscriptionsBorrowers(subBor);

      setBudgetInsightConnections(connections);
    })();
  }, [params, props.aCase, props.cdt]);

  const saveAllData = async () => {
    const cdtToUpdate = clientDocumentTypesToUpdate
      ? clientDocumentTypesToUpdate.map((cdt) => {
          let status: ClientDocumentTypeStatus;

          if (cdt.clientId) {
            status = validatedBorrowers.includes(cdt.clientId)
              ? ClientDocumentTypeStatus.VALIDATED
              : ClientDocumentTypeStatus.SUBMITTED;
          } else {
            // not clientId --> its both borrowers
            status = validatedBorrowers.includes("both")
              ? ClientDocumentTypeStatus.VALIDATED
              : ClientDocumentTypeStatus.SUBMITTED;
          }

          return { id: cdt.id, status };
        })
      : [];

    const documentsToUpdate = budgetInsightConnections
      ? budgetInsightConnections
          .flatMap((c) => c.subscriptions?.flatMap((s) => s.documents))
          .filter(Boolean)
      : [];
    const docsFilteredAndPrepared = documentsToUpdate
      .filter(
        (d) =>
          d?.budgetInsightSubscriptionId &&
          subscriptionsBorrowers[d?.budgetInsightSubscriptionId] !== undefined
      )
      .map((d) => {
        let cdtToAssign: string | null;
        if (
          subscriptionsBorrowers[d!.budgetInsightSubscriptionId].borrowers
            .length === 2
        ) {
          cdtToAssign =
            clientDocumentTypesToUpdate?.find((cdt) => !cdt.clientId)?.id ||
            null;
        } else {
          if (
            subscriptionsBorrowers[d!.budgetInsightSubscriptionId].borrowers
              .length === 0
          ) {
            cdtToAssign = null;
          } else {
            cdtToAssign =
              clientDocumentTypesToUpdate?.find(
                (cdt) =>
                  cdt.clientId ===
                  subscriptionsBorrowers[d!.budgetInsightSubscriptionId]
                    .borrowers[0]
              )?.id || null;
          }
        }

        return {
          id: d!.id,
          cdtId: cdtToAssign,
        };
      });
    await updateBudgetInsightDocumentsWithClientDocumentTypes(
      props.aCase.id,
      docsFilteredAndPrepared,
      cdtToUpdate
    );
    props.onSubmit();
  };

  const toggleSelectedBorrower = (borrowerId: string) => {
    const selectedNew = selectedBorrowers.includes(borrowerId)
      ? selectedBorrowers.filter((sb) => sb !== borrowerId)
      : selectedBorrowers.concat([borrowerId]);
    setSelectedBorrowers(selectedNew);

    if (selectedNew.length === 0) {
      for (const key in subscriptionsBorrowers) {
        subscriptionsBorrowers[key].filteredDisplayed = false;
      }
    }
    if (selectedNew.length === 3) {
      for (const key in subscriptionsBorrowers) {
        subscriptionsBorrowers[key].filteredDisplayed = true;
      }
    }
    if (selectedNew.length > 0 && selectedNew.length < 3) {
      for (const key in subscriptionsBorrowers) {
        let shouldBeDisplayed = false;
        for (const selected of selectedNew) {
          if (selected === "both") {
            shouldBeDisplayed =
              shouldBeDisplayed ||
              subscriptionsBorrowers[key].borrowers.length === 2;
          } else {
            shouldBeDisplayed =
              shouldBeDisplayed ||
              (subscriptionsBorrowers[key].borrowers.length === 1 &&
                subscriptionsBorrowers[key].borrowers.includes(selected));
          }
        }
        subscriptionsBorrowers[key].filteredDisplayed = shouldBeDisplayed;
      }
    }
    setSubscriptionsBorrowers(subscriptionsBorrowers);
  };
  const toggleValidatedBorrower = (borrowerId: string) => {
    const selectedNew = validatedBorrowers.includes(borrowerId)
      ? validatedBorrowers.filter((sb) => sb !== borrowerId)
      : validatedBorrowers.concat([borrowerId]);
    setValidatedBorrowers(selectedNew);
  };

  const openDocUrl = async (docId: string) => {
    const url = await getBudgetInsightDocumentSignedUrl(docId);
    window.open(url, "_blank", "fullscreen=yes");
  };

  const displaySubscriptionDocuments = (sub: BudgetInsightSubscription) => {
    if (!sub.documents || sub.documents.length === 0) {
      return <></>;
    }
    const files = [...sub.documents].sort((a, b) => {
      const dateA = new Date(a.effectiveDate);
      const dateB = new Date(b.effectiveDate);
      return dateB.getTime() - dateA.getTime();
    });
    if (!subscriptionsBorrowers[sub.id].filteredDisplayed) {
      return <></>;
    }
    return (
      <Grid
        key={"sub-" + sub.id}
        container
        justifyContent={"flex-start"}
        textAlign={"left"}
        alignItems={"center"}
        spacing={2}
        marginBottom={2}
      >
        <Grid
          container
          justifyContent={"flex-start"}
          textAlign={"left"}
          alignItems={"center"}
          item
          xs={8}
        >
          <BudgetInsightDocumentSelectBorrower
            borrowers={props.aCase.borrowers}
            selected={subscriptionsBorrowers[sub.id].borrowers}
            hasCommonFeature={hasCommonFeature}
            updateSelected={(selected: string[]) => {
              subscriptionsBorrowers[sub.id].borrowers = selected;
              setSubscriptionsBorrowers(subscriptionsBorrowers);
            }}
          />

          <Box>{sub.label}</Box>
        </Grid>

        <Grid item xs={2} textAlign={"center"} mx={"auto"}>
          <ButtonBase
            onClick={() => {
              openDocUrl(files[0].id);
            }}
          >
            <PdfFileIcon />
          </ButtonBase>
          <br />
          {format(new Date(files[0].effectiveDate), "MM/yy")}
        </Grid>
      </Grid>
    );
  };

  const displayConnectionDocuments = () => {
    return budgetInsightConnections?.map((bis, index) => (
      <div key={"bis-" + bis.id}>
        {index === 0 ||
          bis.connectorName !==
            budgetInsightConnections[index - 1].connectorName}
        <Box
          padding={1}
          fontWeight={"bold"}
          textAlign={"left"}
          marginTop={2}
          marginBottom={1}
        >
          {bis.connectorName}
        </Box>
        {bis.subscriptions?.map((sub) => displaySubscriptionDocuments(sub))}
      </div>
    ));
  };

  if (!props.aCase) {
    return <></>;
  }
  return (
    <>
      {/* Start Header Components */}

      <Grid container justifyContent={"flex-start"} spacing={2}>
        <Grid item xs={5} sm={4}>
          <Box {...mainBoxProps} marginBottom={5}>
            <Typography {...mainBoxTypoProps} textTransform={"uppercase"}>
              {t("header.popup-validation-proof")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <BudgetInsightHeaderBorrowers
        aCase={props.aCase}
        selectedBorrowers={selectedBorrowers}
        validatedBorrowers={validatedBorrowers}
        toggleValidate={(borrowerId: string) => {
          toggleValidatedBorrower(borrowerId);
        }}
        toggleFilter={(borrowerId: string) => {
          toggleSelectedBorrower(borrowerId);
        }}
        hasCommonFeature={hasCommonFeature}
      />

      {/* Start Data Component */}
      <Grid
        marginTop={1}
        container
        justifyContent={"flex-start"}
        alignItems={"center"}
        spacing={4}
      >
        <Grid item xs={8}>
          <Box {...mainBoxLightProps}>
            <Typography {...mainBoxTypoProps}>
              {t("header.popup-validation-proof-first-col")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box {...mainBoxLightProps}>
            <Typography {...mainBoxTypoProps}>
              {t("header.popup-validation-proof-second-col")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {budgetInsightConnections &&
        budgetInsightConnections.length > 0 &&
        displayConnectionDocuments()}
      {/* End Data Component */}
      <Grid container paddingTop={3} justifyContent={"flex-end"}>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              props.onCancel();
            }}
            variant="outlined"
          >
            {t("global.cancel")}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={async () => {
              saveAllData();
            }}
            variant="contained"
          >
            {t("global.confirm")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
