import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DocumentTypeList from "./documentTypeList";
import {
  getCase,
  getClients,
  getDocumentTypes,
  regenerateClientDocumentTypeFromCase,
} from "../../services/case.api";
import { DocumentType } from "../../types/documentList";
import { routeType } from "../../routes";
import { useDispatch } from "react-redux";
import { clearBackContext, customBackContext } from "../../slice/back.slice";
import { Box, Button } from "@mui/material";
import Loader from "../../components/common/loading";
import { useTranslation } from "react-i18next";
import CaseStepper from "../../components/case/caseStepper";

export default function CaseDocumentForm() {
  let params = useParams();
  const { t } = useTranslation();
  const [caseId, setCaseId] = useState(params.id);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [createdCase, setCase] = useState<any>();
  const [clients, setClients] = useState<any[]>();
  const [clientDocumentTypes, setClientDocumentTypes] = useState<any>([]);
  const [caseDocumentTypes, setCaseDocumentTypes] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchDocumentTypes = async () => {
    setDocumentTypes(await getDocumentTypes());
  };

  const fetchCase = async () => {
    if (caseId) {
      const caseToProcess = await getCase(caseId);
      setCase(caseToProcess);
      setCaseDocumentTypes(
        caseToProcess.clientDocumentTypes.map(
          (cdt: { documentType: DocumentType }) => cdt.documentType
        )
      );
    }
  };
  const fetchClients = async () => {
    if (caseId) {
      const newClients = await getClients(caseId);

      setClients(newClients);
      setClientDocumentTypes(
        newClients?.map((client: { clientDocumentTypes: any[] }) =>
          client.clientDocumentTypes.map(
            (cdt: { documentType: DocumentType }) => cdt.documentType
          )
        )
      );
    }
  };

  useEffect(() => {
    fetchCase();
    fetchDocumentTypes();
    fetchClients();
  }, []);

  const regenerateClientDocumentType = async () => {
    if (params.id) {
      setLoading(true);
      await regenerateClientDocumentTypeFromCase(params.id);
      await fetchCase();
      await fetchDocumentTypes();
      await fetchClients();
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(
      customBackContext({
        route: routeType.caseCreate + `/${caseId}`,
        disabled: false,
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  return (
    <div>
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        position={"relative"}
      >
        <Box maxWidth={"33%"}>
          <h3>
            <u>{t("case.document-select")}</u>
          </h3>
        </Box>

        <Box display={"flex"} right={33} position={"absolute"}>
          {loading && <Loader />}

          <Button
            variant="outlined"
            sx={{ marginLeft: "20px" }}
            disabled={loading}
            onClick={async () => {
              await regenerateClientDocumentType();
            }}
          >
            {t("case.regenerate")}
          </Button>
        </Box>
      </Box>
      <CaseStepper nbSteps={3} position={0}></CaseStepper>

      {clients && clients.length > 0 && createdCase && documentTypes && (
        <DocumentTypeList
          case={createdCase}
          clients={clients}
          clientDocumentTypes={clientDocumentTypes}
          caseDocumentTypes={caseDocumentTypes}
          documentTypes={documentTypes}
        />
      )}
    </div>
  );
}
