import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { sendEventOpenMobilePage, sendFile } from "../../services/upload.api";
import { isDesktop } from "react-device-detect";
import "../../styles/client/upload.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  Fab,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "../../components/common/loading";
import Webcam from "react-webcam";
import { dataURIToFile } from "../../services/utils";
import { ReactComponent as BackIcon } from "../../resources/icons/back.svg";
import { enqueueSnackbar } from "notistack";
import { useTheme } from "@emotion/react";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function UploadDocument() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { search } = useLocation();
  const fileInput = useRef<HTMLInputElement>(null);
  const token = new URLSearchParams(search).get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [isOkCamera, setIsOkCamera] = useState(false);
  const [isLoadingCamera, setIsLoadingCamera] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const webcamRef = useRef<Webcam>(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const handleEventMobilePage = async (token: string) => {
    try {
      await sendEventOpenMobilePage(token);
    } catch (error) {
      console.log("sendEventOpenMobilePage", error);
    }
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (token) {
      handleEventMobilePage(token);
    }
  }, [token]);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (!imageSrc) {
        return;
      }
      const fileToAdd = dataURIToFile(imageSrc, "photo.jpg");
      handleFileUpload(fileToAdd);
    }
  }, [webcamRef]);

  const handleFileUpload = async (file: any) => {
    if (file && token) {
      try {
        setDisplayError(false);
        setDisplaySuccess(false);
        setIsLoading(true);
        await sendFile(token, file);
        setDisplaySuccess(true);
      } catch (error) {
        console.log("UploadError", error);
        setDisplayError(true);
      }
      setOpenUploadModal(false);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Grid
        container
        flexGrow={1}
        direction={"column"}
        alignItems={"stretch"}
        justifyContent={"center"}
      >
        <Grid item>
          <Typography>{t("upload.add-document-intro")}</Typography>
        </Grid>
        <Grid
          item
          container
          flexGrow={1}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                setWindowSize(getWindowSize());
                setOpenUploadModal(true);
                setIsLoadingCamera(true);
                setIsOkCamera(false);
              }}
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {t("upload.take-photo")}
            </Button>
          </Grid>
          <Grid item mt={6}>
            <Button
              onClick={() => fileInput.current && fileInput.current.click()}
              variant="contained"
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {t("upload.choose-local-file")}
            </Button>
            {fileInput && (
              <input
                ref={fileInput}
                style={{ display: "none" }}
                accept="image/*,application/pdf"
                type="file"
                capture="environment"
                onChange={(e) => {
                  if (e.target && e.target.files && e.target.files.length > 0) {
                    handleFileUpload(e.target.files[0]);
                  }
                }}
              />
            )}
          </Grid>
          {isLoading && (
            <>
              <Grid item pt={2}>
                <Typography fontStyle={"italic"}>
                  {t("upload.upload-processing")}
                </Typography>
              </Grid>
              <Grid item pt={2}>
                <Loader />
              </Grid>
            </>
          )}
          {(displayError || displaySuccess) && (
            <Grid item pt={2}>
              {displayError && (
                <Alert severity="error">{t("errors.upload")}</Alert>
              )}
              {displaySuccess && (
                <Alert severity="success">{t("successes.upload")}</Alert>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openUploadModal}
        onClose={() => {
          console.log("close dialog");
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        scroll="body"
        fullScreen={true}
      >
        <DialogContent>
          <Grid
            container
            direction={
              windowSize.innerWidth > windowSize.innerHeight &&
              windowSize.innerHeight < 600
                ? "row"
                : "column"
            }
            justifyContent={"space-between"}
            alignItems={"stretch"}
            sx={{ height: windowSize.innerHeight * 0.9 }}
          >
            <Grid item>
              <Box pt={3} alignItems={"start"} textAlign={"left"}>
                <Button
                  sx={{ minWidth: 0 }}
                  onClick={() => {
                    setOpenUploadModal(false);
                  }}
                >
                  <BackIcon fill={"#000000"} color={"#000000"} />
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              display={"flex"}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                {isLoadingCamera && <Loader />}
                {!isLoadingCamera && !isOkCamera && (
                  <Box sx={{ backgroundColor: "#fff", padding: 2 }}>
                    <Typography>{t("errors.camera_not_authorized")}</Typography>
                  </Box>
                )}
                {windowSize && (
                  <Webcam
                    ref={webcamRef}
                    style={{
                      margin: "auto",
                      display: isOkCamera ? "block" : "none",
                      height: "auto",
                      maxHeight: `${windowSize.innerHeight * 0.6}px`,
                      maxWidth: "100%",
                      borderRadius: "10px",
                      border: "2px solid",
                      borderColor: "#305E8F",
                    }}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                      aspectRatio: isDesktop ? 0.707 : 1 / 0.707,
                      facingMode: "environment",
                    }}
                    screenshotQuality={1}
                    minScreenshotHeight={1080}
                    audio={false}
                    onUserMedia={(props) => {
                      setIsOkCamera(true);
                      setIsLoadingCamera(false);
                    }}
                    onUserMediaError={(props) => {
                      setIsOkCamera(false);
                      setIsLoadingCamera(false);
                      enqueueSnackbar(t("errors.camera_not_authorized"), {
                        variant: "error",
                      });
                    }}
                  ></Webcam>
                )}
                {isOkCamera && (
                  <Grid item mt={1} textAlign={"center"}>
                    <Button variant="contained">
                      {t("upload.info-take-photo")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isOkCamera && (
              <Grid
                item
                display={"flex"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item textAlign={"center"}>
                  <Fab
                    sx={{ mx: "auto" }}
                    size="large"
                    aria-label="add"
                    onClick={capture}
                  >
                    &nbsp;
                  </Fab>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
