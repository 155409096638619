import React, { useEffect, useState } from "react";
import { fetchTransactionsList } from "../../services/bi.api";
import { Transaction } from "../../types/transaction.type";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import Loader from "../../components/common/loading";
import "../../styles/transactionsList.scss";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Box } from "@mui/material";

function TransactionsList() {
  const { t } = useTranslation();

  const [transactions, setTransaction] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { userId, accountId, accountName } = state;

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: t("transactions.date") as string,
      flex: 0.3,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return format(new Date(params.value), "dd/MM/YYYY");
      },
    },
    {
      field: "original_wording",
      headerName: t("transactions.name") as string,
      flex: 1,
    },
    { field: "type", headerName: t("transactions.type") as string, flex: 0.3 },
    {
      field: "category",
      headerName: t("transactions.category") as string,
      flex: 0.8,
      valueFormatter: (params) => {
        return params.value ?? t("transactions.other");
      },
    },
    {
      field: "formatted_value",
      headerName: t("transactions.value") as string,
      align: "right",
      flex: 0.5,
      valueGetter: (params) => params,
      renderCell: (params: GridRenderCellParams<string>) => {
        const className =
          params.row.value && params.row.value >= 0
            ? "positive-number"
            : "negative-number";
        return <span className={className}>{params.row.formatted_value}</span>;
      },
    },
  ];

  const feedTransactionsList = async () => {
    setLoading(true);

    const transactionsList = await fetchTransactionsList(userId, accountId);
    setTransaction(transactionsList);
    setLoading(false);
  };

  useEffect(() => {
    feedTransactionsList();
  }, []);

  return (
    <div>
      <h2>{t("transactions.title", { accountName })}</h2>
      {loading ? (
        <Loader />
      ) : (
        <Box height="1151px" width="100%">
          <DataGrid
            rows={transactions}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
          />
        </Box>
      )}
    </div>
  );
}

export default TransactionsList;
