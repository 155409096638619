import {
  Box,
  BoxProps,
  ButtonBase,
  Grid,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useState, useEffect, Component } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Borrower, Case, ClientDocumentType } from "../../types/caseForm.type";

import React from "react";
import "../../styles/case/caseValidation.scss";
import { ReactComponent as CommonFilesIcon } from "../../resources/icons/restitution/common-files.svg";
import { ReactComponent as PdfFileIcon } from "../../resources/icons/restitution/pdf-file.svg";
import Loader from "../common/loading";
import { ReactComponent as NotYetValidatedIcon } from "../../resources/icons/restitution/circle-validate-white.svg";
import { ReactComponent as ValidatedIcon } from "../../resources/icons/restitution/validated-white.svg";

import { MultiStateDisplay } from "./MultiStateDisplay";
import {
  BudgetInsightConnection,
  BudgetInsightSubscription,
  SubscriptionDisplayStatus,
} from "../../types/budgetInsight.type";
import { orderBy, uniq } from "lodash";
import {
  getBudgetInsightConnectionsForConnectionIds,
  getBudgetInsightDocumentSignedUrl,
} from "../../services/bi.api";
import { format } from "date-fns";
import BudgetInsightDocumentSelectBorrower from "./budgetInsightDocumentSelectBorrower";
import { AntSwitch } from "../common/switchCustom";
import {
  mainBoxProps,
  mainBoxTypoProps,
} from "./budgetInsightBankSelectDocument";

export default function BudgetInsightHeaderBorrowers(props: {
  aCase: Case;
  toggleFilter: Function;
  toggleValidate: Function;
  selectedBorrowers: string[];
  validatedBorrowers: string[];
  hasCommonFeature: boolean;
}) {
  // const { t } = useTranslation();
  // const params = useParams();

  // useEffect(() => {}, [params, props.aCase, props.cdt]);

  const displayBorrowerWithActions = (
    borrower: Borrower,
    isValidated: boolean,
    isFiltered: boolean,
    validateAction: Function,
    filterAction: Function,
    customLabel?: React.ReactElement
  ) => {
    return (
      <Box {...mainBoxProps}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          alignContent={"center"}
          direction={"row"}
          spacing={2}
        >
          <Grid item>
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"center"}
              direction={"row"}
            >
              <AntSwitch
                checked={isFiltered}
                inputProps={{ "aria-label": "ant design" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  filterAction(event)
                }
              />
              {customLabel || (
                <Typography {...mainBoxTypoProps} marginLeft={1}>
                  {borrower.lastName.toLocaleUpperCase() +
                    " " +
                    borrower.firstName}{" "}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"center"}
              direction={"row"}
              onClick={() => {
                validateAction(isValidated);
              }}
            >
              {isValidated ? <ValidatedIcon /> : <NotYetValidatedIcon />}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const hasMultipleBorrowers = props.aCase && props.aCase.nbBorrower === 2;
  if (!props.aCase) {
    return <></>;
  }
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      spacing={2}
    >
      <Grid item xs={5} sm={4}>
        {displayBorrowerWithActions(
          props.aCase?.borrowers[0],
          props.validatedBorrowers?.includes(
            props.aCase?.borrowers[0].id || ""
          ) || false,
          props.selectedBorrowers?.includes(
            props.aCase?.borrowers[0].id || ""
          ) || false,
          () => {
            props.toggleValidate(props.aCase?.borrowers[0].id || "");
          },
          () => {
            props.toggleFilter(props.aCase?.borrowers[0].id || "");
          }
        )}
        {}
        {hasMultipleBorrowers &&
          displayBorrowerWithActions(
            props.aCase?.borrowers[1],
            props.validatedBorrowers?.includes(
              props.aCase?.borrowers[1].id || ""
            ) || false,
            props.selectedBorrowers?.includes(
              props.aCase?.borrowers[1].id || ""
            ) || false,
            () => {
              props.toggleValidate(props.aCase?.borrowers[1].id || "");
            },
            () => {
              props.toggleFilter(props.aCase?.borrowers[1].id || "");
            }
          )}
      </Grid>

      {props.hasCommonFeature && (
        <>
          <Grid item>
            {" "}
            <CommonFilesIcon height={"30px"} />{" "}
          </Grid>
          <Grid item xs={5} sm={4}>
            {displayBorrowerWithActions(
              props.aCase?.borrowers[1],
              props.validatedBorrowers?.includes("both") || false,
              props.selectedBorrowers?.includes("both") || false,
              () => {
                props.toggleValidate("both");
              },
              () => {
                props.toggleFilter("both");
              },
              <Typography
                {...mainBoxTypoProps}
                textAlign={"left"}
                marginLeft={1}
                marginY={1.25}
              >
                {props.aCase?.borrowers[0].lastName.toLocaleUpperCase() +
                  " " +
                  props.aCase?.borrowers[0].firstName}
                <br />
                {props.aCase?.borrowers[1].lastName.toLocaleUpperCase() +
                  " " +
                  props.aCase?.borrowers[1].firstName}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
