import {
  Case,
  ClientDocumentType,
  ClientDocumentTypeStatus,
} from "../../types/caseForm.type";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as NotYetRefusedIcon } from "../../resources/icons/restitution/circle-refuse.svg";
import { ReactComponent as NotYetValidatedIcon } from "../../resources/icons/restitution/circle-validate.svg";
import { ReactComponent as NoFileIcon } from "../../resources/icons/restitution/no-file.svg";
import { ReactComponent as RefusedIcon } from "../../resources/icons/restitution/refused.svg";
import { ReactComponent as ValidatedIcon } from "../../resources/icons/restitution/validated.svg";

import {
  getSignedUrl,
  sendProcessBiDocuments,
} from "../../services/upload.api";
import BudgetInsightSelectDocument from "./budgetInsightSelectDocument";
import DocumentGroupValidate from "./documentGroupValidate";
import {
  getBIClientDoucmentTypesChildren,
  updateCdtStatus,
} from "../../services/case.api";
import { Source } from "../../types/documentList";
import DocumentTypeValidationComponent from "./DocumentTypeValidationComponent";
import { cp } from "fs";
import BudgetInsightBankSelectDocument from "./budgetInsightBankSelectDocument";
import BudgetInsightAddressSelectDocument from "./budgetInsightAddressSelectDocument";
import { useTranslation } from "react-i18next";
import BudgetInsightTaxSelectDocument from "./budgetInsightTaxSelectDocument";

export default function CaseDocumentValidationRow(props: {
  aCase?: Case;
  cdt: ClientDocumentType;
  documentTypesViewType: {
    [op: string]: "document" | "documentList" | "budgetInsight";
  };
  setModalComponent: Function;
  setOpenModal: Function;
  setCdtStatus: Function;
  refreshData?: Function;
  setAcceptBackdropClick?: Function;
}) {
  const { t } = useTranslation();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const openDocUrl = async (docId: string) => {
    const url = await getSignedUrl(docId);
    window.open(url, "_blank", "fullscreen=yes");
  };

  const updateParentCdt = async (
    cdts: ClientDocumentType[],
    cdt: ClientDocumentType
  ) => {
    let validated = false;
    let refused = false;
    if (
      cdts.length ===
      cdts.filter((x) => x.status === ClientDocumentTypeStatus.VALIDATED).length
    ) {
      // need to validate parent CDT
      await props.setCdtStatus(
        ClientDocumentTypeStatus.VALIDATED,
        cdt.id,
        cdt.clientId
      );
      validated = true;
    }
    if (cdts.some((x) => x.status === ClientDocumentTypeStatus.REFUSED)) {
      // need to refuse parent CDT
      await props.setCdtStatus(
        ClientDocumentTypeStatus.REFUSED,
        cdt.id,
        cdt.clientId
      );
      refused = true;
    }
    if (
      !validated &&
      !refused &&
      cdts.some((x) => x.status === ClientDocumentTypeStatus.SUBMITTED)
    ) {
      // we need to reset to submitted because there is no refused and at least one not validated
      await props.setCdtStatus(
        ClientDocumentTypeStatus.SUBMITTED,
        cdt.id,
        cdt.clientId
      );
    }
  };

  const generateModalComponent = (cdt: ClientDocumentType) => {
    if (
      cdt.budgetInsightConnectionIds ||
      cdt.documentType?.source === Source.BUDET_INSIGHT_DOCUMENT
    ) {
      if (props.aCase && cdt.documentType?.name === "PROOF_OF_ADDRESS") {
        if (props.setAcceptBackdropClick !== undefined) {
          props.setAcceptBackdropClick(false);
        }
        return (
          <>
            <BudgetInsightAddressSelectDocument
              cdt={cdt}
              aCase={props.aCase}
              onSubmit={() => {
                props.setOpenModal(false);
                if (props.setAcceptBackdropClick !== undefined) {
                  props.setAcceptBackdropClick(true);
                }
                if (props.refreshData) {
                  props.refreshData();
                }
              }}
              onCancel={() => {
                setOpenConfirmDialog(true);
              }}
            ></BudgetInsightAddressSelectDocument>
          </>
        );
      }
      if (
        props.aCase &&
        cdt.documentType?.name === "PERSONAL_ACCOUNT_STATEMENT"
      ) {
        if (props.setAcceptBackdropClick !== undefined) {
          props.setAcceptBackdropClick(false);
        }
        return (
          <>
            <BudgetInsightBankSelectDocument
              cdt={cdt}
              aCase={props.aCase}
              onSubmit={() => {
                props.setOpenModal(false);
                if (props.setAcceptBackdropClick !== undefined) {
                  props.setAcceptBackdropClick(true);
                }
                if (props.refreshData) {
                  props.refreshData();
                }
              }}
              onCancel={() => {
                setOpenConfirmDialog(true);
              }}
            ></BudgetInsightBankSelectDocument>
          </>
        );
      }
      if (
        props.aCase &&
        ["PROPERTY_TAX", "TAX_RETURN", "HOUSING_TAX"].includes(
          cdt.documentType?.name || ""
        )
      ) {
        if (props.setAcceptBackdropClick !== undefined) {
          props.setAcceptBackdropClick(false);
        }
        return (
          <>
            <BudgetInsightTaxSelectDocument
              cdt={cdt}
              aCase={props.aCase}
              onSubmit={() => {
                props.setOpenModal(false);
                if (props.setAcceptBackdropClick !== undefined) {
                  props.setAcceptBackdropClick(true);
                }
                if (props.refreshData) {
                  props.refreshData();
                }
              }}
              onCancel={() => {
                setOpenConfirmDialog(true);
              }}
            ></BudgetInsightTaxSelectDocument>
          </>
        );
      }

      return (
        <BudgetInsightSelectDocument
          cdt={cdt}
          onSubmit={async (
            cdts: ClientDocumentType[],
            cdt: ClientDocumentType,
            selectedDocuments: { [op: string]: number[] }
          ) => {
            let hasDocumentsToUpload = false;
            for (const data in selectedDocuments) {
              if (data.length > 0) {
                hasDocumentsToUpload = true;
              }
            }
            if (!hasDocumentsToUpload) {
              props.setOpenModal(false);
              return;
            }
            if (cdts.length === 1 && cdts[0].id === cdt.id) {
              // only one and the same !
              await props.setCdtStatus(cdts[0].status, cdt.id, cdt.clientId);
            } else {
              await updateCdtStatus(cdts);
              await updateParentCdt(cdts, cdt);
            }
            await sendProcessBiDocuments(selectedDocuments);

            props.setOpenModal(false);
          }}
          onCancel={() => {
            props.setOpenModal(false);
          }}
        />
      );
    }
    return (
      <div>
        <DocumentGroupValidate
          cdt={cdt}
          onSubmit={async (
            cdts: ClientDocumentType[],
            cdt: ClientDocumentType
          ) => {
            await updateCdtStatus(cdts);
            await updateParentCdt(cdts, cdt);
            props.setOpenModal(false);
          }}
          onCancel={() => {
            props.setOpenModal(false);
          }}
        />
      </div>
    );
  };

  const getActionButton = (cdt: ClientDocumentType) => {
    if (
      props.documentTypesViewType[cdt.id] &&
      props.documentTypesViewType[cdt.id] === "document"
    ) {
      openDocUrl(cdt.document.id);
      return;
    }

    props.setModalComponent(generateModalComponent(cdt));
    props.setOpenModal(true);
  };

  const displayRightIcon = (cdt: ClientDocumentType) => {
    if (cdt.status === ClientDocumentTypeStatus.VALIDATED) {
      return (
        <ValidatedIcon
          cursor="pointer"
          onClick={async () => {
            if (props.documentTypesViewType[cdt.id]) {
              switch (props.documentTypesViewType[cdt.id]) {
                case "documentList":
                  props.setModalComponent(generateModalComponent(cdt));
                  props.setOpenModal(true);
                  break;
                case "budgetInsight":
                  if (
                    ["PROPERTY_TAX", "TAX_RETURN", "HOUSING_TAX"].includes(
                      props.cdt.documentType?.name || ""
                    )
                  ) {
                    props.setModalComponent(generateModalComponent(cdt));
                    props.setOpenModal(true);
                    return;
                  }
                  const cdts = await getBIClientDoucmentTypesChildren(
                    props.cdt.id
                  );
                  if (cdts.length === 1 && cdts[0].id === cdt.id) {
                    // only one and the same !
                    await props.setCdtStatus(
                      ClientDocumentTypeStatus.SUBMITTED,
                      cdt.id,
                      cdt.clientId
                    );
                  } else {
                    await updateCdtStatus(
                      cdts.map((c) => ({
                        ...c,
                        status: ClientDocumentTypeStatus.SUBMITTED,
                      }))
                    );
                    await props.setCdtStatus(
                      ClientDocumentTypeStatus.SUBMITTED,
                      cdt.id,
                      cdt.clientId
                    );
                  }
                  break;
                default:
                  await props.setCdtStatus(
                    ClientDocumentTypeStatus.SUBMITTED,
                    cdt.id,
                    cdt.clientId
                  );
                  break;
              }
            }
          }}
        />
      );
    }
    if (cdt.status === ClientDocumentTypeStatus.SUBMITTED) {
      return (
        <NotYetValidatedIcon
          cursor="pointer"
          onClick={async () => {
            if (
              props.documentTypesViewType[cdt.id] &&
              props.documentTypesViewType[cdt.id] !== "document"
            ) {
              props.setModalComponent(generateModalComponent(cdt));
              props.setOpenModal(true);
            } else {
              await props.setCdtStatus(
                ClientDocumentTypeStatus.VALIDATED,
                cdt.id,
                cdt.clientId
              );
            }
          }}
        />
      );
    }

    return <></>;
  };

  const displayLeftIcon = (cdt: ClientDocumentType) => {
    if (cdt.status === ClientDocumentTypeStatus.REFUSED) {
      return (
        <RefusedIcon
          cursor="pointer"
          onClick={async () => {
            if (
              props.documentTypesViewType[cdt.id] &&
              props.documentTypesViewType[cdt.id] === "documentList"
            ) {
              props.setModalComponent(generateModalComponent(cdt));
              props.setOpenModal(true);
            } else {
              await props.setCdtStatus(
                ClientDocumentTypeStatus.SUBMITTED,
                cdt.id,
                cdt.clientId
              );
            }
          }}
        />
      );
    }
    if (cdt.status === ClientDocumentTypeStatus.SUBMITTED) {
      return (
        <NotYetRefusedIcon
          cursor="pointer"
          onClick={async () => {
            if (
              ["PROPERTY_TAX", "TAX_RETURN", "HOUSING_TAX"].includes(
                props.cdt.documentType?.name || ""
              )
            ) {
              props.setModalComponent(generateModalComponent(cdt));
              props.setOpenModal(true);
              return;
            }
            await props.setCdtStatus(
              ClientDocumentTypeStatus.REFUSED,
              cdt.id,
              cdt.clientId
            );
          }}
        />
      );
    }
    if (cdt.status === ClientDocumentTypeStatus.VALIDATED) {
      <></>;
    }
    return <NoFileIcon />;
  };

  return (
    <>
      <Grid
        key={`val-${props.cdt.id}`}
        container
        direction="row"
        alignItems="center"
      >
        <Grid
          item
          xs={1}
          container
          direction="row"
          alignItems="center"
          justifyContent={"center"}
          pr={1}
        >
          {props.cdt.status !== ClientDocumentTypeStatus.VALIDATED &&
            displayLeftIcon(props.cdt)}
        </Grid>

        <Grid item xs={10}>
          {props.cdt.documentType && (
            <DocumentTypeValidationComponent
              multipleFiles={
                props.documentTypesViewType[props.cdt.id] &&
                props.documentTypesViewType[props.cdt.id] !== "document"
              }
              key={props.cdt.id}
              docType={props.cdt.documentType}
              state={props.cdt.status}
              handleClick={
                [
                  ClientDocumentTypeStatus.SUBMITTED,
                  ClientDocumentTypeStatus.VALIDATED,
                  ClientDocumentTypeStatus.REFUSED,
                ].includes(props.cdt.status)
                  ? () => getActionButton(props.cdt)
                  : () => {}
              }
            />
          )}
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          {[
            ClientDocumentTypeStatus.SUBMITTED,
            ClientDocumentTypeStatus.VALIDATED,
            // ClientDocumentTypeStatus.REFUSED,
          ].includes(props.cdt.status) && displayRightIcon(props.cdt)}
        </Grid>
      </Grid>
      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("global.popup-confirm-cancel-header")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("global.popup-confirm-cancel-text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false);
            }}
          >
            {t("global.popup-confirm-cancel-ko")}
          </Button>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false);
              props.setOpenModal(false);
              if (props.setAcceptBackdropClick !== undefined) {
                props.setAcceptBackdropClick(true);
              }
            }}
            autoFocus
          >
            {t("global.popup-confirm-cancel-ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
