import {
  Borrower,
  ClientDocumentType,
  ClientDocumentTypeStatus,
} from "../types/caseForm.type";
import { ClientDocumentGroup } from "../types/client/clientDocumentGroup";
const manualCivilStateNumber = 200;
const manualIncomeNumber = 300;

export const getGroupNameFromDocumentType = (
  cdt: ClientDocumentType
): string => {
  if (cdt.documentType?.source === "budgetInsightDocument") {
    if (
      [
        "PERSONAL_ACCOUNT_STATEMENT",
        "BUSINESS_ACCOUNT_STATEMENT",
        "SCI_ACCOUNT_STATEMENT",
        "LOANS_REPAID_PROOF",
        "RIB",
      ].includes(cdt.documentType!.name)
    ) {
      return "grp-auto-bank";
    }
    if (
      [
        "PROPERTY_TAX_N-1",
        "PROPERTY_TAX_N-2",
        "PROPERTY_TAX_N-3",
        "HOUSING_TAX",
        "TAX_RETURN_N-1",
        "TAX_RETURN_N-2",
        "TAX_RETURN_N-3",
      ].includes(cdt.documentType!.name)
    ) {
      return "grp-auto-tax";
    }
    if (["PROOF_OF_ADDRESS"].includes(cdt.documentType!.name)) {
      return "grp-auto-proof";
    }
  }
  if (
    cdt.documentType!.globalCourtageCode >= manualCivilStateNumber &&
    cdt.documentType!.globalCourtageCode < manualCivilStateNumber + 99
  ) {
    return "grp-manual-civil-state";
  }
  if (
    cdt.documentType!.globalCourtageCode >= manualIncomeNumber &&
    cdt.documentType!.globalCourtageCode < manualIncomeNumber + 99
  ) {
    return "grp-manual-income";
  }
  return "grp-manual-others";
};
export const getGroupDocumentTypes = (
  groupCode: string,
  cdts: ClientDocumentType[]
): ClientDocumentType[] => {
  const autoDocs = cdts.filter(
    (cdt) => cdt.documentType?.source === "budgetInsightDocument"
  );
  const manualDocs = cdts.filter(
    (cdt) => cdt.documentType?.source !== "budgetInsightDocument"
  );

  let filteredCdts: ClientDocumentType[] = [];
  switch (groupCode) {
    case "grp-auto-bank":
      filteredCdts = cdts.filter((d) =>
        [
          "PERSONAL_ACCOUNT_STATEMENT",
          "BUSINESS_ACCOUNT_STATEMENT",
          "SCI_ACCOUNT_STATEMENT",
          "LOANS_REPAID_PROOF",
          "RIB",
        ].includes(d.documentType!.name)
      );
      break;
    case "grp-auto-tax":
      filteredCdts = autoDocs.filter((d) =>
        [
          "PROPERTY_TAX_N-1",
          "PROPERTY_TAX_N-2",
          "PROPERTY_TAX_N-3",
          "HOUSING_TAX",
          "TAX_RETURN_N-1",
          "TAX_RETURN_N-2",
          "TAX_RETURN_N-3",
        ].includes(d.documentType!.name)
      );
      break;
    case "grp-auto-proof":
      filteredCdts = autoDocs.filter((d) =>
        ["PROOF_OF_ADDRESS"].includes(d.documentType!.name)
      );
      break;
    case "grp-manual-civil-state":
      filteredCdts = manualDocs.filter(
        (d) =>
          d.documentType!.globalCourtageCode >= manualCivilStateNumber &&
          d.documentType!.globalCourtageCode < manualCivilStateNumber + 99
      );
      break;
    case "grp-manual-income":
      filteredCdts = manualDocs.filter(
        (d) =>
          d.documentType!.globalCourtageCode >= manualIncomeNumber &&
          d.documentType!.globalCourtageCode < manualIncomeNumber + 99
      );
      break;
    case "grp-manual-others":
      filteredCdts = manualDocs.filter(
        (d) =>
          d.documentType!.globalCourtageCode < manualCivilStateNumber ||
          d.documentType!.globalCourtageCode > manualIncomeNumber + 99
      );
      break;

    default:
      break;
  }
  return filteredCdts;
};

export const getOrderedListOfDocumentTypes = (
  cdts: ClientDocumentType[]
): ClientDocumentGroup[] => {
  const groups: ClientDocumentGroup[] = [
    {
      code: "grp-auto",
      label: "cdts.auto",
      subGroups: [
        { code: "grp-auto-bank", label: "cdts.auto-bank-statements" },
        { code: "grp-auto-tax", label: "cdts.auto-tax" },
        { code: "grp-auto-proof", label: "cdts.auto-housing-proof" },
      ].map((sg) => ({
        ...sg,
        cdts: getGroupDocumentTypes(sg.code, cdts),
        completed: false,
      })),
    },
    {
      code: "grp-manual",
      label: "cdts.manual",
      subGroups: [
        { code: "grp-manual-civil-state", label: "cdts.manual-civil-state" },
        { code: "grp-manual-income", label: "cdts.manual-income" },
        { code: "grp-manual-others", label: "cdts.manual-others" },
      ].map((sg) => ({
        ...sg,
        cdts: getGroupDocumentTypes(sg.code, cdts),
        completed: false,
      })),
    },
  ];
  groups.forEach((g) => {
    g.subGroups.forEach(
      (sg) =>
        (sg.completed =
          sg.cdts.filter((c) =>
            [
              ClientDocumentTypeStatus.UNSET,
              ClientDocumentTypeStatus.REFUSED,
            ].includes(c.status)
          ).length === 0)
    );
  });
  return groups;
};
