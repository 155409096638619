import { createSlice } from "@reduxjs/toolkit";
import { backContext } from "../types/backContext.type";

const initialState: { back: backContext | null } = { back: null };

export const backSlice = createSlice({
  name: "back",
  initialState,

  reducers: {
    customBackContext: (state, action) => {
      state.back = action.payload as backContext;
    },
    clearBackContext: (state) => {
      state.back = null;
    },
  },
});

export const selectBack = (state: { back: { back: backContext } }) =>
  state.back.back;

export const { customBackContext, clearBackContext } = backSlice.actions;

export default backSlice.reducer;
