import {
  Box,
  Grid,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { orderBy } from "lodash";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import CaseDocumentValidationRow from "../../components/case/caseDocumentValidationRow";
import Loader from "../../components/common/loading";
import { routeType } from "../../routes";
import {
  getCaseForValidation,
  sendReflate,
  updateCdtStatus,
} from "../../services/case.api";
import { sendGenerateCase } from "../../services/upload.api";
import { enqueueSnackbar } from "notistack";
import { ReactComponent as CommonFilesIcon } from "../../resources/icons/restitution/common-files.svg";

import {
  Borrower,
  Case,
  ClientDocumentType,
  ClientDocumentTypeStatus,
} from "../../types/caseForm.type";
import { MultiStateDisplay } from "../../components/case/MultiStateDisplay";

import "../../styles/case/caseValidation.scss";
import CaseStepper from "../../components/case/caseStepper";

export default function CaseDocumentValidation() {
  const [aCase, setCase] = useState<Case>();
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [acceptBackdropClick, setAcceptBackdropClick] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openReflateModal, setOpenReflateModal] = useState(false);
  const [modalComponent, setModalComponent] = useState<JSX.Element>();
  const [documentTypesViewType, setDocumentTypesViewType] = useState<{
    [op: string]: "document" | "budgetInsight" | "documentList";
  }>({});

  useEffect(() => {
    (async () => {
      if (!params.id) {
        enqueueSnackbar(t("errors.no-case-id"), { variant: "error" });
        navigate(-1);
      } else {
        getData(params.id);
      }
    })();
  }, [dispatch, navigate, params.id]);

  const getData = async (caseId: string) => {
    const theCase = await getCaseForValidation(caseId);

    const documentTypesViewTypes: {
      [op: string]: "document" | "budgetInsight" | "documentList";
    } = {};
    const clientDocTypes: ClientDocumentType[] = theCase.clientDocumentTypes;

    clientDocTypes.forEach((x) => {
      if (
        x.budgetInsightConnectionIds &&
        x.budgetInsightConnectionIds.length > 0
      ) {
        documentTypesViewTypes[x.id] = "budgetInsight";
      } else if (x.document) {
        documentTypesViewTypes[x.id] = "document";
      } else {
        documentTypesViewTypes[x.id] = "documentList";
      }
    });

    setDocumentTypesViewType(documentTypesViewTypes);
    setCase(theCase);
  };

  const refreshCurrentCaseData = async () => {
    if (aCase && aCase.id) {
      getData(aCase.id);
    }
  };

  const setCdtStatus = async (
    status: ClientDocumentTypeStatus,
    cdtId: string,
    borrowerId?: string
  ) => {
    const caseToUpdate = { ...aCase };
    if (!borrowerId && caseToUpdate.clientDocumentTypes) {
      caseToUpdate.clientDocumentTypes = caseToUpdate.clientDocumentTypes.map(
        (x) => {
          if (x.id === cdtId) {
            x.status = status;
          }
          return x;
        }
      );
    } else {
      if (caseToUpdate.borrowers) {
        caseToUpdate.borrowers = caseToUpdate.borrowers?.map((x) => {
          if (x.id === borrowerId) {
            x.clientDocumentTypes = x.clientDocumentTypes?.map((x) => {
              if (x.id === cdtId) {
                x.status = status;
              }
              return x;
            });
          }
          return x;
        });
        if (caseToUpdate.clientDocumentTypes) {
          caseToUpdate.clientDocumentTypes =
            caseToUpdate.clientDocumentTypes.map((x) => {
              if (x.id === cdtId && x.clientId === borrowerId) {
                x.status = status;
              }
              return x;
            });
        }
      }
    }
    await updateCdtStatus([{ status, id: cdtId } as ClientDocumentType]);

    setCase(caseToUpdate as any);
  };

  const displayEmailElement = (
    borrower: Borrower,
    hasAtLeastOneMaster = false
  ) => {
    return (
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center">
          <Grid
            item
            xs={1}
            container
            direction="row"
            alignItems="center"
          ></Grid>
          <Grid item xs={10} className="clients-email">
            {borrower && (
              <MultiStateDisplay
                currentState={
                  hasAtLeastOneMaster && !borrower.isMaster
                    ? "unselected"
                    : "selected"
                }
                label={borrower.email || ""}
                mode={"selectableUnselectable"}
                onChangeState={() => {
                  console.log("on change le master");
                }}
              />
            )}
          </Grid>
          <Grid item xs={1} container justifyContent="flex-end"></Grid>
        </Grid>
      </Grid>
    );
  };

  const colPadding = aCase?.nbBorrower === 2 ? 3 : 0;
  const hasAtLeastOneMaster =
    (aCase && aCase?.borrowers.filter((b) => b.isMaster).length > 0) || false;
  return (
    <div>
      {loading ? (
        <Loader title={t("case.generate-case-loading") || ""} />
      ) : (
        <>
          <Box alignItems={"center"} justifyContent={"center"} display={"flex"}>
            <h3>
              <u>{t("case.restitution")}</u>
            </h3>
          </Box>
          <CaseStepper nbSteps={3} position={2}></CaseStepper>
          <div>
            <Grid container direction="row" alignItems="stretch">
              <Grid item xs={12} lg={4} paddingX={colPadding}>
                <h4>
                  {aCase?.borrowers[0].firstName +
                    " " +
                    aCase?.borrowers[0].lastName}
                </h4>
                <Grid container alignItems="center" spacing={2}>
                  {aCase?.borrowers[0] &&
                    displayEmailElement(
                      aCase?.borrowers[0],
                      hasAtLeastOneMaster
                    )}
                  {orderBy(
                    aCase?.nbBorrower === 2
                      ? aCase?.borrowers[0]?.clientDocumentTypes
                      : aCase?.clientDocumentTypes,
                    (x) =>
                      x.documentType
                        ? +x?.documentType.globalCourtageCode
                        : null
                  ).map((x) => {
                    if (!x || !x.documentType) {
                      return "";
                    } else {
                      return (
                        <Grid item xs={12} key={`grid-val-${x.id}`}>
                          <CaseDocumentValidationRow
                            aCase={aCase}
                            cdt={x}
                            documentTypesViewType={documentTypesViewType}
                            refreshData={refreshCurrentCaseData}
                            setAcceptBackdropClick={setAcceptBackdropClick}
                            setCdtStatus={setCdtStatus}
                            setModalComponent={setModalComponent}
                            setOpenModal={setOpenModal}
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>
              {aCase && aCase.nbBorrower === 2 && (
                <>
                  <Grid item xs={12} lg={4} paddingX={colPadding}>
                    <h4>&nbsp;</h4>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        <CommonFilesIcon height={"30px"} />
                      </Grid>
                      {orderBy(aCase?.clientDocumentTypes, (x) =>
                        x.documentType
                          ? +x?.documentType.globalCourtageCode
                          : null
                      )
                        .filter((x) => !x.clientId)
                        .map((x) => {
                          if (!x || !x.documentType) {
                            return "";
                          } else {
                            return (
                              <Grid item xs={12} key={`grid-val-${x.id}`}>
                                <CaseDocumentValidationRow
                                  refreshData={refreshCurrentCaseData}
                                  aCase={aCase}
                                  cdt={x}
                                  documentTypesViewType={documentTypesViewType}
                                  setCdtStatus={setCdtStatus}
                                  setModalComponent={setModalComponent}
                                  setOpenModal={setOpenModal}
                                  setAcceptBackdropClick={
                                    setAcceptBackdropClick
                                  }
                                />
                              </Grid>
                            );
                          }
                        })}{" "}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} lg={4} paddingX={colPadding}>
                    <h4>
                      {aCase?.borrowers[1].firstName +
                        " " +
                        aCase?.borrowers[1].lastName}
                    </h4>
                    <Grid container alignItems="center" spacing={2}>
                      {aCase?.borrowers[1] &&
                        displayEmailElement(
                          aCase?.borrowers[1],
                          hasAtLeastOneMaster
                        )}
                      {orderBy(aCase?.borrowers[1]?.clientDocumentTypes, (x) =>
                        x.documentType
                          ? +x?.documentType.globalCourtageCode
                          : null
                      ).map((x) => {
                        if (!x || !x.documentType) {
                          return "";
                        } else {
                          return (
                            <Grid item xs={12} key={`grid-val-${x.id}`}>
                              <CaseDocumentValidationRow
                                refreshData={refreshCurrentCaseData}
                                aCase={aCase}
                                cdt={x}
                                documentTypesViewType={documentTypesViewType}
                                setCdtStatus={setCdtStatus}
                                setModalComponent={setModalComponent}
                                setOpenModal={setOpenModal}
                                setAcceptBackdropClick={setAcceptBackdropClick}
                              />
                            </Grid>
                          );
                        }
                      })}{" "}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <br />
          </div>

          <Grid
            container
            direction="row"
            marginTop={"30px"}
            justifyContent={{ xs: "center", lg: "flex-end" }}
            alignItems="center"
          >
            <Grid item xs={12} lg={"auto"}>
              <Button
                className="btn-validations"
                onClick={async () => {
                  if (params.id) {
                    setLoading(true);
                    const file = await sendGenerateCase(params.id);
                    const url = window.URL.createObjectURL(file);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      `rapport_${aCase?.reference}`
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);

                    setLoading(false);
                    enqueueSnackbar(t("successes.report-generation-sent"), {
                      variant: "success",
                    });
                    navigate(routeType.case + "/list");
                  }
                }}
                variant="outlined"
              >
                {t("case.generate-case")}
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              lg={"auto"}
              marginLeft={{ lg: "20px" }}
              marginTop={{ xs: "20px", lg: "0" }}
            >
              <Button
                className="btn-validations last"
                variant="contained"
                onClick={async () => {
                  setOpenReflateModal(true);
                }}
              >
                {t("case.reflate")}
              </Button>
            </Grid>
          </Grid>

          <Modal
            open={openModal}
            onClose={(event, reason) => {
              if (reason === "backdropClick" && !acceptBackdropClick) {
                return;
              }
              setOpenModal(false);
            }}
          >
            <Box
              sx={{
                width: "90%",
                height: "90%",
                overflow: "scroll",
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              {modalComponent}
            </Box>
          </Modal>
          <Dialog
            open={openReflateModal}
            onClose={() => {
              setOpenReflateModal(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent sx={{ padding: 3 }}>
              {t("case.confirm-reflate")}
              <br />
              <br />
              <Button
                onClick={() => {
                  setOpenReflateModal(false);
                }}
                variant="outlined"
                sx={{ m: 2 }}
              >
                {t("global.cancel")}
              </Button>

              <Button
                sx={{ m: 2 }}
                variant="contained"
                onClick={async () => {
                  if (params.id) {
                    await sendReflate(params.id);
                  }
                  setOpenReflateModal(false);
                }}
              >
                {t("global.confirm")}
              </Button>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
}
