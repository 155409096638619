import { useTranslation } from "react-i18next";
import "../../styles/common/footer.scss";
import { Container } from "@mui/material";
export default function Footer() {
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: 1 }}></div>
      <Container maxWidth="xl" className="container_footer">
        <div className="footer">
          <div className="footer-text">
            {t("global.footer", { dynamicValue: new Date().getFullYear(), version: process.env.REACT_APP_VERSION })}
          </div>
        </div>
      </Container>
    </div>
  );
}
