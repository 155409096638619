import { useTheme } from "@mui/material";
import { DataGrid, GridColDef, frFR, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import "../../styles/common/datagrid.scss";

export default function CustomDataGrid(props: {
  rows: any[];
  columns: GridColDef[];
  defaultSorting?: GridSortModel;
}) {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>();

  useEffect(() => {
    setColumns(
      props.columns.map((col) => {
        return {
          flex: 1,
          headerClassName: "table-header",
          ...col,
          align: "center",
          headerAlign: "center",
        };
      })
    );
  }, [props.columns]);

  useEffect(() => {
    setSortModel(props.defaultSorting);
  }, [props.defaultSorting]);

  const theme = useTheme();
  return (
    <div className="grid-container">
      <DataGrid
        sortModel={sortModel ? sortModel : []}
        onSortModelChange={(newSortModel) => {
          setSortModel(newSortModel);
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rows={props.rows}
        headerHeight={35}
        columns={columns}
        pageSize={8}
        rowHeight={75}
        disableSelectionOnClick
        rowsPerPageOptions={[10]}
        autoHeight
        classes={{ root: "grid-no-border" }}
        sx={{
          "&  .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "white",
          },
          "&  .MuiDataGrid-menuIcon": {
            color: "white",
          },
          "&  .MuiDataGrid-menuIconButton": {
            color: theme.palette.primary.contrastText,
          },
          "&  .MuiDataGrid-sortIcon": {
            color: theme.palette.primary.contrastText,
          },
          "& .MuiDataGrid-columnHeader:last-child": {
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
          },
          "& .MuiDataGrid-columnHeader:first-of-type": {
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: theme.palette.primary.main,
          },
          "& .MuiDataGrid-root": {
            borderColor: "white",
            "&:hover": {
              backgroundColor: "blue !important",
            },
          },
        }}
      />
    </div>
  );
}
