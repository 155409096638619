import { Button, Grid, Box } from "@mui/material";
import {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useState,
} from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  FormContainer,
  SelectElement,
  TextFieldElement,
  UseFormStateReturn,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BorrowerForm from "../../components/case/borrowerForm";
import Loader from "../../components/common/loading";
import { createCase, getCase, updateCase } from "../../services/case.api";
import { enqueueSnackbar } from "notistack";

import { clearBackContext, customBackContext } from "../../slice/back.slice";
import "../../styles/case/caseForm.scss";
import {
  AccommodationType,
  BorrowerLink,
  BorrowerSituation,
  Civility,
  ContractType,
  CSP,
  OperationType,
  UseType,
} from "../../types/caseForm.type";
import { CreateCaseDto, UpdateCaseDto } from "../../types/caseService.type";

type CaseFormValue = {
  id?: string;
  mandateNumber: string;
  reference: string;
  operationType: OperationType;
  use: UseType;
  nbBorrower: number;
  // part with 2 borrowers
  borrowersLink?: BorrowerLink | null;
  livingTogether?: boolean | null;
  // part with 2 borrowers and living together
  accommodationType?: AccommodationType | null;
  // part with 1 borrower
  borrowerSituation: BorrowerSituation | null;
  nbCommonChildren?: number | null;
  //first borrower form
  borrowers: {
    id?: string;
    email: string;
    type: ClientTypes;
    accommodationType?: AccommodationType;
    nationality: string;
    civility: Civility;
    firstName: string;
    lastName: string;
    phone: string;
    status: BorrowerSituation | BorrowerLink;
    nbChildren: number;
    csp: CSP;
    contractType?: ContractType;
    sirenSiret: string;
    liberalType?: string;
  }[];
};

const isBooleanValid = (value: boolean) =>
  value !== undefined && value !== null;

export default function CaseForm() {
  const { t } = useTranslation();
  const [form, setForm] = useState<CaseFormValue>();
  let params = useParams();
  useEffect(() => {
    (async () => {
      if (params && params.id) {
        const theCase = await getCase(params.id, true);
        //the form can't handle null value , but he handle undefined values ... so we delete null field
        theCase.id = params.id;
        if (theCase.nbBorrower === 1) {
          delete theCase.livingTogether;
          delete theCase.borrowersLink;
          delete theCase.accommodationType;
        }
        if (theCase.nbBorrower === 2) {
          delete theCase.borrowerSituation;
        }
        if (!theCase.accommodationType) {
          delete theCase.accommodationType;
        }
        theCase.borrowers.forEach((x: any) => {
          if (!x.liberalType) {
            delete x.liberalType;
          }
          if (!x.contractType) {
            delete x.contractType;
          }
          if (!x.accommodationType) {
            delete x.accommodationType;
          }
        });
        setForm(theCase);
        setNbBorrower(theCase.nbBorrower);
        setLivingTogether(theCase.livingTogether);
      }
    })();
  }, [params.id]);
  const [nbBorrower, setNbBorrower] = useState<number>();
  const [livingTogether, setLivingTogether] = useState<boolean>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      customBackContext({
        disabled: true,
        className: "hide-back-button",
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  const handleSubmit = async (formValues: CaseFormValue) => {
    try {
      if (formValues.nbBorrower === 1) {
        if (formValues.borrowers.length === 2) {
          formValues.borrowers.pop();
        }
        formValues.livingTogether = null;
        formValues.borrowersLink = null;
        formValues.nbCommonChildren = null;

        if (
          !formValues.borrowers[0].accommodationType &&
          formValues.accommodationType
        ) {
          formValues.borrowers[0].accommodationType =
            formValues.accommodationType;
        }
        formValues.accommodationType = null;
      } else {
        formValues.borrowerSituation = null;
      }
      if (formValues.nbCommonChildren !== undefined) {
        formValues.nbCommonChildren = formValues.nbCommonChildren
          ? +formValues.nbCommonChildren
          : 0;
      }

      formValues.borrowers.forEach((borrower) => {
        borrower.nbChildren = +borrower.nbChildren;
      });
      setLoading(true);
      setForm(formValues);
      let caseToProcess = null;
      if (formValues && formValues.id) {
        caseToProcess = await updateCase(formValues as UpdateCaseDto);
        enqueueSnackbar(t("successes.case-update"), { variant: "success" });
      } else {
        caseToProcess = await createCase(formValues as CreateCaseDto);
        enqueueSnackbar(t("successes.case-create"), { variant: "success" });
      }
      navigate(`/case/${caseToProcess.id}/document`);
    } catch (error: any) {
      let message = t("errors.create-case");
      enqueueSnackbar(message);
    } finally {
      setLoading(false);
    }
  };

  const displaySubForm = (index: number) => {
    return (
      <Grid item xs={12} lg={6} key={"borrower-form-" + index} mt={1}>
        <BorrowerForm
          index={index}
          livingTogether={livingTogether}
          csp={form?.borrowers[index]?.csp || undefined}
          firstName={form?.borrowers[index]?.firstName || undefined}
          lastName={form?.borrowers[index]?.lastName || undefined}
        />
      </Grid>
    );
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          defaultValues={form}
          values={form}
          onSuccess={handleSubmit}
        >
          <Box textAlign={"left"}>
            <h3> {params.id ? t("case.edit-case") : t("case.new")}</h3>
            <Grid container spacing={4} sx={{ textAlign: "left" }}>
              <Grid item xs={12} lg={6}>
                <TextFieldElement
                  className="form-input left"
                  name="mandateNumber"
                  label={t("case.form-mandate")}
                  validation={{ required: true }}
                />
                <TextFieldElement
                  className="form-input"
                  name="reference"
                  label={t("case.form-reference")}
                  validation={{ required: true }}
                />
                <br />
                <SelectElement
                  options={Object.keys(OperationType).map((key) => ({
                    id: key,
                    label: t("case.operation-type-" + key),
                  }))}
                  className="form-input left"
                  name="operationType"
                  label={t("case.form-operation")}
                  validation={{ required: true }}
                  InputProps={{
                    sx: { textAlign: "left" },
                  }}
                />
                <SelectElement
                  options={Object.keys(UseType).map((key) => ({
                    id: key,
                    label: t("case.use-type-" + key),
                  }))}
                  className="form-input"
                  inputProps={{
                    sx: { textAlign: "left" },
                  }}
                  name="use"
                  label={t("case.form-use")}
                  validation={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SelectElement
                  options={[
                    { id: 1, label: "1" },
                    { id: 2, label: "2" },
                  ]}
                  className="form-input left"
                  name="nbBorrower"
                  label={t("case.form-nb-borrower")}
                  onChange={(value) => {
                    setNbBorrower(value);
                  }}
                  validation={{ required: true }}
                  InputProps={{
                    sx: { textAlign: "left" },
                  }}
                />
                {nbBorrower && nbBorrower === 2 && (
                  <SelectElement
                    options={Object.keys(BorrowerLink).map((key) => ({
                      id: key,
                      label: t("case.borrower-link-" + key),
                    }))}
                    className="form-input"
                    name="borrowersLink"
                    label={t("case.form-link")}
                    validation={{ required: true }}
                    InputProps={{
                      sx: { textAlign: "left" },
                    }}
                  />
                )}
                {nbBorrower && nbBorrower === 1 && (
                  <SelectElement
                    options={Object.keys(BorrowerSituation).map((key) => ({
                      id: key,
                      label: t("case.borrower-situation-" + key),
                    }))}
                    className="form-input"
                    name="borrowerSituation"
                    label={t("case.form-situation")}
                    validation={{ required: true }}
                    InputProps={{
                      sx: { textAlign: "left" },
                    }}
                  />
                )}
                <br />

                {nbBorrower && nbBorrower === 2 && (
                  <SelectElement
                    options={[
                      { id: true, label: "Oui" },
                      { id: false, label: "Non" },
                    ]}
                    className="form-input left"
                    name="livingTogether"
                    validation={{
                      validate: isBooleanValid,
                    }}
                    label={t("case.form-together")}
                    onChange={(value) => setLivingTogether(value)}
                    InputProps={{
                      sx: { textAlign: "left" },
                    }}
                  />
                )}
                {livingTogether && (
                  <SelectElement
                    options={Object.keys(AccommodationType).map((key) => ({
                      id: key,
                      label: t("case.accommodation-type-" + key),
                    }))}
                    className="form-input"
                    name="accommodationType"
                    label={t("case.form-accommodation")}
                    validation={{ required: true }}
                    InputProps={{
                      sx: { textAlign: "left" },
                    }}
                  />
                )}
                <br />
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={4} textAlign={"justify"}>
            {nbBorrower && (
              <>
                {displaySubForm(0)}
                {nbBorrower === 2 && displaySubForm(1)}
              </>
            )}
          </Grid>

          {nbBorrower && (
            <Grid
              container
              spacing={4}
              textAlign={"justify"}
              mt={3}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={12}
                lg={12}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Controller
                  defaultValue={0}
                  render={function ({
                    field,
                    fieldState,
                    formState,
                  }: {
                    field: ControllerRenderProps<FieldValues, string>;
                    fieldState: ControllerFieldState;
                    formState: UseFormStateReturn<FieldValues>;
                  }): ReactElement<any, string | JSXElementConstructor<any>> {
                    return (
                      <TextFieldElement
                        type={"number"}
                        InputProps={{ inputProps: { min: 0, max: 20 } }}
                        className="form-input children"
                        name="borrowers.0.nbChildren"
                        label={
                          t("case.form-nb-children") +
                          " " +
                          t("case.form-borrower") +
                          " 1"
                        }
                        validation={{ required: true }}
                      />
                    );
                  }}
                  name="borrowers.0.nbChildren"
                ></Controller>

                {nbBorrower === 2 && (
                  <>
                    <Controller
                      defaultValue={0}
                      render={function ({
                        field,
                        fieldState,
                        formState,
                      }: {
                        field: ControllerRenderProps<FieldValues, string>;
                        fieldState: ControllerFieldState;
                        formState: UseFormStateReturn<FieldValues>;
                      }): ReactElement<
                        any,
                        string | JSXElementConstructor<any>
                      > {
                        return (
                          <TextFieldElement
                            type={"number"}
                            InputProps={{ inputProps: { min: 0, max: 20 } }}
                            className="form-input children"
                            name="nbCommonChildren"
                            label={
                              t("case.form-nb-children") +
                              " " +
                              t("case.common")
                            }
                            validation={{ required: true }}
                          />
                        );
                      }}
                      name="nbCommonChildren"
                    ></Controller>
                    <Controller
                      defaultValue={0}
                      render={function ({
                        field,
                        fieldState,
                        formState,
                      }: {
                        field: ControllerRenderProps<FieldValues, string>;
                        fieldState: ControllerFieldState;
                        formState: UseFormStateReturn<FieldValues>;
                      }): ReactElement<
                        any,
                        string | JSXElementConstructor<any>
                      > {
                        return (
                          <TextFieldElement
                            type={"number"}
                            InputProps={{ inputProps: { min: 0, max: 20 } }}
                            className="form-input children"
                            name="borrowers.1.nbChildren"
                            label={
                              t("case.form-nb-children") +
                              " " +
                              t("case.form-borrower") +
                              " 2"
                            }
                            validation={{ required: true }}
                          />
                        );
                      }}
                      name="borrowers.1.nbChildren"
                    ></Controller>
                  </>
                )}
              </Grid>
            </Grid>
          )}

          <Grid container direction={"row-reverse"} spacing={4} mt={2}>
            <Grid item xs={8} lg={2}>
              <Button variant="contained" type="submit" fullWidth>
                {t("case.form-start")}
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      )}
    </div>
  );
}
