import { Box, Grid, Tooltip, lighten, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Borrower } from "../../types/caseForm.type";
import { ReactComponent as NotYetValidatedIcon } from "../../resources/icons/restitution/circle-validate.svg";
import { ReactComponent as ValidatedIcon } from "../../resources/icons/restitution/validated.svg";
import "../../styles/case/caseValidation.scss";

export default function BudgetInsightDocumentSelectBorrower(props: {
  borrowers: Borrower[];
  selected: string[];
  updateSelected: Function;
  hasCommonFeature: boolean;
  readonly?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const params = useParams();
  const [selectedBorrowers, setSelectedBorrowers] = useState<string[]>([]);
  const [showBoxBorrower, setShowBoxBorrower] = useState(false);
  useEffect(() => {
    (async () => {
      setSelectedBorrowers(props.selected);
    })();
  }, [params, props.borrowers, props.selected]);

  const displayInitials = (borrower?: Borrower) => {
    return borrower ? borrower.lastName[0] + borrower.firstName[0] : "??";
  };
  const toggleBorrowers = () => {
    if (props.readonly) {
      return;
    }
    setShowBoxBorrower(!showBoxBorrower);
  };
  const displayBorrowers = () => {
    if (props.readonly) {
      return;
    }
    setShowBoxBorrower(true);
  };
  const hideBorrowers = () => {
    if (props.readonly) {
      return;
    }
    setShowBoxBorrower(false);
  };

  const toggleSelectedBorrower = (borrowerId: string) => {
    let selectedNew: string[];
    if (props.hasCommonFeature) {
      selectedNew = selectedBorrowers.includes(borrowerId)
        ? selectedBorrowers.filter((sb) => sb !== borrowerId)
        : selectedBorrowers.concat([borrowerId]);
    } else {
      selectedNew = selectedBorrowers.includes(borrowerId)
        ? selectedBorrowers.filter((sb) => sb !== borrowerId)
        : [borrowerId];
    }

    setSelectedBorrowers(selectedNew);
    props.updateSelected(selectedNew);
  };

  return (
    <div style={{ position: "relative" }}>
      <Grid
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
        marginRight={1}
        spacing={0.5}
        className="borrowers-initals"
        style={{ width: "80px" }}
        onMouseLeave={() => hideBorrowers()}
      >
        {selectedBorrowers.length === 0 && (
          <Box
            bgcolor={lighten(theme.palette.primary.main, 0.2)}
            paddingX={0.5}
            borderRadius={"4px"}
            color={theme.palette.primary.contrastText}
            width={"2em"}
            textAlign={"center"}
            onClick={() => toggleBorrowers()}
            onMouseEnter={() => displayBorrowers()}
          >
            ?
          </Box>
        )}
        {selectedBorrowers.length > 0 &&
          selectedBorrowers.map((selectedBorrowerId) => (
            <Box
              key={`box-bor-${selectedBorrowerId}`}
              bgcolor={lighten(theme.palette.primary.main, 0.2)}
              paddingX={0.5}
              borderRadius={"4px"}
              color={theme.palette.primary.contrastText}
              width={"2em"}
              textAlign={"center"}
              textTransform={"uppercase"}
              onClick={() => toggleBorrowers()}
              onMouseEnter={() => displayBorrowers()}
              marginRight={"3px"}
            >
              {displayInitials(
                props.borrowers.find((b) => b.id === selectedBorrowerId)
              )}
            </Box>
          ))}
        {showBoxBorrower && (
          <Box
            position={"absolute"}
            bgcolor={"white"}
            className="tooltip-borrowers"
            zIndex="tooltip"
            padding={1}
            top={18}
            left={42}
            minWidth={"300px"}
            // border={"1px solid"}
            // borderColor={theme.palette.primary.main}
            onMouseLeave={() => hideBorrowers()}
          >
            {props.borrowers.map((b) => (
              <Grid
                key={`select-bor-${b.id}`}
                container
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Box onClick={() => toggleSelectedBorrower(b.id!)}>
                  {selectedBorrowers.includes(b.id || "") ? (
                    <ValidatedIcon></ValidatedIcon>
                  ) : (
                    <NotYetValidatedIcon></NotYetValidatedIcon>
                  )}
                </Box>
                <Box marginLeft={1}>
                  {b.lastName.toLocaleUpperCase()} {b.firstName}
                </Box>
              </Grid>
            ))}
          </Box>
        )}
      </Grid>
    </div>
  );
}
