import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  Hidden,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Webcam from "react-webcam";
import { isDesktop } from "react-device-detect";

import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { getClientDocumentType } from "../../services/case.api";
import { ClientDocumentType } from "../../types/caseForm.type";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { sendFile, uploadFiles } from "../../services/upload.api";
import { routeType } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import "../../styles/client/client.scss";
import "../../styles/client/client-upload.scss";

import { selectUser } from "../../slice/login.slice";
import React from "react";
import Upload from "../../components/common/upload";
import Loader from "../../components/common/loading";
import { ReactComponent as ToProcessIcon } from "../../resources/icons/toValidate.svg";
import { ReactComponent as Processed } from "../../resources/icons/processed.svg";
import OldCniImage from "../../resources/identity/old-cni.png";
import NewCniImage from "../../resources/identity/new-cni.png";
import PassportImage from "../../resources/identity/passport.png";
import OldCniIconRecto from "../../resources/identity/icon-old-cni.png";
import OldCniIconVerso from "../../resources/identity/icon-old-cni2.png";
import NewCniIconRecto from "../../resources/identity/icon-new-cni.png";
import NewCniIconVerso from "../../resources/identity/icon-new-cni2.png";
import PassportIcon from "../../resources/identity/icon-passport.png";
import { ReactComponent as BackIcon } from "../../resources/icons/back.svg";

import { dataURIToFile } from "../../services/utils";
import UploadDesktopQrCode from "../../components/client/uploadDesktopQrCode";
import { LocalStorageKey } from "../../types/localStorageEnum";

export enum IdentityType {
  OLD_CNI = "OLD_CNI",
  NEW_CNI = "NEW_CNI",
  PASSPORT = "PASSPORT",
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function UploadIdentityFile(props: { fromQrCode: boolean }) {
  const params = useParams();
  const theme = useTheme();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");
  const tmpCdtId = new URLSearchParams(search).get("cdtId");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [clientDocumentType, setClientDocumentType] =
    useState<ClientDocumentType>();
  const [displayLikeMobile, setDisplayLikeMobile] = useState(false);

  const [selectedIdentity, setSelectedIdentity] = useState<IdentityType>();
  const [isOkCamera, setIsOkCamera] = useState<boolean>(false);
  const [isVerso, setIsVerso] = useState<boolean>(false);
  const [displayCamera, setDisplayCamera] = useState<boolean>(false);
  const [displayEndWorkflowQrCode, setDisplayEndWorkflowQrCode] =
    useState<boolean>(false);
  const [isValidatedEntityType, setIsValidatedEntityType] =
    useState<boolean>(false);
  const jwtPayload = useSelector(selectUser);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [screenshotRecto, setScreenshotRecto] = useState<string>();
  const [screenshotVerso, setScreenshotVerso] = useState<string>();
  const [previousRoute, setPreviousRoute] = useState<string>();
  const [idTimeout, setIdTimeout] = useState<NodeJS.Timeout>();
  const webcamRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (!imageSrc) {
        return;
      }
      if (!screenshotRecto) {
        setScreenshotRecto(imageSrc);
      } else {
        setScreenshotVerso(imageSrc);
      }
      setDisplayCamera(false);
    }
  }, [webcamRef, screenshotRecto, screenshotVerso]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (props.fromQrCode && token && tmpCdtId) {
        localStorage.setItem(LocalStorageKey.KEY_TOKEN_LOCALSTORAGE, token);
        try {
          const cdt = await getClientDocumentType(tmpCdtId);
          if (cdt) {
            setClientDocumentType(cdt);
          }
        } catch (error) {
          enqueueSnackbar(t("errors.cannot_get_case_qr"), { variant: "error" });
        }
      }
      if (!props.fromQrCode && params.cdtId) {
        const cdt = await getClientDocumentType(params.cdtId);
        setClientDocumentType(cdt);
        const route =
          routeType.client +
          "/" +
          jwtPayload.id +
          (jwtPayload.id === cdt.client?.id ? "" : "/co-emprunteur") +
          "/groupe/grp-manual-civil-state";
        setPreviousRoute(route);
      }
    })();
  }, []);

  const sendFilesReceivedFromMobile = async (filesToSend: any[]) => {
    try {
      if (clientDocumentType?.id && filesToSend.length > 0) {
        const filesConverted = [filesToSend[0]];

        if (filesToSend.length > 1) {
          filesConverted.push(filesToSend[1]);
        }
        const type = filesConverted.length === 1 ? "PASSPORT" : "CNI";
        await uploadFiles(clientDocumentType?.id, filesConverted, type);
        enqueueSnackbar(t("successes.upload"), { variant: "success" });
        if (previousRoute) {
          navigate(previousRoute);
        }
      }
    } catch (err) {
      console.log("ERR on upload : ", err);
      enqueueSnackbar(t("errors.upload"), { variant: "error" });
    }
  };

  const sendFiles = async () => {
    if (clientDocumentType?.id) {
      try {
        const type =
          selectedIdentity === IdentityType.PASSPORT ? "PASSPORT" : "CNI";
        if (!screenshotRecto) {
          return;
        }
        if (props.fromQrCode && token) {
          await sendFile(
            token,
            dataURIToFile(screenshotRecto, "cni-recto.jpg") as any
          );
          if (screenshotVerso) {
            await sendFile(
              token,
              dataURIToFile(screenshotVerso, "cni-verso.jpg") as any
            );
          }
          setDisplayEndWorkflowQrCode(true);
          enqueueSnackbar(t("successes.upload"), { variant: "success" });
        } else {
          const files = [dataURIToFile(screenshotRecto, "cni-recto.jpg")];
          if (screenshotVerso) {
            files.push(dataURIToFile(screenshotVerso, "cni-verso.jpg"));
          }
          await uploadFiles(clientDocumentType?.id, files, type);
          enqueueSnackbar(t("successes.upload"), { variant: "success" });
          if (previousRoute) {
            navigate(previousRoute);
          }
        }
      } catch (err) {
        console.log("ERR on upload : ", err);
        enqueueSnackbar(t("errors.upload"), { variant: "error" });
      }
    }
  };

  const displayBackButton = (callback: Function, color?: string) => {
    return (
      <Button sx={{ minWidth: 0 }} onClick={() => callback()}>
        <BackIcon
          fill={color || theme.palette.primary.main}
          color={color || "primary"}
        />
      </Button>
    );
  };

  const diplaySelectButton = (identityType: IdentityType) => {
    if (selectedIdentity === identityType) {
      return (
        <Processed
          fill={theme.palette.primary.main}
          stroke={theme.palette.primary.main}
          color={"primary"}
          width={"100%"}
          height={22}
          style={{ verticalAlign: "middle" }}
          onClick={() => setSelectedIdentity(undefined)}
        />
      );
    }
    return (
      <ToProcessIcon
        stroke={theme.palette.primary.main}
        color={"primary"}
        height={20}
        style={{ verticalAlign: "middle" }}
        onClick={() => setSelectedIdentity(identityType)}
      />
    );
  };

  const displayIntroCamera = () => {
    let title = "upload.intro-camera-cni";
    let subtitle = "upload.intro-camera-cni-subtitle";
    let icon = OldCniIconRecto;
    if (!isVerso) {
      if (selectedIdentity === IdentityType.PASSPORT) {
        title = "upload.intro-camera-passport";
        subtitle = "upload.intro-camera-passport-subtitle";
      }

      switch (selectedIdentity) {
        case IdentityType.NEW_CNI:
          icon = NewCniIconRecto;
          break;
        case IdentityType.PASSPORT:
          icon = PassportIcon;
          break;
      }
    } else {
      title = "upload.intro-camera-cni2";
      subtitle = "upload.intro-camera-cni2-subtitle";
      switch (selectedIdentity) {
        case IdentityType.OLD_CNI:
          icon = OldCniIconVerso;
          break;
        case IdentityType.NEW_CNI:
          icon = NewCniIconVerso;
          break;
      }
    }

    return (
      <>
        <Grid item textAlign={"left"}>
          {displayBackButton(() => {
            if (idTimeout) {
              clearTimeout(idTimeout);
              setIdTimeout(undefined);
            }
            setDisplayCamera(false);
            setIsValidatedEntityType(false);
          })}
        </Grid>
        <Grid
          item
          container
          flexGrow={1}
          direction={{ xs: "column", md: "row" }}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Grid item>
            <img
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "20vh",
              }}
              alt={""}
              src={icon}
            />
          </Grid>
          <Grid item mt={{ xs: 6, md: 0 }}>
            <Typography
              textAlign={{ xs: "center", md: "left" }}
              fontWeight={"bold"}
              mb={2}
            >
              {t(title)}
            </Typography>
            <Typography
              textAlign={{ xs: "center", md: "left" }}
              fontStyle={"italic"}
            >
              {t(subtitle)}
            </Typography>
          </Grid>
          <Grid item>
            <Loader />
          </Grid>
          <Grid item>
            <Typography
              textAlign={{ xs: "center", md: "left" }}
              fontStyle={"italic"}
            >
              {t("upload.identity-wait-camera")}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid sx={{ pt: 1, flexGrow: 1, display: "flex", flexDirection: "column" }}>
      {!clientDocumentType && <Loader />}
      {displayEndWorkflowQrCode && (
        <Grid item>
          <Typography>{t("upload.end-workflow-qrcode")}</Typography>
        </Grid>
      )}
      {/* DISPLAY INITIAL STEP IF DESKTOP : CHOOSE MODE */}
      {!displayEndWorkflowQrCode &&
        clientDocumentType &&
        clientDocumentType.client &&
        isDesktop &&
        !displayLikeMobile &&
        previousRoute && (
          <UploadDesktopQrCode
            identityMode={true}
            previousRoute={previousRoute}
            setDisplayLikeMobile={setDisplayLikeMobile}
            validateFiles={(files) => {
              sendFilesReceivedFromMobile(files);
            }}
            clientDocumentType={clientDocumentType}
          />
        )}

      {/* DISPLAY FIRST STEP : SELECT IDENTITY TYPE */}
      {!displayEndWorkflowQrCode &&
        clientDocumentType &&
        clientDocumentType.client &&
        (!isDesktop || displayLikeMobile) &&
        !isValidatedEntityType && (
          <>
            <Grid item>
              <div className="title">
                {clientDocumentType.client.firstName}{" "}
                {clientDocumentType.client.lastName}
              </div>
            </Grid>
            <Grid item py={2}>
              <div className="notice">{t("upload.identity-title1")}</div>
            </Grid>
            <Grid
              item
              container
              pt={4}
              flexGrow={1}
              direction={"column"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                container
                spacing={1}
                alignItems={"center"}
                onClick={() =>
                  setSelectedIdentity(
                    selectedIdentity === IdentityType.OLD_CNI
                      ? undefined
                      : IdentityType.OLD_CNI
                  )
                }
              >
                <Grid item xs={2}>
                  {diplaySelectButton(IdentityType.OLD_CNI)}
                </Grid>
                <Grid item xs={4}>
                  <img
                    style={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      maxHeight: "20vh",
                    }}
                    alt={`${t("upload.identity-cni-title")} ${t(
                      "upload.identity-old-cni-subtitle"
                    )}`}
                    src={OldCniImage}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    mt={-1}
                    textAlign={"left"}
                    fontSize={"0.9em"}
                    fontWeight={"bold"}
                  >
                    {t("upload.identity-cni-title")}
                  </Typography>
                  <Typography
                    textAlign={"left"}
                    fontSize={"0.9em"}
                    fontStyle={"italic"}
                  >
                    {t("upload.identity-old-cni-subtitle")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item my={1}>
                <Divider />
              </Grid>
              <Grid
                item
                container
                spacing={1}
                alignItems={"center"}
                onClick={() =>
                  setSelectedIdentity(
                    selectedIdentity === IdentityType.NEW_CNI
                      ? undefined
                      : IdentityType.NEW_CNI
                  )
                }
              >
                <Grid item xs={2}>
                  {diplaySelectButton(IdentityType.NEW_CNI)}
                </Grid>
                <Grid item xs={4}>
                  <img
                    style={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      maxHeight: "20vh",
                    }}
                    alt={`${t("upload.identity-cni-title")} ${t(
                      "upload.identity-new-cni-subtitle"
                    )}`}
                    src={NewCniImage}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    mt={-1}
                    textAlign={"left"}
                    fontSize={"0.9em"}
                    fontWeight={"bold"}
                  >
                    {t("upload.identity-cni-title")}
                  </Typography>
                  <Typography
                    textAlign={"left"}
                    fontSize={"0.9em"}
                    fontStyle={"italic"}
                  >
                    {t("upload.identity-new-cni-subtitle")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item my={1}>
                <Divider />
              </Grid>
              <Grid
                item
                container
                spacing={1}
                alignItems={"center"}
                onClick={() =>
                  setSelectedIdentity(
                    selectedIdentity === IdentityType.PASSPORT
                      ? undefined
                      : IdentityType.PASSPORT
                  )
                }
              >
                <Grid item xs={2}>
                  {diplaySelectButton(IdentityType.PASSPORT)}
                </Grid>
                <Grid item xs={4}>
                  <img
                    style={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      maxHeight: "20vh",
                    }}
                    max-height={"10vh"}
                    alt={`${t("upload.identity-passport-title")}`}
                    src={PassportImage}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    mt={-1}
                    textAlign={"left"}
                    fontSize={"0.9em"}
                    fontWeight={"bold"}
                  >
                    {t("upload.identity-passport-title")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <div className="next-container">
                  <Button
                    variant="contained"
                    className="next-button action"
                    onClick={() => {
                      setIsValidatedEntityType(true);
                    }}
                    disabled={selectedIdentity === undefined}
                  >
                    {t("global.next-step")}
                  </Button>
                  <Button
                    variant="outlined"
                    className="next-button"
                    onClick={() => {
                      previousRoute && navigate(previousRoute);
                    }}
                  >
                    {t("global.cancel")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      {/* DISPLAY SECOND STEP : SELECTED IDENTITY TYPE OK */}
      {!displayEndWorkflowQrCode &&
        clientDocumentType &&
        clientDocumentType.client &&
        (!isDesktop || displayLikeMobile) &&
        isValidatedEntityType && (
          <>
            <Grid
              item
              container
              flexDirection={"column"}
              justifyContent={"center"}
              px={2}
              sx={{
                pt: 1,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* DISPLAY MESSAGE IF NOT YET CAMERA AUTHORIZATION  */}
              {!isOkCamera && (
                <>
                  <Grid item mb={10}>
                    <Typography fontWeight={"bold"} textAlign={"left"}>
                      {t("upload.identity-wait-cam1")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography textAlign={"left"}>
                      {t("upload.identity-wait-cam2")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      className="next-button"
                      onClick={() => {
                        setDisplayCamera(false);
                        setIsValidatedEntityType(false);
                      }}
                    >
                      {t("global.cancel")}
                    </Button>
                  </Grid>
                </>
              )}
              {/* DISPLAY CAMERA ICONS AND EXPLANATION DEPENDING STEP (RECTO / VERSO / IDENTITY TYPE) */}
              {isOkCamera &&
                !displayCamera &&
                ((!isVerso && !screenshotRecto) ||
                  (isVerso && !screenshotVerso)) &&
                displayIntroCamera()}
              {/* INIT CAMERA IN BACKGROUND TO LET BROWSER ASK FOR AUTHORIZATION  */}
              {!displayCamera &&
                ((!isVerso && !screenshotRecto) ||
                  (isVerso && !screenshotVerso)) && (
                  <div style={{ display: "none" }}>
                    <Webcam
                      style={{
                        width: "100%",
                        maxHeight: "60vh",
                      }}
                      videoConstraints={{
                        facingMode: "environment",
                      }}
                      audio={false}
                      screenshotFormat="image/jpeg"
                      onUserMedia={(props) => {
                        setIsOkCamera(true);
                        setWindowSize(getWindowSize());
                        const currentIdTimeout = setTimeout(
                          () => setDisplayCamera(true),
                          6000
                        );
                        setIdTimeout(currentIdTimeout);
                      }}
                      onUserMediaError={(props) => {
                        setIsOkCamera(false);
                        enqueueSnackbar(t("errors.camera_not_authorized"), {
                          variant: "error",
                        });
                      }}
                    ></Webcam>
                  </div>
                )}
              {/* DISPLAY CONFIRMATION OF TAKEN PHOTO : VALIDATION OR BUTTON TO TAKE ANOTHER PHOTO */}
              {!displayCamera &&
                ((!isVerso && screenshotRecto) ||
                  (isVerso && screenshotVerso)) && (
                  <>
                    <Grid item>
                      <div className="title">
                        {clientDocumentType.client.firstName}{" "}
                        {clientDocumentType.client.lastName}
                      </div>
                    </Grid>

                    <Grid
                      item
                      flexGrow={1}
                      container
                      direction={"column"}
                      justifyContent={"space-evenly"}
                    >
                      <Grid item>
                        <Typography fontWeight={"bold"} textAlign={"center"}>
                          {t("upload.identity-title-confirm-photo")}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ maxWidth: "600px", maxHeight: "60vh" }}>
                        <img
                          src={isVerso ? screenshotVerso : screenshotRecto}
                          width={"100%"}
                          style={{ maxHeight: "60vh", objectFit: "contain" }}
                          alt=""
                        />
                      </Grid>
                      <Grid item>
                        <Typography fontWeight={"bold"} textAlign={"center"}>
                          {t("upload.identity-title-confirm-photo-text")}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        className="next-button action"
                        onClick={() => {
                          if (selectedIdentity === IdentityType.PASSPORT) {
                            sendFiles();
                            return;
                          }
                          if (isVerso) {
                            sendFiles();
                          } else {
                            setIsVerso(true);
                          }
                        }}
                      >
                        {t("upload.transmit-photo")}
                      </Button>
                    </Grid>
                    <Grid item mt={1}>
                      <Button
                        variant="outlined"
                        className="next-button"
                        onClick={() => {
                          if (isVerso) {
                            setScreenshotVerso(undefined);
                          } else {
                            setScreenshotRecto(undefined);
                          }
                          setDisplayCamera(true);
                        }}
                      >
                        {t("upload.take-another-photo")}
                      </Button>
                    </Grid>
                  </>
                )}
            </Grid>
            {/* DISPLAY CAMERA MODAL : TAKE PHOTO (RECTO / VERSO) */}
            <Dialog
              open={displayCamera}
              onClose={() => {
                console.log("close dialog");
              }}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
              scroll="body"
              fullScreen={true}
            >
              <DialogContent>
                <Grid
                  container
                  direction={
                    windowSize.innerWidth > windowSize.innerHeight &&
                    windowSize.innerHeight < 600
                      ? "row"
                      : "column"
                  }
                  justifyContent={"space-evenly"}
                  alignItems={"stretch"}
                  sx={{ height: "90vh" }}
                >
                  <Grid item>
                    <Box pt={3} alignItems={"start"} textAlign={"left"}>
                      {displayBackButton(() => {
                        setDisplayCamera(false);
                        setIsValidatedEntityType(false);
                      }, "#000000")}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    display={"flex"}
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Webcam
                        ref={webcamRef}
                        style={{
                          margin: "auto",
                          display: "block",
                          maxHeight: "60vh",
                          maxWidth: "100%",
                          borderRadius: "10px",
                          border: "2px solid",
                          borderColor: theme.palette.primary.main,
                        }}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                          aspectRatio:
                            selectedIdentity === IdentityType.PASSPORT
                              ? isDesktop ||
                                windowSize.innerWidth > windowSize.innerHeight
                                ? 12.5 / 8.8
                                : 8.8 / 12.5
                              : isDesktop ||
                                windowSize.innerWidth > windowSize.innerHeight
                              ? 10.5 / 7.4
                              : 7.4 / 10.5,
                          facingMode: "environment",
                        }}
                        screenshotQuality={1}
                        minScreenshotHeight={1080}
                        audio={false}
                        onUserMedia={(props) => {
                          setIsOkCamera(true);
                        }}
                        onUserMediaError={(props) => {
                          setIsOkCamera(false);
                          enqueueSnackbar(t("errors.camera_not_authorized"), {
                            variant: "error",
                          });
                        }}
                      ></Webcam>
                    </Grid>
                    {isOkCamera && (
                      <Grid item mt={1} textAlign={"center"}>
                        <Button variant="contained">
                          {t("upload.identity-take-photo")}
                        </Button>
                      </Grid>
                    )}
                  </Grid>

                  {isOkCamera && (
                    <Grid
                      item
                      display={"flex"}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Grid item textAlign={"center"}>
                        <Fab
                          sx={{ mx: "auto" }}
                          size="large"
                          aria-label="add"
                          onClick={capture}
                        >
                          &nbsp;
                        </Fab>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
            </Dialog>
          </>
        )}
    </Grid>
  );
}
