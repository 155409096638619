import { createSlice } from "@reduxjs/toolkit";
import { Alert, TypeAlert } from "../types/alert.type";

const initialState: { alerts: Alert[] } = { alerts: [] };

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addError: (state, action) => {
      state.alerts.push({
        type: TypeAlert.ERROR,
        value: action.payload,
      } as Alert);
    },
    addWarning: (state, action) => {
      state.alerts.push({
        type: TypeAlert.WARNING,
        value: action.payload,
      } as Alert);
    },
    addSuccess: (state, action) => {
      state.alerts.push({
        type: TypeAlert.SUCCESS,
        value: action.payload,
      } as Alert);
    },
    addInfo: (state, action) => {
      state.alerts.push({
        type: TypeAlert.INFO,
        value: action.payload,
      } as Alert);
    },
    clearAlert: (state) => {
      state.alerts = [];
    },
  },
});

export const selectAlerts = (state: { alert: { alerts: Alert[] } }) =>
  state.alert.alerts;

export const { addError, addWarning, addSuccess, addInfo, clearAlert } =
  alertSlice.actions;

export default alertSlice.reducer;
