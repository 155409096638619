import React, { useEffect, useState } from "react";
import {
  fetchDocument,
  fetchDocumentList,
  fetchMyDocumentList,
  getCode,
} from "../../services/bi.api";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { Document } from "../../types/documentList";
import { Box, Button } from "@mui/material";
import { LocalStorageKey } from "../../types/localStorageEnum";
import Loader from "../../components/common/loading";
import { routeType } from "../../routes";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

function DocumentList() {
  const { t } = useTranslation();

  const [documents, setDocuments] = useState<Document[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [loading, setLoading] = useState(false);
  let params = useParams();
  const [userId, setUserId] = useState<any>(params.userId);
  const { state } = useLocation();
  const { userName } = state || {};
  const navigate = useNavigate();

  function getColumns(userId: string) {
    return [
      {
        field: "date",
        headerName: t("documents.date") as string,
        flex: 0.3,
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
          return format(new Date(params.value), "dd/MM/YYYY");
        },
      },
      {
        field: "subscription",
        headerName: t("documents.provider") as string,
        valueGetter: (params: GridValueGetterParams) => {
          return params.value.connection.connector.name;
        },
        flex: 1,
        sortable: true,
      },
      { field: "name", headerName: t("documents.name") as string, flex: 1 },

      { field: "type", headerName: t("documents.type") as string, flex: 0.3 },
      {
        field: "url",
        headerName: "",
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<string>) => {
          if (params.value) {
            return (
              <Button
                variant="contained"
                onClick={async () => {
                  const code = await getCode(userId);
                  window.open(
                    URL.createObjectURL(
                      await fetchDocument(code.code, params.value + "")
                    )
                  );
                }}
              >
                {t("documents.show")}
              </Button>
            );
          }
        },
      },
    ];
  }

  const feedDocumentList = async () => {
    setLoading(true);
    let userIdFromLocal = null;
    if (!userId) {
      userIdFromLocal = localStorage.getItem(LocalStorageKey.USER_ID);
      setUserId(userIdFromLocal);
    }
    if (!userId && !userIdFromLocal) {
      enqueueSnackbar(t("errors.no-user-for-documents"), { variant: "error" });
      setLoading(false);
      return;
    }
    try {
      let documentList = null;
      if (userId) {
        documentList = await fetchDocumentList(userId);
      } else {
        documentList = await fetchMyDocumentList();
      }
      setDocuments(documentList.documents);
      const goodUserId = userId || userIdFromLocal;
      setColumns(getColumns(goodUserId));
    } catch (err) {
      enqueueSnackbar(t("errors.fail-fetch-documents"), { variant: "error" });
      navigate(routeType.home);
    }

    setLoading(false);
  };

  useEffect(() => {
    feedDocumentList();
    if (userId) {
      setColumns(getColumns(userId));
    }
  }, []);

  return (
    <div>
      {userName ? (
        <h2>{t("documents.title-user", { userName })}</h2>
      ) : (
        <h2>{t("documents.title-client")} </h2>
      )}
      {loading ? (
        <Loader />
      ) : (
        <Box height={"400px"} width={"100%"}>
          <DataGrid
            rows={documents}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
      )}
    </div>
  );
}

export default DocumentList;
