export enum LocalStorageKey {
  KEY_TOKEN_LOCALSTORAGE = "access_token",
  USER_ID = "userId",
  PROFILE = "profile",
  CGU_USER_ID = "cguUserId",
  BI_CONNECTED = "biConnected",
  CAPTCHA_TOKEN = "captcha_token",
  PRIMARY = "primary",
  CLIENT_PROCESS_PRESENTATION = "client-process-presentation",
  SECONDARY = "secondary",
}
