import { LocalStorageKey } from "../types/localStorageEnum";
import { getHeaderWithAuth } from "./utils";

export async function getUploadToken(cdtId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/upload/token/${cdtId}`,
    {
      headers: getHeaderWithAuth(),
    }
  );
  const data: { token: string } = await res.json();
  if (res.status >= 400) {
    throw new Error("Can not get upload token of user");
  }
  return data;
}

export async function sendEventOpenMobilePage(token: string) {
  const data = new FormData();
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/upload/open-mobile-page`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + token,
      }),
      body: data,
    }
  );
  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.message);
  }
  return res.json();
}

export async function sendFile(token: string, file: string) {
  const data = new FormData();
  data.append("files", file);
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/upload/transit`,
    {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + token,
      }),
      body: data,
    }
  );
  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.message);
  }
  return res.json();
}

export async function uploadFiles(
  cdtId: string,
  files: any[],
  type?: string | null
) {
  let index = 1;
  for (const file of files) {
    const data = new FormData();

    data.append("files", file);
    data.append("fileIndex", index + "");
    data.append("totalFileNumber", files.length + "");
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/upload/${cdtId}/${
        type ? "?type=" + type : ""
      }`,
      {
        method: "POST",
        headers: new Headers({
          Authorization:
            "Bearer " +
            localStorage.getItem(LocalStorageKey.KEY_TOKEN_LOCALSTORAGE),
        }),
        body: data,
      }
    );
    index++;
  }
}

export function getDocumentEventEmitter(cdtId: string) {
  return new EventSource(
    `${process.env.REACT_APP_BACKEND_URL}/upload/document/${cdtId}`
  );
}
export function getDocumentMobilePageEventEmitter(cdtId: string) {
  return new EventSource(
    `${process.env.REACT_APP_BACKEND_URL}/upload/document-mobile-page/${cdtId}`
  );
}

export async function getSignedUrl(documentId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/document/${documentId}/url`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );

  return res.text();
}

export async function sendProcessBiDocuments(selecteDocument: {
  [op: string]: number[];
}) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/upload/client-document-type/bi/documents`,
    {
      method: "POST",
      headers: getHeaderWithAuth(),
      body: JSON.stringify(selecteDocument),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cdt children");
  }
}

export async function sendGenerateCase(caseId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/upload/${caseId}/generate`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not generate case");
  }
  const link = await res.json();
  return await downloadLink(link.reportLink);
}

async function downloadLink(link: string) {
  const res = await fetch(link, {
    method: "GET",
    headers: {
      "content-type": "application/pdf",
    },
  });
  if (res.status >= 400) {
    throw new Error("can not generate case");
  }
  return await res.blob();
}

export async function getDocumentInfo(documentId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/document/info/${documentId}`
  );
  if (res.status >= 400) {
    throw new Error("can not get  document info");
  }

  return res.json();
}
export async function checkDocumentHash(documentId: string, hash: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/document/info/${documentId}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hash: hash,
      }),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not check document hash");
  }

  return res.json();
}
