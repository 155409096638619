import { Box, CircularProgress } from "@mui/material";

export default function Loader(props: { title?: string }) {
  return (
    <Box margin={"auto"}>
      <CircularProgress variant="indeterminate" />
      <br />
      {props.title}
    </Box>
  );
}
