import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getClientDoucmentTypesChildrenWithDocument } from "../../services/case.api";
import {
  ClientDocumentType,
  ClientDocumentTypeStatus,
} from "../../types/caseForm.type";
import CaseDocumentValidationRow from "./caseDocumentValidationRow";

export default function DocumentGroupValidate(props: {
  cdt: ClientDocumentType;
  onSubmit: Function;
  onCancel: Function;
}) {
  const { t } = useTranslation();
  const [clientDocumentTypes, setClientDocumentTypes] =
    useState<ClientDocumentType[]>();
  useEffect(() => {
    (async () => {
      setClientDocumentTypes(
        await getClientDoucmentTypesChildrenWithDocument(props.cdt.id)
      );
    })();
  }, [props.cdt]);
  return (
    <div>
      <Box paddingBottom={"10%"}>
        <h3>{t("doc-type." + props.cdt.documentType?.name)}</h3>
        <Grid container spacing={2}>
          {clientDocumentTypes?.map((cdt) => {
            return (
              <Grid item xs={12} key={`group-validate-${cdt.id}`}>
                <CaseDocumentValidationRow
                  cdt={cdt}
                  documentTypesViewType={{ [cdt.id]: "document" }}
                  setCdtStatus={(
                    status: ClientDocumentTypeStatus,
                    cdtId: string,
                    clientId: string
                  ) => {
                    const tmpCdts = [...clientDocumentTypes];
                    tmpCdts.forEach((x) => {
                      if (x.id === cdtId) {
                        cdt.status = status;
                      }
                    });

                    setClientDocumentTypes(tmpCdts);
                  }}
                  setModalComponent={() => {}}
                  setOpenModal={() => {}}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Grid
        container
        spacing={0}
        direction={"row-reverse"}
        bottom={0}
        position={"absolute"}
        right={0}
        m={3}
      >
        <Grid item xs={4} lg={2}>
          <Button
            onClick={() => {
              props.onSubmit(clientDocumentTypes, props.cdt);
            }}
            variant="contained"
          >
            {t("global.confirm")}
          </Button>
        </Grid>
        <Grid item xs={4} lg={2}>
          <Button
            onClick={() => {
              props.onCancel();
            }}
            variant="outlined"
          >
            {t("global.cancel")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
