import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
  getCaseWithDocTypeForClient,
  getClientDocumentType,
  getDocumentGroupesForClient,
  updateCaseAdvencement,
} from "../../services/case.api";
import { getClient } from "../../services/user.api";
import {
  AdvencementStatus,
  Borrower,
  ClientDocumentType,
} from "../../types/caseForm.type";
import "../../styles/client/client.scss";
import { DocumentGroupForClient } from "../../types/client/groupeForClient.type";
import { ReactComponent as ToProcessIcon } from "../../resources/icons/toProcess.svg";
import { ReactComponent as Processed } from "../../resources/icons/processed.svg";

import { routeType } from "../../routes";
import Loader from "../../components/common/loading";
import { useDispatch } from "react-redux";
import { customBackContext, clearBackContext } from "../../slice/back.slice";
import { LocalStorageKey } from "../../types/localStorageEnum";
import { getOrderedListOfDocumentTypes } from "../../services/utils-cdt";
import { ClientDocumentGroup } from "../../types/client/clientDocumentGroup";
import { SessionStorageKey } from "../../types/sessionStorageEnum";

export default function Client() {
  const params = useParams();
  const [client, setClient] = useState<Borrower>();
  const [coBorrower, setCoBorrower] = useState<Borrower>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFirstUse = sessionStorage.getItem(SessionStorageKey.FIRST_USE);

  const [clientDocumentGroups, setClientDocumentGroups] = useState<
    ClientDocumentGroup[]
  >([]);
  const [coBorrowerDocumentGroups, setCoBorrowerDocumentGroups] = useState<
    ClientDocumentGroup[]
  >([]);
  const theme = useTheme();

  const [groupsIsCompleted, setGroupIsCompleted] = useState(false);
  const [isPresentationOk, setIsPresentationOk] = useState(false);

  useEffect(() => {
    dispatch(
      customBackContext({
        disabled: true,
        className: "hide-back-button",
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (params.id) {
        setClient(await getClient(params.id));
        setIsPresentationOk(
          localStorage.getItem(LocalStorageKey.CLIENT_PROCESS_PRESENTATION) ===
            params.id
        );
        try {
          const cdt = await getCaseWithDocTypeForClient(params.id, false);
          const groups = getOrderedListOfDocumentTypes(
            cdt.clientDocumentTypes.filter(
              (cdt) => cdt.clientId === params.id || cdt.clientId === null
            )
          );
          setClientDocumentGroups(groups);
          if (
            cdt.borrowers.length > 1 &&
            cdt.borrowers.find((b) => b.id === params.id)?.isMaster
          ) {
            const coBorrowerTmp = cdt.borrowers.find((b) => b.id !== params.id);
            setCoBorrower(coBorrowerTmp);
            if (coBorrowerTmp) {
              const coBorrowerGroups = getOrderedListOfDocumentTypes(
                cdt.clientDocumentTypes.filter(
                  (cdt) => cdt.clientId !== params.id && cdt.clientId !== null
                )
              );
              setCoBorrowerDocumentGroups(coBorrowerGroups);
            }
          }
          setGroupIsCompleted(
            groups.every((g) => g.subGroups.every((sg) => sg.completed))
          );
        } catch (error) {
          console.error("steve", error);
        }
      }
    })();
  }, [params.id]);

  const onSubmitDocs = async () => {
    if (client && client.case) {
      await updateCaseAdvencement(client?.case.id, {
        advencementStatus: AdvencementStatus.UNDER_REVIEW,
      });
      navigate(routeType.client + "/" + params.id + "/statut");
    }
  };
  const onSubmitPresentationOk = async () => {
    if (client && client.id) {
      localStorage.setItem(
        LocalStorageKey.CLIENT_PROCESS_PRESENTATION,
        client.id
      );
      setIsPresentationOk(true);
    }
  };

  const displayGroups = (cdgs: ClientDocumentGroup[], isCoBorrower = false) => {
    return (
      <>
        {cdgs.map((docGroup) => {
          return (
            <React.Fragment key={docGroup.code}>
              <Grid item>
                <div className="sub-group">{t(docGroup.label)}</div>
              </Grid>
              <Grid
                item
                container
                direction={"column"}
                columnSpacing={1}
                rowSpacing={3}
              >
                {docGroup.subGroups
                  //.filter((sg) => sg.cdts.length > 0)
                  .map((sg) => (
                    <Grid
                      item
                      container
                      key={sg.code}
                      sx={{
                        display: {
                          xs: sg.cdts.length === 0 ? "none" : "flex",
                          md: "flex",
                        },
                      }}
                      className={
                        (sg.cdts.length === 0 ? "disabled" : "") + " group-row"
                      }
                    >
                      <Grid className="group-row-label" item xs={10}>
                        {!isFirstUse && !sg.completed && (
                          <Box
                            position={"absolute"}
                            color={theme.palette.error.main}
                            marginLeft={-1.5}
                          >
                            *
                          </Box>
                        )}
                        {t(sg.label)}
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          sx={{ cursor: "pointer" }}
                          m="auto"
                          height={28}
                          onClick={() => {
                            navigate(
                              routeType.client +
                                (isCoBorrower
                                  ? `/${params.id}/co-emprunteur/groupe/${sg.code}`
                                  : `/${params.id}/groupe/${sg.code}`)
                            );
                          }}
                        >
                          {sg.cdts.length > 0 &&
                            (sg.completed ? (
                              <Processed
                                fill={theme.palette.primary.main}
                                stroke={theme.palette.primary.main}
                                color={"primary"}
                                width={"100%"}
                                height={20}
                              />
                            ) : (
                              <ToProcessIcon
                                fill={
                                  isFirstUse
                                    ? theme.palette.primary.main
                                    : theme.palette.error.main
                                }
                                stroke={
                                  isFirstUse
                                    ? theme.palette.primary.main
                                    : theme.palette.error.main
                                }
                                color={"primary"}
                                width={"100%"}
                                height={20}
                              />
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <Box
      sx={{
        pt: 0,
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      {!client && <Loader />}
      {client && !isPresentationOk && (
        <>
          <Grid container sx={{ textAlign: "left" }} spacing={2} rowSpacing={4}>
            <Grid item xs={12}>
              <h2>{t("case.list-intro-title")}</h2>
              <p>{t("case.list-intro-p1")}</p>
              <h3>{t("case.list-intro-sub1")}</h3>
              <p>{t("case.list-intro-p2")}</p>
              <p>{t("case.list-intro-p3")}</p>
              <p>{t("case.list-intro-p4")}</p>
              <h3>{t("case.list-intro-sub2")}</h3>
              <p>{t("case.list-intro-p5")}</p>
              <p>{t("case.list-intro-p6")}</p>
              <p>{t("case.list-intro-p7")}</p>
            </Grid>
          </Grid>
          <div className="next-container">
            <Button
              variant="contained"
              className="next-button"
              onClick={onSubmitPresentationOk}
            >
              {t("global.next-step")}
            </Button>
          </div>
        </>
      )}
      {client && isPresentationOk && (
        <Grid
          container
          sx={{
            pt: 1,
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <Grid item textAlign={"left"} mb={3}>
            {(isFirstUse || groupsIsCompleted) && <>{t("client.text")}</>}
            {!isFirstUse && !groupsIsCompleted && (
              <>
                <h4
                  style={{
                    fontSize: "1.1em",
                    marginTop: 0,
                    marginBottom: "5px",
                  }}
                >
                  {t("client.text_incomplete_title")}
                </h4>
                {t("client.text_incomplete")}
                <br />
                {t("client.text_incomplete_more")}
              </>
            )}
          </Grid>
          <Grid
            item
            container
            direction={"row"}
            alignItems={"flex-start"}
            columnSpacing={2}
            rowSpacing={5}
            spacing={0}
          >
            <Grid
              xs={12}
              md={6}
              item
              container
              sx={{ textAlign: "left" }}
              spacing={0}
              direction={"column"}
              rowSpacing={4}
              mb={1.5}
            >
              <Grid item xs={12}>
                <div className="title">
                  {client.firstName} {client.lastName}
                </div>
              </Grid>
              {clientDocumentGroups.length > 0 &&
                displayGroups(clientDocumentGroups)}
            </Grid>
            {coBorrower && coBorrowerDocumentGroups.length > 0 && (
              <Grid
                xs={12}
                md={6}
                item
                container
                sx={{ textAlign: "left" }}
                spacing={0}
                direction={"column"}
                rowSpacing={4}
                mb={1.5}
              >
                <Grid item xs={12}>
                  <div className="title">
                    {coBorrower.firstName} {coBorrower.lastName}
                  </div>
                </Grid>
                {coBorrowerDocumentGroups.length > 0 &&
                  displayGroups(coBorrowerDocumentGroups, true)}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {groupsIsCompleted && client && client.case ? (
        <Grid item>
          <div className="next-container">
            <Button
              variant="contained"
              className="next-button"
              onClick={onSubmitDocs}
              disabled={
                client?.case?.advencementStatus ===
                AdvencementStatus.UNDER_REVIEW
              }
            >
              {client?.case?.advencementStatus ===
              AdvencementStatus.UNDER_REVIEW
                ? t("global.next-step-under-review")
                : t("global.next-step")}
            </Button>
          </div>
        </Grid>
      ) : (
        ""
      )}
    </Box>
  );
}
