import {
  AdvencementStatus,
  Case,
  CaseStatus,
  ClientDocumentType,
} from "../types/caseForm.type";
import { CreateCaseDto, UpdateCaseDto } from "../types/caseService.type";
import { DocumentGroupForClient } from "../types/client/groupeForClient.type";
import { getHeaderWithAuth } from "./utils";

export async function createCase(caseDto: CreateCaseDto) {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/case/`, {
    method: "POST",
    body: JSON.stringify(caseDto),
    headers: getHeaderWithAuth(),
  });
  if (res.status >= 400) {
    const error = await res.json();
    throw new Error(error.message);
  }

  return res.json();
}

export async function getCases() {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/case/`, {
    method: "GET",
    headers: getHeaderWithAuth(),
  });
  if (res.status >= 400) {
    throw new Error("can not get cases");
  }

  return res.json();
}

export async function getCase(caseId: string, withBorrowers = false) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseId}/${
      withBorrowers ? "with-clients" : ""
    }`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cases");
  }

  return res.json();
}

export async function getCaseForValidation(caseId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseId}/validation`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cases");
  }

  return res.json();
}

export async function getDocumentTypes() {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/document/type`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cases");
  }

  return res.json();
}

export async function getClients(caseId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseId}/clients`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cases");
  }

  return res.json();
}

export async function updateCase(caseDto: UpdateCaseDto) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseDto.id}`,
    {
      method: "PATCH",
      body: JSON.stringify(caseDto),
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not update case");
  }
  return res.json();
}

export async function updateCaseAdvencement(
  caseId: string,
  advencementCase: { advencementStatus: AdvencementStatus }
) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseId}/advencement`,
    {
      method: "PATCH",
      body: JSON.stringify(advencementCase),
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not update case");
  }
}

export async function validateCase(
  caseId: string,
  validateCaseDto: { caseStatus: CaseStatus; master?: string }
) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseId}`,
    {
      method: "PATCH",
      body: JSON.stringify(validateCaseDto),
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not update case");
  }
}

export async function getDocumentGroupes() {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/document/type/group`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cases");
  }

  return res.json();
}

export async function getDocumentGroupesForClient(clientId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/client/${clientId}/document/group`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cases");
  }

  return (await res.json()) as DocumentGroupForClient[];
}

export const inviteCoBorrower = async (clientId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/client/${clientId}/invite`,
    {
      method: "POST",
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    return null;
  }
};

export async function getCaseWithDocTypeForGroupAndClient(
  clientId: string,
  groupId: string,
  onlyChildren = false
) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/client/${clientId}/group/${groupId}/document-type?onlyChildren=${onlyChildren}`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get case");
  }

  return (await res.json()) as Case;
}
export async function getCaseWithDocTypeForClient(
  clientId: string,
  onlyChildren = false
) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/client/${clientId}/all-document-types?onlyChildren=${onlyChildren}`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get case");
  }

  return (await res.json()) as Case;
}

export async function getClientDocumentType(cdtId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/client/client-document-type/${cdtId}`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get case");
  }

  return (await res.json()) as ClientDocumentType;
}

export async function getClientDoucmentTypesChildrenWithDocument(
  cdtId: string
) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/client/client-document-type/${cdtId}/document`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cdt children");
  }

  return (await res.json()) as ClientDocumentType[];
}

export async function getBIClientDoucmentTypesChildren(cdtId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/client/client-document-type/${cdtId}/bi`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cdt children");
  }

  return (await res.json()) as ClientDocumentType[];
}
export async function deleteCase(caseId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseId}`,
    {
      method: "DELETE",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not remove case");
  }
}

export async function updateCdtStatus(
  clientDocumentTypes: ClientDocumentType[]
) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/client-document-type/`,
    {
      method: "PATCH",
      headers: getHeaderWithAuth(),
      body: JSON.stringify(clientDocumentTypes),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not get cdt children");
  }
}

export async function sendReflate(caseId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseId}/reflate`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not reflate case");
  }
}

export async function regenerateClientDocumentTypeFromCase(caseId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/case/${caseId}/client-document-type/regenerate`,
    {
      method: "PATCH",
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not regenerate cdt for the  case");
  }
}
