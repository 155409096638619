import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { groupBy } from "lodash";
import { Borrower } from "../../types/caseForm.type";
import { DocumentType } from "../../types/documentList";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DocumentTypeComponent from "./DocumentTypeComponent";
import { ReactComponent as CommonFilesIcon } from "../../resources/icons/restitution/common-files.svg";

export default function AddDocumentTypeDialog(props: {
  client?: Borrower;
  docTypes: DocumentType[];
  selectDocType: Function;
  close: Function;
}) {
  const style = {
    buttonDialog: {
      m: 2,
      right: 1,
      bottom: 2,
    },
  };

  const { t } = useTranslation();

  const [selected, setSelected] = useState<DocumentType[]>([]);

  const addDocTypeToSelection = (docType: DocumentType) => {
    if (selected.find((x) => x.id === docType.id)) {
      setSelected([...selected.filter((x) => x.id !== docType.id)]);
    } else {
      setSelected([...selected, docType]);
    }
  };

  const isSelected = (docType: DocumentType) => {
    return !!selected.find((x) => x.id === docType.id);
  };

  const groupedDocType = groupBy(props.docTypes, (dt) => dt.group.id);
  return (
    <>
      <DialogTitle>
        <h2>
          {props.client?.firstName || <CommonFilesIcon height={"30px"} />}
        </h2>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {Object.keys(groupedDocType).map((key) => (
            <Grid xs={4} item container spacing={2} direction={"column"} mt={2}>
              <Grid item>
                <h3>{groupedDocType[key][0].group.name}</h3>
              </Grid>
              {groupedDocType[key].map((dt) => (
                <Grid item>
                  <DocumentTypeComponent
                    docType={dt}
                    clientId={props?.client?.id}
                    key={dt.id}
                    handleClick={addDocTypeToSelection}
                    mode="selectable"
                    state={isSelected(dt) ? "selected" : "neutral"}
                    textAlign={"left"}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <br />
      <DialogActions>
        <Button
          variant="outlined"
          sx={style.buttonDialog}
          onClick={() => {
            props.close();
          }}
        >
          {t("global.cancel")}
        </Button>
        <Button
          sx={style.buttonDialog}
          variant="contained"
          onClick={() => {
            props.selectDocType(selected);
          }}
        >
          {t("global.save")}
        </Button>
      </DialogActions>
    </>
  );
}
