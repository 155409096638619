import { LocalStorageKey } from "../types/localStorageEnum";

export function getHeaderWithAuth() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization:
      "Bearer " + localStorage.getItem(LocalStorageKey.KEY_TOKEN_LOCALSTORAGE),
  };
}

export function dataURIToFile(dataURI: string, filename: string) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new File([ia], filename, { type: mimeString });
}
