import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Roles } from "../../types/role.enum";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routeType } from "../../routes";
import "../../styles/common/navBar.scss";
import { useEffect, useState } from "react";
import { LocalStorageKey } from "../../types/localStorageEnum";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, userLogout, userUpdate } from "../../slice/login.slice";
import { getMe } from "../../services/user.api";
import { useTranslation } from "react-i18next";
import { reloadCategories } from "../../services/categories.api";
import { enqueueSnackbar } from "notistack";

import { ReactComponent as LogoutIcon } from "../../resources/icons/logout.svg";
import { ReactComponent as NotificationIcon } from "../../resources/icons/notification.svg";

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const jwtUser = useSelector(selectUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const settings = [
    //{label:"Compte"}, This menu is useless for now (...)
    {
      label: t("header.logout"),
      action: () => {
        localStorage.removeItem(LocalStorageKey.KEY_TOKEN_LOCALSTORAGE);
        localStorage.removeItem(LocalStorageKey.USER_ID);
        localStorage.removeItem(LocalStorageKey.CGU_USER_ID);
        localStorage.removeItem(LocalStorageKey.PROFILE);
        localStorage.removeItem(LocalStorageKey.PRIMARY);
        localStorage.removeItem(LocalStorageKey.SECONDARY);
        dispatch(userLogout());
        navigate(routeType.home);
      },
    },
  ];

  const getPages = (userId: string) => [
    {
      label: t("header.create-user"),
      url: routeType.userCreate,
      rights: [Roles.ADMIN],
      bold: [routeType.userCreate],
    },
    {
      label: t("header.client-informations"),
      url: routeType.client + "/" + userId + "/informations",
      rights: [Roles.CLIENT],
      bold: [],
    },
    {
      label: t("header.client-doctypes"),
      url: routeType.client + "/" + userId,
      rights: [Roles.CLIENT],
      bold: [],
    },

    {
      label: t("header.case-list"),
      url: routeType.caseList,
      rights: [Roles.ADMIN],
      bold: [routeType.caseList],
    },
    {
      label: t("header.user-list"),
      url: routeType.userList,
      rights: [Roles.ADMIN],
      bold: [
        routeType.userList,
        routeType.clientList,
        routeType.account,
        routeType.transaction,
        routeType.document,
      ],
    },
    {
      label: t("header.case-list"),
      url: routeType.caseList,
      rights: [Roles.USER],
      bold: [
        routeType.clientList,
        routeType.caseList,
        routeType.account,
        routeType.transaction,
        routeType.document,
      ],
    },
    {
      label: t("header.create-case"),
      url: routeType.caseCreate,
      rights: [Roles.USER],
      bold: [routeType.caseCreate],
    },
  ];

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const [pages, setPages] = useState<
    {
      label: string;
      url: string;
      rights: Roles[];
      bold: routeType[];
    }[]
  >([]);

  const fetchMe = async () => {
    if (!jwtUser.id) {
      const userPayload = await getMe();
      if (userPayload) {
        dispatch(userUpdate(userPayload));
      }
    }
  };

  useEffect(() => {
    // TODO: dispatch userlogin en fonction du localstorage
    fetchMe();
  }, [jwtUser]);

  useEffect(() => {
    if (jwtUser.id) {
      const completePages = getPages(jwtUser.id);
      const filteredPages = completePages.filter(
        (page) => page.rights.length === 0 || page.rights.includes(jwtUser.role)
      );
      setPages(filteredPages);
    } else {
      setPages([]);
    }
  }, [jwtUser]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar
      position="static"
      className="header"
      sx={{ boxShadow: 0, backgroundColor: "white" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{ flexGrow: 2, display: { xs: "flex" } }}
            alignItems={"center"}
          >
            <Box
              onClick={() => {
                jwtUser.role === Roles.CLIENT
                  ? navigate(routeType.client + "/" + jwtUser.id)
                  : navigate(routeType.home);
              }}
              mt={"auto"}
              display={"contents"}
            >
              <img height={"30px"} alt={"Logo"} src="/Lendy.png" />
            </Box>
            <IconButton
              className="icon"
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", marginLeft: "25px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page, index) => (
                <Link
                  key={"navbar-pages-mobile" + index}
                  className="link-navBar"
                  to={page.url}
                >
                  <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                    <Typography
                      sx={{
                        color: "black",
                      }}
                      textAlign="center"
                    >
                      {page.label}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
            <div className="username-div">
              {jwtUser.firstName && jwtUser.lastName ? (
                <p className="username">
                  {jwtUser.firstName + " " + jwtUser.lastName}
                </p>
              ) : (
                <p className="username">{jwtUser.email}</p>
              )}
            </div>
          </Box>

          {jwtUser.role === Roles.USER &&
          location.pathname === routeType.caseList ? (
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(routeType.case);
                }}
              >
                {t("case.new")}
              </Button>
            </Box>
          ) : (
            <></>
          )}

          {jwtUser.id && (
            <Box sx={{ flexGrow: 0, display: "flex" }}>
              {jwtUser.isAdmin && (
                <Button
                  onClick={async () => {
                    try {
                      await reloadCategories();
                    } catch (Error) {
                      enqueueSnackbar(t("errors.reload-categories"), {
                        variant: "error",
                      });
                    }
                  }}
                  variant="contained"
                >
                  {t("header.reload-categories")}
                </Button>
              )}
              <Tooltip title={t("header.logout")}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: -1 }}>
                  <LogoutIcon style={{ fontSize: "25px", color: "black" }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.label}
                    onClick={() => setting.action && setting.action()}
                  >
                    <Typography textAlign="center">{setting.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
