import { AccountsList } from "../types/accountsList.type";
import { Transaction } from "../types/transaction.type";
import { WebViewData } from "../types/webviewData.type";
import { DocumentList } from "../types/documentList";
import { getHeaderWithAuth } from "./utils";
import {
  BudgetInsightConnection,
  BudgetInsightSubscription,
  DocumentDisplayStatus,
} from "../types/budgetInsight.type";
import {
  BudgetInsightConnector,
  ClientDocumentTypeStatus,
} from "../types/caseForm.type";

export const fetchHelloWorld = async () => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}`);
};

export const fetchAccountsList = async (clientId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/accounts/${clientId}`,
    {
      headers: getHeaderWithAuth(),
    }
  );
  const data: AccountsList = await res.json();
  if (res.status >= 400) {
    throw new Error("Can not get accounts of user");
  }
  return data;
};

export const fetchMyAccountsList = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/accounts/me`,
    {
      headers: getHeaderWithAuth(),
    }
  );
  const data: AccountsList = await res.json();
  if (res.status >= 400) {
    throw new Error("Can not get your accounts");
  }
  return data;
};

export const fetchDocumentList = async (clientId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/documents/${clientId}`,
    {
      headers: getHeaderWithAuth(),
    }
  );
  const data: DocumentList = await res.json();
  if (res.status >= 400) {
    throw new Error("Can not get documents of user");
  }
  return data;
};
export const fetchMyDocumentList = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/documents/me`,
    {
      headers: getHeaderWithAuth(),
    }
  );
  const data: DocumentList = await res.json();
  if (res.status >= 400) {
    throw new Error("Can not get your documents");
  }
  return data;
};

export const fetchTransactionsList = async (
  userId: string,
  accountId: number
): Promise<Transaction[]> => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/transactions/${userId}/${accountId}`,
    {
      headers: getHeaderWithAuth(),
    }
  );
  const data: Transaction[] = await res.json();
  return data;
};

export async function fetchWebViewData(): Promise<WebViewData> {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/webview/info`,
    {
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status === 200) {
    const data = await res.json();
    return data;
  } else {
    throw new Error("Can not get webview info");
  }
}

export async function createAccessToken(
  cdtId: string,
  code?: string,
  connectionId?: string
) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/access-token`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state: cdtId,
        code,
        connection_id: connectionId,
      }),
    }
  );

  if (res.status >= 400) {
    throw new Error("Can not set access token to user");
  }
}

export async function fetchDocument(code: string, documentUrl: string) {
  const res = await fetch(documentUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/octet-stream",
      Authorization: "Bearer " + code,
    },
  });

  if (res.status >= 400) {
    throw new Error("can not get document for user");
  }
  return await res.blob();
}

export async function getCode(userId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/code/${userId}`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    throw new Error("can not get code for user");
  }
  return await res.json();
}
export async function getConnectors(): Promise<BudgetInsightConnector[]> {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/bi/connectors`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    throw new Error("can not get code for user");
  }
  return await res.json();
}

export async function getBiDocumentsForConnectionIds(
  caseId: string,
  connectionIds: string[]
) {
  const res = await fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/bi/case/${caseId}/documents?connectionIds=${connectionIds.join(",")}`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    throw new Error("can not get documents for case and connection ids ");
  }
  return await res.json();
}
/**
 *
 * NEW BI ROUTES
 *
 */
export async function getClientMineConnections(): Promise<
  BudgetInsightConnection[]
> {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/budget-insight-connection/mine`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    throw new Error("can not get connections for case and connection ids ");
  }
  return await res.json();
}

export async function getBudgetInsightConnectionsForConnectionIds(
  caseId: string,
  connectionIds: string[]
): Promise<BudgetInsightConnection[]> {
  const res = await fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/budget-insight-connection/case/${caseId}?connectionIds=${connectionIds.join(
      ","
    )}`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    throw new Error("can not get subscriptions for case and connection ids ");
  }
  return await res.json();
}

export async function getBudgetInsightDocumentSignedUrl(documentId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/budget-insight-document/${documentId}/url`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );

  return res.text();
}

export async function updateBudgetInsightDocumentsWithClientDocumentTypes(
  caseId: string,
  documentsToUpdate: {
    id: string;
    cdtId: string | null;
    displayStatus?: DocumentDisplayStatus;
  }[],
  cdtToUpdate: { id: string; status: ClientDocumentTypeStatus }[]
) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/budget-insight-document/case/${caseId}/client-document-type`,
    {
      method: "PATCH",
      headers: getHeaderWithAuth(),
      body: JSON.stringify({
        documentsToUpdate,
        cdtToUpdate,
      }),
    }
  );

  if (res.status >= 400) {
    console.error(res);
    throw new Error("Can not update data");
  }
  return res.json();
}
