import { ClientDocumentType } from "./caseForm.type";

export type BudgetInsightAccessToken = {
  id: string;
  caseId: string;
};

export enum ConnectionProcessStatus {
  TO_PROCESS = "TO_PROCESS",
  TO_PROCESS_UPDATE = "TO_PROCESS_UPDATE",
  PROCESSING = "PROCESSING",
  PROCESSED_OK = "PROCESSED_OK",
  PROCESSED_KO = "PROCESSED_KO",
}

export type BudgetInsightConnection = {
  id: string;
  budgetInsightTokenId: string;
  connectionId: number;
  connectorName: string;
  connectorColor: string;
  processStatus: ConnectionProcessStatus;
  subscriptions?: BudgetInsightSubscription[];
};

export enum SubscriptionDisplayStatus {
  SHOWN = "SHOWN",
  HIDDEN = "HIDDEN",
}

export type BudgetInsightSubscription = {
  id: string;
  budgetInsightConnectionId: string;
  budgetInsightConnection?: BudgetInsightConnection;
  subscriptionId: number;
  label: string;
  subscriber: string;
  number: string;
  lastUpdate: Date;
  displayStatus: SubscriptionDisplayStatus;
  documents?: BudgetInsightDocument[];
};

export enum DocumentProcessStatus {
  TO_PROCESS = "TO_PROCESS",
  PROCESSING = "PROCESSING",
  PROCESSED_OK = "PROCESSED_OK",
  PROCESSED_KO = "PROCESSED_KO",
  IGNORED = "IGNORED",
}
export enum DocumentDisplayStatus {
  SHOWN = "SHOWN",
  HIDDEN = "HIDDEN",
}

export type BudgetInsightDocument = {
  id: string;
  documentId: number;
  budgetInsightSubscription: BudgetInsightSubscription;
  budgetInsightSubscriptionId: string;
  name: string;
  typeId: number;
  typeLabel: string;
  number: string;
  effectiveDate: Date;
  originalFileStorageKey: string;
  originalFileHash?: string;
  convertedFileStorageKey: string;
  convertedFileHash?: string;
  processStatus: DocumentProcessStatus;
  displayStatus: DocumentDisplayStatus;
  clientDocumentTypeId?: string;
  clientDocumentType?: ClientDocumentType;
};
