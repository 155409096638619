import { Box, lighten, Typography, useTheme } from "@mui/material";
import { PropsWithChildren, useState } from "react";

export type MultiStageDisplayStateValue = "neutral" | "selected" | "unselected";
export type TextAlignment = "left" | "center" | "right";

interface MultiStateDisplayCommon {
  mode: "neutral" | "selectable" | "unselectable" | "selectableUnselectable";
  label: string;
  onChangeState: (newState: MultiStageDisplayStateValue) => void;
  fit?: boolean;
  textAlign?: TextAlignment;
}

interface MultiStateDisplayState extends MultiStateDisplayCommon {
  currentState: MultiStageDisplayStateValue;
}

interface MultiStateDisplayUncontrolledState extends MultiStateDisplayCommon {
  initialState: MultiStageDisplayStateValue;
}
interface MultiStateDisplayCoreProps extends PropsWithChildren {
  label: string;
  onClick?: Function;
  classes?: string;
  bgColor?: string;
  padding?: string;
  typographyColor?: string;
  typographySx?: any;
  fit?: boolean;
  clickable?: boolean;
  borderColor?: string;
  textAlign?: TextAlignment;
}

export function MultiStateDisplayCore(props: MultiStateDisplayCoreProps) {
  let typographyProps: any = {
    style: { flexGrow: 1, textAlign: "center" },
  };
  if (props.textAlign) {
    typographyProps.style.textAlign = props.textAlign;
  }
  if (props.typographyColor) {
    typographyProps["color"] = props.typographyColor;
  }
  if (props.typographySx) {
    typographyProps["sx"] = props.typographySx;
  }

  const cursor = props.clickable ? "pointer" : "default";

  return (
    <Box
      borderRadius={"4px"}
      border={"solid 1px "}
      borderColor={props.borderColor ?? "primary.light"}
      textAlign={"center"}
      sx={{ cursor }}
      onClick={() => props.onClick?.()}
      bgcolor={props.bgColor ?? "none"}
      height={"100%"}
      display={props.fit ? "inline-flex" : "flex"}
      alignItems="center"
      padding={props.padding ?? "6px 12px"}
      boxSizing="border-box"
      position="relative"
    >
      <Typography {...typographyProps}>{props.label}</Typography>
      {props.children && (
        <Box
          position={"absolute"}
          right={"0"}
          top={"55%"}
          style={{
            transform: "translate(-50%, -50%)",
          }}
        >
          {props.children}
        </Box>
      )}
    </Box>
  );
}

export function MultiStateDisplay(props: MultiStateDisplayState) {
  const handleClick = () => {
    if (!props.onChangeState) {
      return;
    }
    if (props.mode === "selectable") {
      if (props.currentState === "selected") {
        props.onChangeState("neutral");
      }
      if (props.currentState === "neutral") {
        props.onChangeState("selected");
      }
    }
    if (props.mode === "unselectable") {
      if (props.currentState === "unselected") {
        props.onChangeState("neutral");
      }
      if (props.currentState === "neutral") {
        props.onChangeState("unselected");
      }
    }
    if (props.mode === "selectableUnselectable") {
      if (props.currentState === "selected") {
        props.onChangeState("unselected");
      }
      if (props.currentState === "unselected") {
        props.onChangeState("selected");
      }
    }
  };
  const theme = useTheme();
  const commonProps = {
    fit: props.fit,
    label: props.label,
    textAlign: props.textAlign,
  };
  const selectedProps = {
    bgColor: "primary.main",
    typographyColor: "primary.contrastText",
  };
  const unselectedProps = {
    typographySx: { opacity: 0.3 },
  };

  if (props.mode === "neutral") {
    if (props.currentState === "selected") {
      return <MultiStateDisplayCore {...commonProps} {...selectedProps} />;
    }
    if (props.currentState === "unselected") {
      return <MultiStateDisplayCore {...commonProps} {...unselectedProps} />;
    }
    return <MultiStateDisplayCore {...commonProps} />;
  }
  if (props.currentState === "selected") {
    return (
      <MultiStateDisplayCore
        {...commonProps}
        onClick={handleClick}
        clickable
        {...selectedProps}
      />
    );
  }
  if (props.currentState === "unselected") {
    return (
      <MultiStateDisplayCore
        {...commonProps}
        onClick={handleClick}
        clickable
        {...unselectedProps}
        borderColor={lighten(theme.palette.primary.light, 0.5)}
      />
    );
  }
  return (
    <MultiStateDisplayCore {...commonProps} onClick={handleClick} clickable />
  );
}

export function MultiStateDisplayUncontrolled(
  props: MultiStateDisplayUncontrolledState
) {
  const [currentState, setCurrentState] =
    useState<MultiStageDisplayStateValue>("neutral");
  return (
    <MultiStateDisplay
      {...props}
      currentState={currentState}
      onChangeState={(newState) => {
        setCurrentState(newState);
        props.onChangeState?.(newState);
      }}
    />
  );
}
