import { useEffect, useState } from "react";
import { fetchWebViewData, getCode } from "../../services/bi.api";
import "../../styles/client/budgetInsightWebView.scss";
import { WebViewData } from "../../types/webviewData.type";
import { useNavigate } from "react-router-dom";
import { routeType } from "../../routes";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../slice/login.slice";
import BiIframe from "../../components/case/biIframe";
import { useTranslation } from "react-i18next";

//TODO: this is not used anymore 
export default function BudgetInsightWebView() {
  const { t } = useTranslation();
  const [webviewData, setWebviewData] = useState<WebViewData>();
  const navigate = useNavigate();
  const jwtPayload = useSelector(selectUser);
  const [biTmpCode, setBiTmpCode] = useState<string>();

  const [iframeUrl, setIframeUrl] = useState<string>();
  const [queryParams, setQueryParams] = useState<URLSearchParams>(
    new URLSearchParams()
  );

  //TODO: mutualize setUrlIframeBank and setUrlIframeDocument and take a parameter in input "type" or something like that
  const setUrlIframe = (isDocument: boolean, onlyTaxes = false) => {
    if (webviewData) {
      const queryParams = new URLSearchParams({
        client_id: webviewData.clientId,
        redirect_uri: webviewData.webviewRedirectUrl,
        state: jwtPayload.id,
      });
      if (isDocument) {
        queryParams.append("connector_capabilities", "document");
      }
      if (onlyTaxes) {
        queryParams.append("connector_ids", "812"); // the id of the taxes connector  (impot.gouv.fr)
      }
      let url: string;
      if (jwtPayload.biConnected && biTmpCode) {
        queryParams.append("code", biTmpCode);
        url = webviewData.webviewManageUrl;
      } else {
        url = webviewData.webviewConnectUrl;
      }
      //TODO: do proper url encode and use https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams (native) or https://www.npmjs.com/package/qs instead of string concatenation
      // CARE : budget insight doesn't accept redirect_uri or code  when url encoded
      setIframeUrl(url);
      setQueryParams(queryParams);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setWebviewData(await fetchWebViewData());
        if (jwtPayload.biConnected) {
          const code = await getCode(jwtPayload.id);
          setBiTmpCode(code.code);
        }
      } catch (err) {
        navigate(routeType.home);
      }
    })();
  }, [jwtPayload]);

  return (
    <div className="webview-container">
      <h2>{t("webview.title")}</h2>
      <div>
        <Button
          className="webview-button"
          variant="contained"
          onClick={() => setUrlIframe(false)}
        >
          {jwtPayload && jwtPayload.biConnected
            ? t("webview.bank-manage")
            : t("webview.bank-connect")}
        </Button>

        <Button
          variant="contained"
          className="webview-button"
          onClick={() => setUrlIframe(true)}
        >
          {jwtPayload && jwtPayload.biConnected
            ? t("webview.document-manage")
            : t("webview.document-connect")}
        </Button>
        <Button
          className="webview-button"
          variant="contained"
          onClick={() => setUrlIframe(true, true)}
        >
          {jwtPayload && jwtPayload.biConnected
            ? t("webview.taxe-manage")
            : t("webview.taxe-connect")}
        </Button>
      </div>
      {iframeUrl && webviewData?.webviewRedirectUrl && (
        <BiIframe
          url={iframeUrl}
          queryParams={queryParams}
          redirectUrl={webviewData?.webviewRedirectUrl}
        />
      )}
    </div>
  );
}
