import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useParams, useNavigate } from "react-router-dom";
import { ClientDocumentType } from "../../types/caseForm.type";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";

import React from "react";
import Upload from "../../components/common/upload";
import Loader from "../../components/common/loading";

import "../../styles/client/client-upload.scss";

export default function UploadDesktopQrCode(props: {
  identityMode: boolean;
  previousRoute?: string;
  setDisplayLikeMobile: (display: boolean) => void;
  validateFiles: (files: any[]) => void;
  clientDocumentType: ClientDocumentType;
}) {
  const params = useParams();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openPopupModalUploadMobile, setOpenPopupModalUploadMobile] =
    useState(false);
  const [disableContinueDevice, setDisableContinueDevice] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  return (
    <>
      <Grid item>
        <Typography textAlign={"left"} mt={2}>
          {t("upload.intro-desktop-info")}
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop={3}>
        <Grid container>
          <Grid item xs={8} m="auto">
            <Upload
              identityMode={props.identityMode}
              cdtId={props.clientDocumentType.id}
              files={files}
              setFiles={setFiles}
              cancelOpen={() => {
                setDisableContinueDevice(false);
              }}
              clickOpen={() => {
                setDisableContinueDevice(true);
              }}
              openModal={() => {
                if (disableContinueDevice) {
                  setOpenPopupModalUploadMobile(true);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={8} m="auto" textAlign={"center"}>
          <Button
            disabled={disableContinueDevice}
            className="next-button action"
            variant="contained"
            sx={{
              marginTop: "25px",
              fontWeight: "bold",
            }}
            onClick={() => {
              props.setDisplayLikeMobile(true);
            }}
          >
            {t("upload.continue-current-device")}
          </Button>
        </Grid>
      </Grid>
      <Grid item textAlign={"center"} marginTop={5}>
        <Button
          variant="outlined"
          className="next-button"
          onClick={() => {
            if (props.previousRoute) {
              navigate(props.previousRoute);
            }
          }}
        >
          {t("global.back")}
        </Button>
      </Grid>
      {disableContinueDevice && (
        <Dialog
          open={openPopupModalUploadMobile}
          fullWidth
          onClose={() => {
            setOpenPopupModalUploadMobile(false);
          }}
        >
          <DialogContent>
            <Grid
              container
              direction={"column"}
              alignItems={"stretch"}
              justifyContent={"flex-start"}
            >
              <Grid item textAlign={"center"}>
                {t("upload.dialog-wait-documents-title")}
              </Grid>
              <Grid item textAlign={"center"} m={3}>
                <Loader />
              </Grid>
              {files.length > 0 && (
                <Grid item textAlign={"center"}>
                  <Typography fontWeight={"bold"}>
                    {t("upload.dialog-wait-documents-list-title")}
                  </Typography>
                </Grid>
              )}
              {files.map((file, index) => {
                return (
                  <Grid item container key={index}>
                    <Grid item xs={8} marginTop={2}>
                      <Box
                        textAlign={"left"}
                        sx={{ overflowWrap: "break-word" }}
                      >
                        {file.name}
                      </Box>
                    </Grid>
                    <Grid item xs={2} marginTop={2}>
                      <RemoveRedEyeIcon
                        cursor="pointer"
                        color="primary"
                        onClick={() => {
                          window.open(URL.createObjectURL(files[index]));
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} marginTop={2}>
                      <DeleteIcon
                        cursor="pointer"
                        color="error"
                        onClick={() => {
                          setFiles([...files.filter((_, i) => i !== index)]);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              {files.length > 0 && (
                <Grid item textAlign={"center"} pt={2}>
                  <Button
                    variant="contained"
                    className="title"
                    disabled={!files || files.length === 0}
                    onClick={() => {
                      setOpenPopupModalUploadMobile(false);
                      props.validateFiles(files);
                    }}
                  >
                    {t(
                      props.identityMode
                        ? "upload.send-files-end-identity"
                        : "upload.send-files-end"
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
