import { Box, Button, FormControlLabel, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getClient,
  getClientCgu,
  getMe,
  updateClientCgu,
} from "../../services/user.api";
import { Borrower } from "../../types/caseForm.type";
import "../../styles/client/clientCGU.scss";
import { useTranslation } from "react-i18next";
import { routeType } from "../../routes";
import Loader from "../../components/common/loading";
import { useDispatch } from "react-redux";
import { customBackContext, clearBackContext } from "../../slice/back.slice";
import { enqueueSnackbar } from "notistack";
import { userUpdate } from "../../slice/login.slice";
import { AntSwitch } from "../../components/common/switchCustom";
import { set } from "lodash";
import { LocalStorageKey } from "../../types/localStorageEnum";
import { SessionStorageKey } from "../../types/sessionStorageEnum";
export function ClientCgu() {
  const params = useParams();
  const [accepted, setAccepted] = useState(false);
  const [isIntroOk, setIsIntroOk] = useState<boolean>(false);

  const [client, setClient] = useState<Borrower>();
  const [clientCgu, setClientCgu] = useState<{ html: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      customBackContext({
        disabled: true,
        className: "hide-back-button",
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (params.id) {
        const clientInfo = await getClient(params.id);
        if (clientInfo.hasAcceptedCGU) {
          const finalRoute =
            localStorage.getItem(
              LocalStorageKey.CLIENT_PROCESS_PRESENTATION
            ) === params.id
              ? "/"
              : "/informations";
          navigate(routeType.client + "/" + params.id + finalRoute);
        } else {
          setClient(clientInfo);

          setClientCgu(await getClientCgu(params.id));
        }
      }
    })();
  }, [params.id]);

  const handleSubmit = async () => {
    if (!params.id) {
      return;
    }
    if (!accepted) {
      enqueueSnackbar(t("errors.cgu_not_check"), { variant: "error" });
      return;
    }
    try {
      await updateClientCgu(params.id);
      const payload = await getMe();
      dispatch(userUpdate(payload));
      if (payload && payload.hasAcceptedCgu) {
        localStorage.setItem(LocalStorageKey.CGU_USER_ID, payload.id);
      }
      if (payload && payload.hasAcceptedCgu) {
        sessionStorage.setItem(SessionStorageKey.FIRST_USE, "true");
      }
      navigate(routeType.client + "/" + params.id + "/informations");
    } catch (error) {
      localStorage.removeItem(LocalStorageKey.CGU_USER_ID);
      enqueueSnackbar(t("errors.cgu_not_accepted"), { variant: "error" });
    }
  };
  return (
    <Grid container className="cgu-content" sx={{ pt: 1, textAlign: "left" }}>
      {!client && <Loader />}

      {client && !isIntroOk && (
        <>
          <Grid container sx={{ textAlign: "left" }} spacing={2} rowSpacing={4}>
            <Grid item xs={12}>
              <h3>
                {t("global.cgu-hello")} {client.firstName} {client.lastName}
              </h3>
              <p>{t("global.cgu-intro")}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="validate-button">
                <Button onClick={() => setIsIntroOk(true)} variant="contained">
                  {t("global.next-step")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {client && isIntroOk && clientCgu && (
        <>
          <div dangerouslySetInnerHTML={{ __html: clientCgu.html || "" }}></div>
          <Grid item xs={12} px={1}>
            <FormControlLabel
              value="left"
              componentsProps={{
                typography: { align: "left", fontSize: "0.9em" },
              }}
              control={
                <AntSwitch
                  checked={accepted}
                  inputProps={{ "aria-label": "ant design" }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAccepted(event.target.checked);
                  }}
                />
              }
              label={t("global.cgu-label-checkbox")}
              labelPlacement="end"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <div className="validate-button">
              <Button onClick={handleSubmit} variant="contained">
                {t("global.next-step")}
              </Button>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}
