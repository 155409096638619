import { useTranslation } from "react-i18next";
import { DocumentType } from "../../types/documentList";
import { ReactComponent as MultiFileIcon } from "../../resources/icons/restitution/files.svg";

import "../../styles/case/documentTypeList.scss";
import { MultiStateDisplayCore, TextAlignment } from "./MultiStateDisplay";
import { ClientDocumentTypeStatus } from "../../types/caseForm.type";
import {
  LENDY_FILE_SUBMITTED_COLOR,
  LENDY_FILE_VALIDATED_COLOR,
  LENDY_PRIMARY_COLOR,
} from "../../constants";

interface CustomProps {
  classes?: string;
  bgColor?: string;
  padding?: string;
  typographyColor?: string;
  typographySx?: any;
  fit?: boolean;
  clickable?: boolean;
  borderColor?: string;
  textAlign?: TextAlignment;
}

export default function DocumentTypeValidationComponent(props: {
  multipleFiles?: boolean;
  docType: DocumentType;
  clientId?: string;
  handleClick?: Function;
  state: ClientDocumentTypeStatus;
  textAlign?: TextAlignment;
}) {
  const { t } = useTranslation();

  const handleClick = (state: string) => {
    if (props.handleClick) {
      props.handleClick(props.docType, props.clientId);
    }
  };

  const setPropsFromStatus = (state: ClientDocumentTypeStatus) => {
    let specificProps: CustomProps;
    switch (state) {
      case ClientDocumentTypeStatus.VALIDATED:
        specificProps = {
          borderColor: LENDY_FILE_VALIDATED_COLOR,
          bgColor: LENDY_FILE_VALIDATED_COLOR,
          typographyColor: "primary.contrastText",
        };
        break;
      case ClientDocumentTypeStatus.SUBMITTED:
      case ClientDocumentTypeStatus.REFUSED:
        specificProps = {
          borderColor: LENDY_FILE_SUBMITTED_COLOR,
          bgColor: LENDY_FILE_SUBMITTED_COLOR,
          typographyColor: "primary.contrastText",
        };
        break;
      default:
        specificProps = {
          borderColor: LENDY_PRIMARY_COLOR,
        };
        break;
    }
    return specificProps;
  };

  const extraProps = setPropsFromStatus(props.state);
  return (
    <MultiStateDisplayCore
      label={t("doc-type." + props.docType.name)}
      key={"DTC-" + props.docType.id}
      clickable={[
        ClientDocumentTypeStatus.VALIDATED,
        ClientDocumentTypeStatus.SUBMITTED,
        ClientDocumentTypeStatus.REFUSED,
      ].includes(props.state)}
      onClick={handleClick}
      padding={props.multipleFiles ? "6px 28px" : "6px 12px"} // necessary to let space to child icon
      {...extraProps}
    >
      {/* if multiple files, need to display specific icon */}
      {props.multipleFiles && <MultiFileIcon />}
    </MultiStateDisplayCore>
  );
}
