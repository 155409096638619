import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { Roles } from "../types/role.enum";
import { useTranslation } from "react-i18next";
import { selectUser } from "../slice/login.slice";
import { LocalStorageKey } from "../types/localStorageEnum";
import { JwtPayload } from "../types/JwtPayload.type";
import { routeType } from "../routes";

export default function ProtectedRoute(props: {
  children: any;
  profiles: Roles[];
  checkId?: boolean;
  checkCgu?: boolean;
}) {
  const { t } = useTranslation();
  const params = useParams();
  let jwtPayload = useSelector(selectUser);

  const authed = (() => {
    if (!jwtPayload || !jwtPayload.id) {
      jwtPayload = {
        role: localStorage.getItem(LocalStorageKey.PROFILE) as Roles,
        id: localStorage.getItem(LocalStorageKey.USER_ID) as string,
        hasAcceptedCgu:
          (localStorage.getItem(LocalStorageKey.CGU_USER_ID) as string) ===
          (localStorage.getItem(LocalStorageKey.USER_ID) as string),
      } as JwtPayload;
    }
    if (
      !jwtPayload ||
      (!jwtPayload.role && props.profiles && props.profiles.length > 0)
    ) {
      enqueueSnackbar(t("errors.connected-page"), { variant: "error" });
      return { authed: false, redirection: routeType.home };
    }
    if (jwtPayload.role && props.profiles && props.profiles.length > 0) {
      const hasRight = props.profiles.includes(jwtPayload.role as Roles);
      if (!hasRight) {
        enqueueSnackbar(t("errors.no-rights"), { variant: "error" });
      }
      if (
        props.checkId &&
        jwtPayload.role === Roles.CLIENT &&
        params.id !== jwtPayload.id
      ) {
        return { authed: false, redirection: routeType.home };
      }
      if (
        props.checkCgu &&
        jwtPayload.role === Roles.CLIENT &&
        !jwtPayload.hasAcceptedCgu
      ) {
        return {
          authed: false,
          redirection: `${routeType.client}/${jwtPayload.id}/cgu`,
        };
      }
      return { authed: hasRight, redirection: routeType.login };
    }
    return { authed: true, redirection: "" };
  })();
  if (authed.authed) {
    return props.children;
  } else {
    console.error("no right, redirect", authed);
  }

  return <Navigate to={authed.redirection} replace />;
}
