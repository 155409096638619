import { useTranslation } from "react-i18next";
import { DocumentType } from "../../types/documentList";
import "../../styles/case/documentTypeList.scss";
import {
  MultiStageDisplayStateValue,
  MultiStateDisplay,
  TextAlignment,
} from "./MultiStateDisplay";

export default function DocumentTypeComponent(props: {
  docType: DocumentType;
  clientId?: string;
  handleClick?: Function;
  mode: "neutral" | "selectable" | "unselectable";
  state: MultiStageDisplayStateValue;
  textAlign?: TextAlignment;
}) {
  const { t } = useTranslation();

  const handleClick = (state: string) => {
    if (props.handleClick) {
      props.handleClick(props.docType, props.clientId);
    }
  };
  return (
    <MultiStateDisplay
      currentState={props.state}
      label={t("doc-type." + props.docType.name)}
      mode={props.mode}
      key={"DTC-" + props.docType.id}
      onChangeState={handleClick}
      textAlign={props.textAlign}
    />
  );
}
