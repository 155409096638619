export enum TypeAlert {
    ERROR = "error",
    WARNING = "warning",
    INFO = "info",
    SUCCESS = "success",
  }

export type Alert = {
    type: TypeAlert;
    value: string;
  };