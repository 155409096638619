import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import "../../styles/common/navBar.scss";

function ClientResponsiveAppBar() {
  return (
    <AppBar
      position="static"
      className="header"
      sx={{ boxShadow: 0, backgroundColor: "white" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{ flexGrow: 2, display: { xs: "flex" } }}
            alignItems={"center"}
          >
            <Box mt={"auto"} display={"contents"}>
              <img height={"30px"} alt={"Logo"} src="/Lendy.png" />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ClientResponsiveAppBar;
