import { Button, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../styles/common/backButton.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectBack } from "../../slice/back.slice";
import { ReactComponent as BackIcon } from "../../resources/icons/back.svg";
export default function BackButton() {
  const back = useSelector(selectBack);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [disableBack, setDisableBack] = useState(true);
  const location = useLocation();
  const [historyBase, setHistoryBase] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (!back || !back.shoulBlockIncrement) {
      setHistoryBase(historyBase + 1);
      if (historyBase <= 0) {
        setDisableBack(true);
      }
      if (historyBase > 0) {
        setDisableBack(false);
      }
    }
  }, [location, back]);

  return (
    <>
      {back && !back.disabled && (
        <h3
          className={
            (back && back.className !== undefined
              ? back.className
              : "custom-back-button") + " no-hover"
          }
        >
          <Button
            disabled={
              back && back.disabled !== undefined ? back.disabled : disableBack
            }
            onClick={() => {
              if (back && back.route) {
                navigate(back.route);
                setHistoryBase(historyBase - 2);
              } else {
                setHistoryBase(historyBase - 2);
                navigate(-1);
              }
            }}
          >
            <BackIcon fill={theme.palette.primary.main} color={"primary"} />

            {back && back.title ? back.title : ""}
          </Button>
        </h3>
      )}
    </>
  );
}
