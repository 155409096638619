import { Box, Grid, useTheme, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import {
  BudgetInsightConnector,
  Case,
  ClientDocumentType,
} from "../../types/caseForm.type";
import { getClientMineConnections, getConnectors } from "../../services/bi.api";

import { ReactComponent as ToProcessIcon } from "../../resources/icons/toProcess.svg";
import { ReactComponent as Processed } from "../../resources/icons/processed.svg";
import "../../styles/client/client.scss";
import React from "react";
import { clearBackContext, customBackContext } from "../../slice/back.slice";
import { routeType } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/loading";

import ClientBi from "./clientBi";
import { BudgetInsightConnection } from "../../types/budgetInsight.type";
import { concat, flatten } from "lodash";
import { selectUser } from "../../slice/login.slice";
import { displayClientName } from "../../components/client/utils";

export function ClientDocumentBank(props: {
  isCoBorrower: boolean;
  // isMaster: boolean;
  selectedDocumentTypes: ClientDocumentType[];
  clientId: string;
  aCase: Case;
}) {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [connectors, setConnectors] = useState<BudgetInsightConnector[]>();
  const [selectedConnector, setSelectedConnector] =
    useState<BudgetInsightConnector>();
  const [existingConnections, setExistingConnections] =
    useState<BudgetInsightConnection[]>();
  const [displayOnlyMainConnectors, setDisplayOnlyMainConnectors] =
    useState<boolean>(true);
  const [loadingConnectors, setLoadingConnectors] = useState<boolean>(true);
  const [displayNextView, setDisplayNextView] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const jwtPayload = useSelector(selectUser);

  const mainConnectors = [
    "Crédit Agricole",
    "BNP Paribas",
    "Banque Populaire",
    "Caisse d'Épargne Particuliers",
    "Société Générale",
    "Crédit Mutuel",
    "La Banque Postale",
  ];

  useEffect(() => {
    (async () => {
      if (searchParams.get("display-ok") === "true") {
        setDisplayNextView(true);
      }
      try {
        setLoadingConnectors(true);
        const connectors = await getConnectors();
        const connectorIds = flatten(
          props.selectedDocumentTypes.map(
            (dt) => dt.documentType?.budgetInsightConnectorIds
          )
        ).filter(Boolean);
        setConnectors(
          connectors.filter((c) => connectorIds.includes("" + c.id))
        );
      } catch (err) {
        console.error(err);
        setConnectors([]);
      }
      setLoadingConnectors(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const connectionsIds = flatten(
          props.selectedDocumentTypes.map((dt) => dt.budgetInsightConnectionIds)
        ).filter(Boolean);

        const connections = await getClientMineConnections();
        setExistingConnections(
          connections.filter((c) =>
            connectionsIds.includes("" + c.connectionId)
          )
        );
      } catch (err) {
        console.error(err);
        setExistingConnections([]);
      }
    })();
  }, [props.aCase.id, props.selectedDocumentTypes, connectors]);

  const getFilteredAndOrderedConnectors = (): BudgetInsightConnector[] => {
    const existingNames = existingConnections?.map((c) => c.connectorName);
    const existingConnectors = connectors?.filter((c) =>
      existingNames?.includes(c.name)
    );
    const mainConnectorsOrdered = connectors
      ?.filter(
        (c) =>
          existingConnectors?.findIndex((ec) => ec.name === c.name) === -1 &&
          mainConnectors.includes(c.name)
      )
      .sort(
        (a, b) =>
          mainConnectors.indexOf(a.name) - mainConnectors.indexOf(b.name)
      );
    if (displayOnlyMainConnectors) {
      return concat(
        existingConnectors || [],
        mainConnectorsOrdered || []
      ).filter(Boolean);
    }

    const otherConnectors = connectors?.filter(
      (c) =>
        mainConnectorsOrdered?.findIndex((ec) => ec.id === c.id) === -1 &&
        existingConnectors?.findIndex((ec) => ec.id === c.id) === -1
    );

    return concat(
      existingConnectors || [],
      mainConnectorsOrdered || [],
      otherConnectors || []
    ).filter(Boolean);
  };
  const generateDocTypeList = (
    connectorsToDisplay: BudgetInsightConnector[],
    withButtonMoreConnectors: boolean
  ) => {
    return (
      <>
        {connectorsToDisplay.map((co) => (
          <Grid
            item
            container
            direction={"row"}
            key={`conn-display-${co.uuid}`}
            alignItems={"center"}
            justifyContent={"space-between"}
            className="group-row-bank"
            p={2}
            wrap="nowrap"
            onClick={() => {
              setSelectedConnector(co);
            }}
          >
            <Grid item pr={2}>
              <img
                src={co.logo}
                alt={co.name}
                style={{
                  verticalAlign: "middle",
                  maxWidth: "30px",
                  height: "26px",
                }}
              />
            </Grid>
            <Grid item flexGrow={1} zeroMinWidth>
              <Typography textAlign={"left"} noWrap>
                {co.name}
              </Typography>
            </Grid>

            <Grid item>
              <Box sx={{ cursor: "pointer" }} m="auto">
                {existingConnections
                  ?.map((ec) => ec.connectorName)
                  .includes(co.name) ? (
                  <Processed
                    fill={theme.palette.primary.main}
                    stroke={theme.palette.primary.main}
                    color={"primary"}
                    width={"100%"}
                    height={22}
                    style={{ verticalAlign: "middle" }}
                  />
                ) : (
                  <ToProcessIcon
                    fill={theme.palette.primary.main}
                    stroke={theme.palette.primary.main}
                    color={"primary"}
                    height={20}
                    style={{ verticalAlign: "middle" }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        ))}
        {withButtonMoreConnectors && (
          <Grid
            item
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            className="group-row-bank"
            p={2}
          >
            <Grid item flexGrow={1} sx={{ minWidth: 0 }}>
              <Typography
                onClick={() => {
                  setDisplayOnlyMainConnectors(false);
                }}
                textAlign={"left"}
                noWrap
                fontWeight={"bold"}
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Autre établissement financier
              </Typography>
            </Grid>

            <Grid item>
              <Box
                sx={{ cursor: "pointer" }}
                m="auto"
                onClick={() => {
                  setDisplayOnlyMainConnectors(false);
                }}
              >
                <ToProcessIcon
                  fill={theme.palette.primary.main}
                  stroke={theme.palette.primary.main}
                  color={"primary"}
                  height={20}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    );
  };
  const connectorsToDisplay = getFilteredAndOrderedConnectors();
  return (
    <Grid sx={{ pt: 1, flexGrow: 1, display: "flex", flexDirection: "column" }}>
      {(!props.aCase || loadingConnectors) && <Loader />}
      {!loadingConnectors &&
        params.id &&
        props.aCase &&
        selectedConnector &&
        !displayNextView && (
          <ClientBi
            isCoBorrower={props.isCoBorrower}
            //isMaster={}
            selectedDocumentTypes={props.selectedDocumentTypes}
            user={jwtPayload}
            selectedConnectors={[selectedConnector]}
            endRedirectionPage={
              (props.isCoBorrower ? "/co-emprunteur" : "") +
              "/groupe/" +
              params.groupId
            }
          />
        )}
      {!loadingConnectors && displayNextView && (
        <Grid
          flexGrow={1}
          item
          container
          direction={"column"}
          justifyContent={"stretch"}
          alignItems={"space-between"}
        >
          <Grid item>
            {displayClientName(
              props.aCase.borrowers,
              params.id || "",
              props.isCoBorrower
            )}
          </Grid>
          <Grid
            container
            item
            flexGrow={1}
            flexDirection={"column"}
            justifyContent={"center"}
            rowSpacing={5}
          >
            <Grid item>
              <Processed
                fill={theme.palette.primary.main}
                stroke={theme.palette.primary.main}
                color={"primary"}
                width={"100%"}
                height={40}
                style={{ verticalAlign: "middle" }}
              />
            </Grid>
            <Grid item>
              <Typography fontWeight={"bold"} fontSize={"1.4em"}>
                {t("case.transmitted-docs")}
              </Typography>
            </Grid>
            <Grid item container alignItems={"center"}>
              <Button
                className="next-button action"
                variant="contained"
                fullWidth
                onClick={() => setDisplayNextView(false)}
                sx={{ marginTop: "10px !important" }}
              >
                {t("global.next-step")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!loadingConnectors &&
        props.aCase &&
        !selectedConnector &&
        !displayNextView && (
          <>
            {displayClientName(
              props.aCase.borrowers,
              params.id || "",
              props.isCoBorrower
            )}
            <div className="text-notice">{t("client.bank-notice1")}</div>
            <div className="text-notice">
              <span>{t("client.bank-notice2")}</span>
            </div>
            <Grid
              pt={1}
              md={6}
              container
              direction={"column"}
              alignItems={"stretch"}
              justifyContent={"flex-start"}
            >
              {connectorsToDisplay.length > 0 &&
                generateDocTypeList(
                  connectorsToDisplay,
                  displayOnlyMainConnectors
                )}
              {existingConnections && existingConnections.length > 0 ? (
                <Grid item px={6} pt={3} container alignItems={"center"}>
                  <Button
                    className="next-button action"
                    variant="contained"
                    fullWidth
                    onClick={() => navigate(routeType.client + "/" + params.id)}
                  >
                    {t("case.end-bank")}
                  </Button>
                </Grid>
              ) : (
                <Grid item textAlign={"center"} marginTop={5}>
                  <Button
                    variant="outlined"
                    className="title"
                    onClick={() => {
                      navigate(routeType.client + "/" + params.id);
                    }}
                  >
                    {t("global.back")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
    </Grid>
  );
}
