import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isDesktop } from "react-device-detect";

import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { getClientDocumentType } from "../../services/case.api";
import { ClientDocumentType } from "../../types/caseForm.type";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadFiles } from "../../services/upload.api";
import { routeType } from "../../routes";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { selectUser } from "../../slice/login.slice";
import React from "react";
import Upload from "../../components/common/upload";
import Loader from "../../components/common/loading";
import { ReactComponent as BackIcon } from "../../resources/icons/back.svg";

import styled from "@emotion/styled";
import { getGroupNameFromDocumentType } from "../../services/utils-cdt";
import Webcam from "react-webcam";
import { dataURIToFile } from "../../services/utils";
import "../../styles/client/client-upload.scss";
import UploadDesktopQrCode from "../../components/client/uploadDesktopQrCode";

const AccordionCustom = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #ccc`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function UploadFile() {
  const params = useParams();
  const theme = useTheme();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();
  const fileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [clientDocumentType, setClientDocumentType] =
    useState<ClientDocumentType>();
  const [files, setFiles] = useState<any[]>([]);
  const [previousRoute, setPreviousRoute] = useState<string>();
  const [displayPreviewFiles, setDisplayPreviewFiles] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);

  const [disableContinueDevice, setDisableContinueDevice] = useState(false);
  const [loadingTransmitFiles, setLoadingTransmitFiles] = useState(false);
  const [displayLikeMobile, setDisplayLikeMobile] = useState(false);
  const [isOkCamera, setIsOkCamera] = useState(false);
  const [isLoadingCamera, setIsLoadingCamera] = useState(false);
  const [openConfirmRemoveFile, setOpenConfirmRemoveFile] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState<number>();
  const jwtPayload = useSelector(selectUser);

  const webcamRef = useRef<Webcam>(null);

  const [expandedAccordion, setExpandedAccordion] =
    React.useState<string | false>(false);
  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (params.cdtId) {
        const cdt = await getClientDocumentType(params.cdtId);
        setClientDocumentType(cdt);
        const groupe = getGroupNameFromDocumentType(cdt);
        const route =
          routeType.client +
          "/" +
          jwtPayload.id +
          (jwtPayload.id === cdt.client?.id ? "" : "/co-emprunteur") +
          "/groupe/" +
          groupe;
        setPreviousRoute(route);
      }
    })();
  }, []);

  const handleFileUpload = (newFiles: any[]) => {
    const newFilesFiltered = [
      ...files,
      ...(Array.from(newFiles) as any[]).filter(
        (x: { type: string }) =>
          x.type.includes("image/") || x.type.includes("/pdf")
      ),
    ];
    setFiles(newFilesFiltered);
    setOpenUploadModal(false);
    // setOpenPopupModalUploadMobile(false);
    setDisplayPreviewFiles(true);
    setExpandedAccordion(`file-${newFilesFiltered.length - 1}`);
  };

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (!imageSrc) {
        return;
      }
      const fileToAdd = dataURIToFile(imageSrc, "photo.jpg");
      handleFileUpload([fileToAdd]);
    }
  }, [webcamRef, files]);

  const sendFiles = async () => {
    if (clientDocumentType?.id) {
      try {
        setLoadingTransmitFiles(true);
        const type = searchParams.get("type");

        await uploadFiles(clientDocumentType?.id, files, type);
        enqueueSnackbar(t("successes.upload"), { variant: "success" });
        if (previousRoute) {
          setLoadingTransmitFiles(false);
          navigate(previousRoute);
        }
      } catch (err) {
        console.log("ERR on upload : ", err);
        enqueueSnackbar(t("errors.upload"), { variant: "error" });
        setLoadingTransmitFiles(false);
      }
    }
  };
  if (!clientDocumentType || loadingTransmitFiles) {
    return <Loader />;
  }

  return (
    <Box
      alignContent={"center"}
      sx={{ pt: 1, flexGrow: 1, display: "flex", flexDirection: "column" }}
    >
      <Grid container sx={{ textAlign: "left" }} spacing={2} rowSpacing={4}>
        {clientDocumentType && (
          <Grid item xs={12}>
            {clientDocumentType.client ? (
              <div className="title">
                {clientDocumentType.client.firstName}{" "}
                {clientDocumentType.client.lastName}
              </div>
            ) : (
              <div className="title">{t("case.common")}</div>
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        marginTop={2}
        sx={{ textAlign: "left", fontWeight: "bold" }}
      >
        <div>{t("doc-type." + clientDocumentType?.documentType?.name)}</div>
      </Grid>
      {clientDocumentType &&
        !displayPreviewFiles &&
        isDesktop &&
        !displayLikeMobile &&
        previousRoute && (
          <UploadDesktopQrCode
            identityMode={false}
            previousRoute={previousRoute}
            setDisplayLikeMobile={setDisplayLikeMobile}
            validateFiles={(newFiles) => {
              setFiles([...files, ...newFiles]);
              setDisplayPreviewFiles(true);
              setExpandedAccordion(`file-0`);
            }}
            clientDocumentType={clientDocumentType}
          />
        )}
      {clientDocumentType &&
        !displayPreviewFiles &&
        (!isDesktop || displayLikeMobile) && (
          <>
            <Grid
              container
              flexGrow={1}
              direction={"column"}
              mt={2}
              justifyContent={"flex-start"}
              alignItems={"stretch"}
            >
              <Grid item>
                <Typography>{t("upload.add-document-intro")}</Typography>
              </Grid>
              <Grid item mt={5}>
                <Button
                  variant="contained"
                  className="next-button action"
                  onClick={() => {
                    setOpenUploadModal(true);
                    setIsLoadingCamera(true);
                    setIsOkCamera(false);
                  }}
                >
                  {t("upload.take-photo")}
                </Button>
              </Grid>
              <Grid item mt={2}>
                <Button
                  className="next-button action"
                  onClick={() => fileInput.current && fileInput.current.click()}
                  variant="contained"
                >
                  {t("upload.choose-local-file")}
                </Button>
                {fileInput && (
                  <input
                    ref={fileInput}
                    style={{ display: "none" }}
                    accept="image/*,application/pdf"
                    type="file"
                    capture="environment"
                    onChange={(e) => {
                      if (
                        e.target &&
                        e.target.files &&
                        e.target.files.length > 0
                      ) {
                        handleFileUpload([e.target.files[0]]);
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid item textAlign={"center"} marginTop={2}>
                <Button
                  variant="outlined"
                  className="next-button"
                  onClick={() => {
                    if (displayLikeMobile) {
                      setDisplayLikeMobile(false);
                      return;
                    }
                    if (previousRoute) {
                      navigate(previousRoute);
                    }
                  }}
                >
                  {t("global.back")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      {displayPreviewFiles && files.length > 0 && (
        <Grid
          container
          flexGrow={1}
          direction={"column"}
          mt={2}
          justifyContent={"stretch"}
          alignItems={"stretch"}
        >
          <Grid
            item
            flexGrow={1}
            container
            direction={"column"}
            alignItems={"stretch"}
            justifyContent={"flex-start"}
          >
            {files.map((file, index) => {
              return (
                <AccordionCustom
                  key={`acc-file-${index}`}
                  expanded={
                    files.length === 1 || expandedAccordion === `file-${index}`
                  }
                  sx={{
                    paddingX: 1,
                    flexGrow:
                      files.length === 1 ||
                      expandedAccordion === `file-${index}`
                        ? 1
                        : 0,
                  }}
                  onChange={
                    files.length === 1
                      ? () => {}
                      : handleChangeAccordion(`file-${index}`)
                  }
                >
                  <AccordionSummary
                    aria-controls={`file-${index}d-content`}
                    id={`file-${index}d-content`}
                  >
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      wrap="nowrap"
                    >
                      <Grid item textAlign={"left"} zeroMinWidth>
                        <Typography textAlign={"left"} noWrap>
                          {file.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} container justifyContent={"flex-end"}>
                        {files.length > 1 && (
                          <Grid item>
                            {expandedAccordion !== `file-${index}` ? (
                              <RemoveRedEyeIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </Grid>
                        )}
                        <Grid ml={2} item>
                          <DeleteIcon
                            cursor="pointer"
                            color="error"
                            onClick={() => {
                              setIndexToRemove(index);
                              setOpenConfirmRemoveFile(true);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {file.type.includes("image/") ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt="aperçu document"
                        style={{
                          width: "auto",
                          maxWidth: "100%",
                          maxHeight: "50vh",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(URL.createObjectURL(files[index]));
                        }}
                      >
                        <Grid item>{t("upload.show-document-pdf")}</Grid>
                        <Grid item>
                          <RemoveRedEyeIcon cursor="pointer" color="primary" />
                        </Grid>
                      </Grid>
                    )}
                  </AccordionDetails>
                </AccordionCustom>
              );
            })}
          </Grid>
          <Grid textAlign={"center"} mt={5}>
            <Button
              variant="contained"
              className="next-button action"
              disabled={!files || files.length === 0}
              onClick={() => {
                sendFiles();
              }}
            >
              {files.length > 1
                ? t("upload.send-files")
                : t("upload.send-one-file")}
            </Button>
          </Grid>
          <Grid textAlign={"center"} mt={2}>
            <Button
              variant="contained"
              className="next-button action"
              disabled={!files || files.length === 0}
              onClick={() => {
                setDisplayPreviewFiles(false);
              }}
            >
              {t("upload.add-another-document")}
            </Button>
          </Grid>
          <Grid textAlign={"center"} mt={2}>
            <Button
              variant="outlined"
              className="next-button"
              disabled={!files || files.length === 0}
              onClick={() => {
                setOpenConfirmCancel(true);
              }}
            >
              {t("global.cancel")}
            </Button>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openConfirmCancel}
        onClose={() => setOpenConfirmCancel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("upload.confirm-cancel-upload-title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("upload.confirm-cancel-upload-text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmCancel(false)}>
            {t("global.no")}
          </Button>
          <Button
            onClick={() => {
              setOpenConfirmCancel(false);
              if (displayLikeMobile) {
                setDisplayLikeMobile(false);
              }
              setDisplayPreviewFiles(false);
              setFiles([]);
            }}
            autoFocus
          >
            {t("global.yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmRemoveFile}
        onClose={() => setOpenConfirmRemoveFile(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-remove-title">
          {t("upload.confirm-remove-file-title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-remove-description">
            {t("upload.confirm-remove-file-text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmRemoveFile(false)}>
            {t("global.cancel")}
          </Button>
          <Button
            onClick={() => {
              if (indexToRemove !== undefined && files.length > indexToRemove) {
                setFiles([...files.filter((_, i) => i !== indexToRemove)]);
              }
              if (files.length === 1) {
                setDisplayPreviewFiles(false);
              }
              setOpenConfirmRemoveFile(false);
            }}
            autoFocus
          >
            {t("global.remove")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUploadModal}
        onClose={() => {
          console.log("close dialog");
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        scroll="body"
        fullScreen={true}
      >
        <DialogContent>
          <Grid
            container
            direction={
              windowSize.innerWidth > windowSize.innerHeight &&
              windowSize.innerHeight < 600
                ? "row"
                : "column"
            }
            justifyContent={"space-between"}
            alignItems={"stretch"}
            sx={{ height: windowSize.innerHeight * 0.9 }}
          >
            <Grid item>
              <Box pt={3} alignItems={"start"} textAlign={"left"}>
                <Button
                  sx={{ minWidth: 0 }}
                  onClick={() => {
                    setOpenUploadModal(false);
                  }}
                >
                  <BackIcon fill={"#000000"} color={"#000000"} />
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              display={"flex"}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                {isLoadingCamera && <Loader />}
                {!isLoadingCamera && !isOkCamera && (
                  <Box sx={{ backgroundColor: "#fff", padding: 2 }}>
                    <Typography>{t("errors.camera_not_authorized")}</Typography>
                  </Box>
                )}
                <Webcam
                  ref={webcamRef}
                  style={{
                    margin: "auto",
                    display: isOkCamera ? "block" : "none",
                    height: "auto",
                    maxHeight: `${windowSize.innerHeight * 0.6}px`,
                    maxWidth: "100%",
                    borderRadius: "10px",
                    border: "2px solid",
                    borderColor: theme.palette.primary.main,
                  }}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    aspectRatio: isDesktop ? 0.707 : 1 / 0.707,
                    facingMode: "environment",
                  }}
                  screenshotQuality={1}
                  minScreenshotHeight={1080}
                  audio={false}
                  onUserMedia={(props) => {
                    setIsOkCamera(true);
                    setIsLoadingCamera(false);
                  }}
                  onUserMediaError={(props) => {
                    setIsOkCamera(false);
                    setIsLoadingCamera(false);
                    enqueueSnackbar(t("errors.camera_not_authorized"), {
                      variant: "error",
                    });
                  }}
                ></Webcam>

                {isOkCamera && (
                  <Grid item mt={1} textAlign={"center"}>
                    <Button variant="contained">
                      {t("upload.info-take-photo")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isOkCamera && (
              <Grid
                item
                display={"flex"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item textAlign={"center"}>
                  <Fab
                    sx={{ mx: "auto" }}
                    size="large"
                    aria-label="add"
                    onClick={capture}
                  >
                    &nbsp;
                  </Fab>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
