import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { groupBy, orderBy, uniqBy, zipObject } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentGroup, DocumentType } from "../../types/documentList";
import "../../styles/case/documentTypeList.scss";
import { Borrower, Case } from "../../types/caseForm.type";
import AddDocumentTypeDialog from "../../components/case/addDocumentDialog";
import { getDocumentGroupes, updateCase } from "../../services/case.api";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import DocumentTypeComponent from "../../components/case/DocumentTypeComponent";
import { ReactComponent as CommonFilesIcon } from "../../resources/icons/restitution/common-files.svg";

export default function DocumentTypeList(props: {
  documentTypes: DocumentType[];
  case: Case;
  clients: Borrower[];
  clientDocumentTypes: DocumentType[][];
  caseDocumentTypes: DocumentType[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [groupedDocTypes, setGroupedDocTypes] = useState<any>({
    clients: [],
    case: {},
  });
  const [isOpen, setIsOpen] = useState(false);
  const [dialogComponent, setDialogComponent] = useState<JSX.Element>();
  const [clientDocumentTypes, setClientDocumentTypes] = useState<
    DocumentType[][]
  >(props.clientDocumentTypes);
  const [caseDocumentTypes, setCaseDocumentTypes] = useState<DocumentType[]>(
    props.caseDocumentTypes
  );
  const [documentGroupes, setDocumentGroupes] = useState<{
    [op: number]: DocumentGroup;
  }>({});
  useEffect(() => {
    setClientDocumentTypes(props.clientDocumentTypes);
  }, [props.clientDocumentTypes]);
  useEffect(() => {
    setCaseDocumentTypes(props.caseDocumentTypes);
  }, [props.caseDocumentTypes]);

  useEffect(() => {
    (async () => {
      const documentGroups = await getDocumentGroupes();
      setDocumentGroupes(
        zipObject(
          documentGroups.map(
            (x: { globalCourtageCode: number }) => x.globalCourtageCode
          ),
          documentGroups
        )
      );
    })();
  }, []);

  useEffect(() => {
    const caseGroup = groupBy(
      orderBy(
        caseDocumentTypes.filter(Boolean),
        (x: { globalCourtageCode: any }) => x.globalCourtageCode
      ),
      (docType: DocumentType) => Math.trunc(docType?.globalCourtageCode / 100)
    );
    const clientsGroup = props.clients.map((x, index) => ({
      id: x.id,
      docTypes: groupBy(
        clientDocumentTypes && clientDocumentTypes[index]
          ? orderBy(
              clientDocumentTypes[index].filter(Boolean),
              (x: { globalCourtageCode: any }) => x.globalCourtageCode
            )
          : [],
        (docType: DocumentType) => Math.trunc(docType?.globalCourtageCode / 100)
      ),
    }));
    setGroupedDocTypes({ case: caseGroup, clients: clientsGroup });
  }, [props, clientDocumentTypes, caseDocumentTypes]);

  const updateDocType = (docType: DocumentType, docTypes: DocumentType[]) => {
    let newDocTypes = [...docTypes];
    if (docTypes.find((dt) => dt.id === docType.id && !docType.unselected)) {
      newDocTypes = newDocTypes.map((x) => {
        if (x.id === docType.id) {
          x.unselected = true;
        }
        return x;
      });
    } else {
      let newDocTypes = [...docTypes.filter((x) => !x.unselected)];
      docType.unselected = false;
      newDocTypes.push(docType);
    }
    return newDocTypes;
  };
  const toggleDocType = (docType: DocumentType, clientId?: string) => {
    const clientIndex = props.clients?.findIndex((x) => x.id === clientId);
    if (clientIndex === undefined || clientIndex < 0) {
      const newCaseDocumentType = updateDocType(
        docType,
        caseDocumentTypes.filter(Boolean)
      );
      setCaseDocumentTypes(newCaseDocumentType);
    } else {
      if (clientDocumentTypes && clientDocumentTypes.length > 0) {
        const newClientsDocumentTypes = [...clientDocumentTypes];
        newClientsDocumentTypes[clientIndex] = updateDocType(
          docType,
          newClientsDocumentTypes[clientIndex].filter(Boolean)
        );
        setClientDocumentTypes(newClientsDocumentTypes);
      }
    }
  };

  const userListDocTypeGroup = (group: number, client?: Borrower) => {
    if (!client) {
      if (!groupedDocTypes.case || !groupedDocTypes.case[group]) {
        return <div></div>;
      }
      return (
        <Grid
          container
          spacing={2}
          direction={"row"}
          mt={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {groupedDocTypes &&
            groupedDocTypes.case &&
            groupedDocTypes.case[group] &&
            groupedDocTypes.case[group].map((docType: DocumentType) => {
              return (
                <Grid item xs={10}>
                  <DocumentTypeComponent
                    docType={docType}
                    mode="unselectable"
                    state={docType.unselected ? "unselected" : "neutral"}
                    handleClick={toggleDocType}
                  />
                </Grid>
              );
            })}
        </Grid>
      );
    } else {
      const clientGroup = groupedDocTypes.clients.find(
        (x: { id: string }) => x.id === client.id
      );
      if (!clientGroup) {
        return <div></div>;
      }
      const docTypeGroups = clientGroup.docTypes;
      if (!docTypeGroups[group]) {
        return <div></div>;
      }
      return (
        <Grid
          container
          spacing={2}
          direction={"row"}
          mt={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {groupedDocTypes &&
            groupedDocTypes.clients &&
            groupedDocTypes.clients.length > 0 &&
            docTypeGroups[group].map((docType: DocumentType) => (
              <Grid item xs={10}>
                <DocumentTypeComponent
                  key={docType.id + "_" + client.id}
                  docType={docType}
                  handleClick={toggleDocType}
                  clientId={clientGroup.id}
                  mode="unselectable"
                  state={docType.unselected ? "unselected" : "neutral"}
                />
              </Grid>
            ))}
        </Grid>
      );
    }
  };

  const openDialog = (clientIndex?: number) => {
    let allDocTypesForClient = [];
    if (clientIndex !== undefined) {
      const clientDocTypeIds = clientDocumentTypes[clientIndex]
        .filter(Boolean)
        .filter((x) => !x.unselected)
        .map((x) => x.id);
      allDocTypesForClient = props.documentTypes.filter(
        (dt) => !clientDocTypeIds.includes(dt.id) && !dt.onlyCommon
      );
    } else {
      const caseDtIds = caseDocumentTypes
        .filter(Boolean)
        .filter((x) => !x.unselected)
        .map((x) => x.id);
      allDocTypesForClient = props.documentTypes.filter(
        (dt) => !caseDtIds.includes(dt.id) && !dt.onlyPersonal
      );
    }
    setDialogComponent(
      <AddDocumentTypeDialog
        client={
          clientIndex !== undefined ? props.clients[clientIndex] : undefined
        }
        docTypes={allDocTypesForClient}
        selectDocType={(dts: DocumentType[]) => {
          dts = dts.map((x) => {
            x.unselected = false;
            return x;
          });
          if (clientIndex !== undefined && clientIndex >= 0) {
            const cdt = [...clientDocumentTypes];
            cdt[clientIndex].push(...dts);
            cdt[clientIndex] = uniqBy(
              cdt[clientIndex].filter((x) => x && !x.unselected),
              "id"
            ).map((x) => {
              x.unselected = false;
              return x;
            });
            setClientDocumentTypes(cdt);
          } else {
            let cdt = [...caseDocumentTypes];
            cdt.push(...dts);
            cdt = uniqBy(
              cdt.filter((x) => x && !x.unselected),
              "id"
            ).map((x) => {
              x.unselected = false;
              return x;
            });
            setCaseDocumentTypes(cdt);
          }
          setIsOpen(false);
        }}
        close={() => {
          setIsOpen(false);
        }}
      />
    );
    setIsOpen(true);
  };

  const handleSubmit = async () => {
    try {
      await updateCase({
        id: props.case.id,
        documentTypes: caseDocumentTypes.filter((x) => x && !x.unselected),
        borrowers: props.clients.map((x, index) => {
          return {
            id: x.id as string,
            documentTypes: clientDocumentTypes[index].filter(
              (x) => x && !x.unselected
            ),
          };
        }),
      });
      enqueueSnackbar(t("successes.doc-types-saved"), { variant: "success" });
      navigate("/case/" + props.case.id + "/document/confirm");
    } catch (err) {
      console.log("ERROR SAVING DOCUMENT TYPES ", err);
      enqueueSnackbar(t("errors.doc-type-save"), { variant: "error" });
    }
  };

  const displayCaseDocType = (group: number) => {
    return (
      groupedDocTypes && groupedDocTypes.case && groupedDocTypes.case[group]
    );
  };
  const displayUserDocType = (group: number, userIndex: number) => {
    return (
      groupedDocTypes &&
      groupedDocTypes.clients &&
      groupedDocTypes.clients[userIndex] &&
      groupedDocTypes.clients[userIndex].docTypes &&
      groupedDocTypes.clients[userIndex].docTypes[group]
    );
  };

  const displayDocTypeGroupName = (group: number) => {
    return (
      documentGroupes &&
      documentGroupes[group] &&
      (displayCaseDocType(group) ||
        displayUserDocType(group, 0) ||
        displayUserDocType(group, 1))
    );
  };
  return (
    <div>
      <Box sx={{ flexGrow: 1 }} boxSizing={"border-box"}>
        {new Array(
          Object.keys(documentGroupes).filter(
            (groupKey) => !documentGroupes[+groupKey].parentId
          ).length
        )
          .fill(0)
          .map((_, index) => {
            return (
              <div key={"doc-type-list-" + index}>
                <Grid container direction="row" alignItems="stretch">
                  {index === 0 && (
                    <Grid
                      item
                      container
                      xs={12}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <h3>
                          {props.clients[0].lastName +
                            " " +
                            props.clients[0].firstName}
                        </h3>
                      </Grid>
                      {props.case.nbBorrower === 2 && (
                        <>
                          <Grid item xs={4}>
                            <CommonFilesIcon height={"30px"} />
                          </Grid>
                          <Grid item xs={4}>
                            <h3>
                              {props.clients[1].lastName +
                                " " +
                                props.clients[1].firstName}
                            </h3>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}

                  {displayDocTypeGroupName(index + 1) && (
                    <Grid item xs={12}>
                      <Box textAlign={"center"} paddingTop={"15px"}></Box>
                    </Grid>
                  )}
                  {displayDocTypeGroupName(index + 1) && (
                    <Grid item xs={4}>
                      {userListDocTypeGroup(index + 1, props.clients[0])}
                    </Grid>
                  )}
                  {props.case.nbBorrower === 2 && (
                    <>
                      {displayDocTypeGroupName(index + 1) && (
                        <Grid item xs={4}>
                          {userListDocTypeGroup(index + 1)}
                        </Grid>
                      )}
                      {displayDocTypeGroupName(index + 1) && (
                        <Grid item xs={4}>
                          {userListDocTypeGroup(index + 1, props.clients[1])}
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                <br />
              </div>
            );
          })}

        <Grid container direction="row" spacing={1} alignItems="stretch">
          <Grid xs={12} mt={"25px"} pt={"15px"}></Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                openDialog(0);
              }}
              variant="contained"
            >
              {t("global.modify")}
            </Button>
          </Grid>
          {props.case.nbBorrower === 2 && (
            <>
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    openDialog();
                  }}
                  variant="contained"
                >
                  {t("global.modify")}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    openDialog(1);
                  }}
                  variant="contained"
                >
                  {t("global.modify")}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container direction={"row-reverse"} spacing={2}>
          <Grid item xs={8} lg={2}>
            <Button
              fullWidth
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
            >
              {t("global.next")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {dialogComponent && (
        <Dialog
          open={isOpen}
          fullWidth
          maxWidth={"xl"}
          sx={{ height: "90vh" }}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          {dialogComponent}
        </Dialog>
      )}
    </div>
  );
}
