import { Box, Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getUser, updateUser } from "../../services/user.api";
import { User } from "../../types/User.type";

export default function EditUser() {
  const [user, setUser] = useState<User>();
  const { t } = useTranslation();
  const params = useParams();
  useEffect(() => {
    (async () => {
      if (params.id) {
        setUser(await getUser(params.id));
      }
    })();
  }, [params.id]);
  return (
    <Box sx={{ alignItems: "center", justifyContent: "center" }}>
      {user && (
        <Grid
          container
          direction="column"
          textAlign={"start"}
          alignItems="stretch"
          flex={1}
          spacing={2}
        >
          <Grid item>{t("clients.email")} :</Grid>
          <Grid item>
            <TextField
              type={"email"}
              value={user.email}
              onChange={(event) =>
                setUser({ ...user, email: event.target.value })
              }
            />
          </Grid>
          <Grid item>{t("clients.firstname")} :</Grid>
          <Grid item>
            <TextField
              value={user.firstName}
              onChange={(event) =>
                setUser({ ...user, firstName: event.target.value })
              }
            />
          </Grid>
          <Grid item>{t("clients.lastname")} :</Grid>

          <Grid item>
            <TextField
              value={user.lastName}
              onChange={(event) =>
                setUser({ ...user, lastName: event.target.value })
              }
            />
          </Grid>
          <Grid item>
            {t("user.is-admin")} :
            <Checkbox
              checked={user.isAdmin || false}
              onChange={(event: { target: { checked: any } }) =>
                setUser({ ...user, isAdmin: event.target.checked })
              }
            />
          </Grid>
          <Grid item>{t("user.white-mark")} :</Grid>
          <Grid item>
            <Select
              type={"text"}
              value={user.whiteMark}
              onChange={(event) =>
                setUser({ ...user, whiteMark: event.target.value })
              }
            >
              <MenuItem value="default">Défaut</MenuItem>
              <MenuItem value="ashler-manson">Ashler Manson</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={async () => {
                setUser(await updateUser(user));
              }}
            >
              {t("global.modify")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
