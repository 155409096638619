import { Box } from "@mui/material";
import { JSXElementConstructor, ReactElement } from "react";
import {
  AutocompleteElement,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

export const countries = [
  { id: "AF", label: "Afghane" },
  { id: "ZA", label: "Sud-africaine" },
  { id: "AL", label: "Albanaise" },
  { id: "DZ", label: "Algérienne" },
  { id: "DE", label: "Allemande" },
  { id: "AD", label: "Andorrane" },
  { id: "AO", label: "Angolaise" },
  { id: "AG", label: "Antiguaise et barbudienne" },
  { id: "SA", label: "Saoudienne" },
  { id: "AR", label: "Argentine" },
  { id: "AM", label: "Arménienne" },

  { id: "AU", label: "Australienne" },
  { id: "AT", label: "Autrichienne" },
  { id: "AZ", label: "Azerbaïdjanaise" },
  { id: "BS", label: "Bahamienne" },
  { id: "BH", label: "Bahreinienne" },
  { id: "BD", label: "Bangladaise" },
  { id: "BB", label: "Barbadienne" },
  { id: "BY", label: "Biélorusse" },
  { id: "BE", label: "Belge" },
  { id: "BZ", label: "Bélizienne" },
  { id: "BJ", label: "Béninoise" },

  { id: "BT", label: "Bhoutanaise" },
  { id: "BO", label: "Bolivienne" },

  { id: "BA", label: "Bosnienne" },
  { id: "BW", label: "Botswanaise" },

  { id: "BR", label: "Brésilienne" },
  { id: "BN", label: "Bruneienne" },
  { id: "BG", label: "Bulgare" },
  { id: "BF", label: "Burkinabé" },
  { id: "BI", label: "Burundaise" },
  { id: "CV", label: "Cap-verdienne" },

  { id: "KH", label: "Cambodgienne" },
  { id: "CM", label: "Camerounaise" },
  { id: "CA", label: "Canadienne" },
  { id: "CL", label: "Chilienne" },
  { id: "CN", label: "Chinoise" },

  { id: "CY", label: "Chypriote" },

  { id: "CO", label: "Colombienne" },
  { id: "KM", label: "Comorienne" },
  { id: "CG", label: "Congolaise" },
  { id: "CD", label: "Congolaise (Kinshasa)" },

  { id: "KR", label: "Sud-coréenne" },
  { id: "KP", label: "Nord-coréenne" },
  { id: "CR", label: "Costaricaine" },
  { id: "CI", label: "Ivoirienne" },
  { id: "HR", label: "Croate" },
  { id: "CU", label: "Cubaine" },

  { id: "DK", label: "Danoise" },
  { id: "DJ", label: "Djiboutienne" },
  { id: "DO", label: "Dominicaine" },
  { id: "DM", label: "Dominiquaise" },
  { id: "EG", label: "Egyptienne" },
  { id: "SV", label: "Salvadorienne" },
  { id: "AE", label: "Emirienne" },
  { id: "EC", label: "Equatorienne" },
  { id: "ER", label: "Erythréenne" },
  { id: "ES", label: "Espagnole" },
  { id: "EE", label: "Estonienne" },
  { id: "US", label: "Américaine" },
  { id: "ET", label: "Ethiopienne" },

  { id: "FJ", label: "Fidjienne" },
  { id: "FI", label: "Finlandaise" },
  { id: "FR", label: "Française" },
  { id: "GA", label: "Gabonaise" },
  { id: "GM", label: "Gambienne" },
  { id: "GE", label: "Georgienne" },

  { id: "GH", label: "Ghanéenne" },

  { id: "GR", label: "Hellénique" },
  { id: "GD", label: "Grenadienne" },

  { id: "GT", label: "Guatemaltèque" },

  { id: "GN", label: "Guinéenne" },
  { id: "GQ", label: "Equato-guinéenne" },
  { id: "GW", label: "Bissau-Guinéenne" },
  { id: "GY", label: "Guyanaise (Guyana)" },
  { id: "GF", label: "Guyanienne" },
  { id: "HT", label: "Haïtienne" },

  { id: "HN", label: "Hondurienne" },

  { id: "HU", label: "Hongroise" },

  { id: "IN", label: "Indienne" },

  { id: "ID", label: "Indonésienne" },
  { id: "IR", label: "Iranienne" },
  { id: "IQ", label: "Irakienne" },
  { id: "IE", label: "Irlandaise" },
  { id: "IS", label: "Islandaise" },
  { id: "IL", label: "Israélienne" },
  { id: "IT", label: "Italienne" },
  { id: "JM", label: "Jamaïcaine" },
  { id: "JP", label: "Japonaise" },

  { id: "JO", label: "Jordanienne" },
  { id: "KZ", label: "Kazakhstanaise" },
  { id: "KE", label: "Kenyane" },
  { id: "KG", label: "Kirghize" },
  { id: "KI", label: "Kiribatienne" },
  { id: "KW", label: "Koweitienne" },
  { id: "LA", label: "Laotienne" },
  { id: "LS", label: "Lesothane" },
  { id: "LV", label: "Lettone" },
  { id: "LB", label: "Libanaise" },
  { id: "LR", label: "Libérienne" },
  { id: "LY", label: "Libyenne" },
  { id: "LI", label: "Liechtensteinoise" },
  { id: "LT", label: "Lituanienne" },
  { id: "LU", label: "Luxembourgeoise" },

  { id: "MK", label: "Macédonienne" },
  { id: "MG", label: "Malgache" },
  { id: "MY", label: "Malaisienne" },
  { id: "MW", label: "Malawienne" },
  { id: "MV", label: "Maldivienne" },
  { id: "ML", label: "Malienne" },
  { id: "MT", label: "Maltaise" },

  { id: "MA", label: "Marocaine" },
  { id: "MH", label: "Marshallaise" },

  { id: "MU", label: "Mauricienne" },
  { id: "MR", label: "Mauritanienne" },

  { id: "MX", label: "Mexicaine" },
  { id: "FM", label: "Micronésienne" },
  { id: "MD", label: "Moldave" },
  { id: "MC", label: "Monégasque" },
  { id: "MN", label: "Mongole" },
  { id: "ME", label: "Monténégrine" },

  { id: "MZ", label: "Mozambicaine" },
  { id: "MM", label: "Birmane" },
  { id: "NA", label: "Namibienne" },
  { id: "NR", label: "Nauruane" },
  { id: "NP", label: "Népalaise" },
  { id: "NI", label: "Nicaraguayenne" },
  { id: "NE", label: "Nigérienne" },
  { id: "NG", label: "Nigériane" },

  { id: "NO", label: "Norvégienne" },

  { id: "NZ", label: "Neo-zélandaise" },
  { id: "OM", label: "Omanaise" },
  { id: "UG", label: "Ougandaise" },
  { id: "UZ", label: "Ouzbeke" },
  { id: "PK", label: "Pakistanaise" },
  { id: "PW", label: "Palau" },
  { id: "PS", label: "Palestinienne" },
  { id: "PA", label: "Panaméenne" },
  { id: "PG", label: "Papouane-neoguinéenne" },
  { id: "PY", label: "Paraguayenne" },
  { id: "NL", label: "Néerlandaise" },
  { id: "PE", label: "Péruvienne" },
  { id: "PH", label: "Philippine" },

  { id: "PL", label: "Polonaise" },

  { id: "PR", label: "Portoricaine" },
  { id: "PT", label: "Portugaise" },
  { id: "QA", label: "Qatarienne" },
  { id: "SY", label: "Syrienne" },
  { id: "CF", label: "Centrafricaine" },

  { id: "RO", label: "Roumaine" },
  { id: "GB", label: "Britannique (RU)" },
  { id: "RU", label: "Russe" },
  { id: "RW", label: "Rwandaise" },

  { id: "LC", label: "Saint-Lucienne" },
  { id: "KN", label: "Kittitienne-et-névicienne" },
  { id: "SM", label: "Saint-Marinaise" },

  { id: "VC", label: "Saint-Vincentaise-et-Grenadine" },
  { id: "SB", label: "Salomonaise" },
  { id: "WS", label: "Samoane" },

  { id: "ST", label: "Santoméenne" },
  { id: "SN", label: "Sénégalaise" },
  { id: "RS", label: "Serbe" },
  { id: "SC", label: "Seychelloise" },
  { id: "SL", label: "Sierra-leonaise" },
  { id: "SG", label: "Singapourienne" },
  { id: "SK", label: "Slovaque" },
  { id: "SI", label: "Slovène" },
  { id: "SO", label: "Somalienne" },
  { id: "SD", label: "Soudanaise" },
  { id: "SS", label: "Sud soudanaise" },
  { id: "LK", label: "Sri-lankaise" },
  { id: "SE", label: "Suédoise" },
  { id: "CH", label: "Suisse" },
  { id: "SR", label: "Surinamaise" },

  { id: "TJ", label: "Tadjike" },
  { id: "TW", label: "Taiwanaise" },
  { id: "TZ", label: "Tanzanienne" },
  { id: "TD", label: "Tchadienne" },
  { id: "CZ", label: "Tchèque" },

  { id: "TH", label: "Thaïlandaise" },
  { id: "TL", label: "Est-timoraise" },
  { id: "TG", label: "Togolaise" },

  { id: "TO", label: "Tongienne" },
  { id: "TT", label: "Trinidadienne" },
  { id: "TN", label: "Tunisienne" },
  { id: "TM", label: "Turkmène" },

  { id: "TR", label: "Turque" },
  { id: "TV", label: "Tuvaluane" },
  { id: "UA", label: "Ukrainienne" },
  { id: "UY", label: "Uruguayenne" },
  { id: "VU", label: "Vanuatuane" },
  { id: "VE", label: "Vénézuélienne" },

  { id: "VN", label: "Vietnamienne" },

  { id: "YE", label: "Yéménite" },
  { id: "ZM", label: "Zambienne" },
  { id: "ZW", label: "Zimbabwéenne" },
];

export default function CountrySelect(props: { index: number }) {
  const { t } = useTranslation();

  // ✅ work on refresh entire field array

  return (
    <Box display={"inline-block"} className={"form-input left"}>
      <Controller
        defaultValue={"FR"}
        render={function ({
          field,
          fieldState,
          formState,
        }: {
          field: ControllerRenderProps<FieldValues, string>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<FieldValues>;
        }): ReactElement<any, string | JSXElementConstructor<any>> {
          return (
            <AutocompleteElement
              options={countries.map((x) => x.id)}
              autocompleteProps={{
                getOptionLabel: (option) =>
                  countries.find((x) => x.id === option)?.label || option,
              }}
              name={`borrowers.${props.index}.nationality`}
              label={t("case.form-nationality")}
              required
            />
          );
        }}
        name={`borrowers.${props.index}.nationality`}
      ></Controller>
    </Box>
  );
}
