import jwt_decode from "jwt-decode";
import { AccessToken } from "../types/accessToken.type";
import { JwtPayload } from "../types/JwtPayload.type";
import { LocalStorageKey } from "../types/localStorageEnum";
import { Roles } from "../types/role.enum";
import { User } from "../types/User.type";
import { getHeaderWithAuth } from "./utils";
export const sendMagicLink = async (email: string, token: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/send-magic-link`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, captchaToken: token }),
    }
  );

  if (res.status >= 400) {
    throw new Error("Can not sent connection email");
  }
};

export const fetchLogin = async (token: string): Promise<JwtPayload> => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/login/${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status === 200) {
    const data: AccessToken = await res.json();

    localStorage.setItem(
      LocalStorageKey.KEY_TOKEN_LOCALSTORAGE,
      data.access_token
    );
    const jwtData: JwtPayload = jwt_decode(data.access_token);
    localStorage.setItem(LocalStorageKey.USER_ID, jwtData.id);
    localStorage.setItem(LocalStorageKey.PROFILE, jwtData.role);
    localStorage.setItem(
      LocalStorageKey.BI_CONNECTED,
      jwtData.biConnected + ""
    );
    return jwtData;
  } else {
    throw new Error("User not found");
  }
};

export async function fetchAllUsers(): Promise<User[]> {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/list`, {
    headers: getHeaderWithAuth(),
  });
  if (res.status === 200) {
    const data = await res.json();
    return data;
  } else {
    throw new Error("Can not get user list");
  }
}

export async function fetchClientForUser(userId: string): Promise<User[]> {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/${userId}/client/list`,
    {
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status === 200) {
    const data = await res.json();
    return data;
  } else {
    throw new Error("Can not client list");
  }
}

export async function postCreateNewUser(
  type: Roles,
  email: string,
  userId: string
) {
  const body: { email: string; userId?: string; isAdmin?: boolean } = {
    email,
  };
  if (type === Roles.CLIENT) {
    body.userId = userId;
  }
  if (type === Roles.ADMIN) {
    body.isAdmin = true;
  }
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/${
      type === Roles.CLIENT ? "client" : ""
    }`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: getHeaderWithAuth(),
    }
  );
  if (res.status >= 400) {
    throw new Error("can not create user");
  }
}

export const fetchToken = async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/token`);
  const data: AccessToken = await res.json();
  localStorage.setItem(
    LocalStorageKey.KEY_TOKEN_LOCALSTORAGE,
    data.access_token
  );
};

export const getUserInfo = async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user`, {
    headers: getHeaderWithAuth(),
  });
  const data = await res.json();
  return data;
};

export const getMe = async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/me`, {
    headers: getHeaderWithAuth(),
  });
  const data: JwtPayload = await res.json();

  if (
    res.status >= 400 &&
    localStorage.getItem(LocalStorageKey.KEY_TOKEN_LOCALSTORAGE)
  ) {
    localStorage.removeItem(LocalStorageKey.KEY_TOKEN_LOCALSTORAGE);
    localStorage.removeItem(LocalStorageKey.USER_ID);
    localStorage.removeItem(LocalStorageKey.PROFILE);
    window.location.reload();
    //throw new Error("can not get user");
  }
  if (res.status >= 400) {
    return null;
  }

  return data;
};

export const getClient = async (clientId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/client/${clientId}`,
    {
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    return null;
  }

  return await res.json();
};
export const getClientCgu = async (clientId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/client/${clientId}/cgu`,
    {
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    return null;
  }

  return await res.json();
};

export const getUser = async (userId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/${userId}`,
    {
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    return null;
  }

  return await res.json();
};

export const getClientWithDocTypes = async (clientId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/client/${clientId}?documentTypes=true`,
    {
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    return null;
  }

  return await res.json();
};

export const updateUser = async (user: User) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/${user.id}`,
    {
      method: "PATCH",
      headers: getHeaderWithAuth(),

      body: JSON.stringify({ ...user }),
    }
  );

  if (res.status >= 400) {
    return null;
  }

  return await res.json();
};

export const updateClientCgu = async (clientId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/client/${clientId}/cgu`,
    {
      method: "PATCH",
      headers: getHeaderWithAuth(),
      body: JSON.stringify({}),
    }
  );

  if (res.status >= 400) {
    return null;
  }

  return await res.json();
};

export const getConnectionTokenForUser = async (userId: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/user/${userId}/impersonate`,
    {
      method: "GET",
      headers: getHeaderWithAuth(),
    }
  );

  if (res.status >= 400) {
    return null;
  }

  return await res.json();
};
