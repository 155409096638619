import {
  forwardRef,
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useState,
} from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  SelectElement,
  TextFieldElement,
  UseFormStateReturn,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import {
  AccommodationType,
  Civility,
  ContractType,
  CSP,
  LiberalType,
} from "../../types/caseForm.type";
import CountrySelect from "../common/countrySelect";
import "../../styles/case/caseForm.scss";
import PhoneInput from "../common/phoneInput";

export default function BorrowerForm(props: {
  index: number;
  livingTogether?: boolean;
  csp?: CSP;
  firstName?: string;
  lastName?: string;
}) {
  const { t } = useTranslation();
  const [csp, setCsp] = useState<CSP>();
  const [firstName, setFirstname] = useState<string>("");
  const [lastName, setLastname] = useState<string>("");
  useEffect(() => {
    setCsp(props.csp);
  }, [props.csp]);

  useEffect(() => {
    setFirstname(props.firstName || "");
    setLastname(props.lastName || "");
  }, [props.firstName, props.lastName]);

  const noContractCsp = [
    CSP.CRAFTING_MERCHANT,
    CSP.AUTOENTREPRENEUR,
    CSP.FARMER,
    CSP.LIBERAL,
    CSP.RETIRED,
    CSP.UNEMPLOYED,
    CSP.SEFLEMPLOYED_WORKER,
  ];
  const cspWithsiret = [
    CSP.CRAFTING_MERCHANT,
    CSP.AUTOENTREPRENEUR,
    CSP.FARMER,
    CSP.LIBERAL,
    CSP.SEFLEMPLOYED_WORKER,
    CSP.ENTREPRENEUR,
  ];
  return (
    <div className="borrower-container">
      <h3 className="borrower-title">
        {firstName || lastName
          ? firstName + " " + lastName
          : t("case.form-borrower") + " " + (props.index + 1)}
      </h3>

      <Controller
        defaultValue={"PHYSICAL"}
        render={function ({
          field,
          fieldState,
          formState,
        }: {
          field: ControllerRenderProps<FieldValues, string>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<FieldValues>;
        }): ReactElement<any, string | JSXElementConstructor<any>> {
          return (
            <SelectElement
              options={[{ id: "PHYSICAL", label: t("case.form-physical") }]}
              defaultValue={"PHYSICAL"}
              className="form-input left"
              name={`borrowers.${props.index}.type`}
              label={t("case.form-physical")}
              validation={{ required: true }}
              inputProps={{
                sx: { textAlign: "left" },
              }}
            />
          );
        }}
        name={`borrowers.${props.index}.type`}
      ></Controller>

      {!props.livingTogether && (
        <SelectElement
          options={Object.keys(AccommodationType).map((key) => ({
            id: key,
            label: t("case.accommodation-type-" + key),
          }))}
          className="form-input"
          name={`borrowers.${props.index}.accommodationType`}
          label={t("case.form-accommodation")}
          validation={{ required: true }}
          inputProps={{
            sx: { textAlign: "left" },
          }}
        />
      )}
      <CountrySelect index={props.index} />
      <SelectElement
        options={Object.keys(Civility).map((key) => ({
          id: key,
          label: t("case.borrower-civility-" + key),
        }))}
        className="form-input"
        name={`borrowers.${props.index}.civility`}
        label={t("case.form-civility")}
        validation={{ required: true }}
        inputProps={{
          sx: { textAlign: "left" },
        }}
      />
      <TextFieldElement
        className="form-input left"
        name={`borrowers.${props.index}.firstName`}
        label={t("case.form-firstname")}
        validation={{ required: true }}
        onChange={(value) => {
          setFirstname(value.target.value);
        }}
      />
      <TextFieldElement
        className="form-input"
        name={`borrowers.${props.index}.lastName`}
        label={t("case.form-lastname")}
        validation={{ required: true }}
        onChange={(value) => {
          setLastname(value.target.value);
        }}
      />
      <TextFieldElement
        className="form-input left"
        name={`borrowers.${props.index}.email`}
        label={t("case.form-email")}
        type={"email"}
        validation={{ required: true }}
      />
      <PhoneInput index={props.index} />

      <SelectElement
        options={Object.keys(CSP).map((key) => ({
          id: key,
          label: t("case.borrower-csp-" + key),
        }))}
        className="form-input left"
        name={`borrowers.${props.index}.csp`}
        label={t("case.form-csp")}
        validation={{ required: true }}
        onChange={(value) => {
          setCsp(value);
        }}
        inputProps={{
          sx: { textAlign: "left" },
        }}
      />
      {csp && !noContractCsp.includes(csp) && (
        <SelectElement
          options={Object.keys(ContractType).map((key) => ({
            id: key,
            label: t("case.borrower-contract-" + key),
          }))}
          className="form-input"
          name={`borrowers.${props.index}.contractType`}
          label={t("case.form-contract")}
          validation={{ required: true }}
          inputProps={{
            sx: { textAlign: "left" },
          }}
        />
      )}
      {csp && csp === CSP.LIBERAL && (
        <SelectElement
          options={Object.keys(LiberalType).map((key) => ({
            id: key,
            label: t("case.borrower-liberal-" + key),
          }))}
          className="form-input"
          name={`borrowers.${props.index}.liberalType`}
          label={t("case.form-liberal-type")}
          validation={{ required: true }}
          inputProps={{
            sx: { textAlign: "left" },
          }}
        />
      )}
      {csp && cspWithsiret.includes(csp) && (
        <TextFieldElement
          className="form-input"
          name={`borrowers.${props.index}.sirenSiret`}
          label={t("case.form-siren-siret")}
          validation={{ required: true }}
        />
      )}
    </div>
  );
}
