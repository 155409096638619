import { Document, DocumentType } from "./documentList";
import { User } from "./User.type";

export enum OperationType {
  OLD_PURCHASE_WITH_WORK = "OLD_PURCHASE_WITH_WORK",
  OLD_PURCHASE_WITHOUT_WORK = "OLD_PURCHASE_WITHOUT_WORK",
  NEW_PURCHASE_TURNKEY = "NEW_PURCHASE_TURNKEY",
  NEW_PURCHASE_VEFA = "NEW_PURCHASE_VEFA",
  OTHER = "OTHER",
  BUILDING = "BUILDING",
  FIELD = "FIELD",
  WORKS = "WORKS",
}

export enum UseType {
  MAIN_HOUSE = "MAIN_HOUSE",
  RENTAL_RESIDENCE = "RENTAL_RESIDENCE",
  SECOND_HOUSE = "SECOND_HOUSE",
}

export enum BorrowerLink {
  MARRIED = "MARRIED",
  PACS = "PACS",
  LIVING_TOGETHER = "LIVING_TOGETHER",
  UNDIVIDED = "UNDIVIDED",
  OTHER = "OTHER",
}

export enum BorrowerSituation {
  DIVORSED = "DIVORSED",
  MARRIED = "MARRIED",
  PACS = "PACS",
  LIVING_TOGETHER = "LIVING_TOGETHER",
  SEPARETED = "SEPARETED",
  WIDOWER = "WIDOWER",
  ALONE = "ALONE",
}

export enum AccommodationType {
  OWNER = "OWNER",
  TENANT = "TENANT",
  HOSTED = "HOSTED",
}

export enum Civility {
  MR = "MR",
  MS = "MS",
}

export enum CSP {
  CRAFTING_MERCHANT = "CRAFTING_MERCHANT",
  AUTOENTREPRENEUR = "AUTOENTREPRENEUR",
  OTHER = "OTHER",
  EXECUTIVE = "EXECUTIVE",
  ENTREPRENEUR = "ENTREPRENEUR",
  PARENTAL_LEAVE = "PARENTAL_LEAVE",
  FARMER = "FARMER",
  CIVIL_SERVANT = "CIVIL_SERVANT",
  LIBERAL = "LIBERAL",
  RETIRED = "RETIRED",
  EMPLOYEE = "EMPLOYEE",
  UNEMPLOYED = "UNEMPLOYED",
  SEFLEMPLOYED_WORKER = "SEFLEMPLOYED_WORKER", // TNS => travaileur non salarier ?
}

export enum ContractType {
  PERMANENT_CONTRACT = "PERMANENT_CONTRACT",
  CDD = "CDD",
  CONTRACTUAL = "CONTRACTUAL",
  TEMP_WORKER = "TEMP_WORKER",
  INTERN = "INTERN",
  INCUMBENT = "INCUMBENT",
}

export enum LiberalType {
  HEALTH = "HEALTH",
  LEGAL = "LEGAL",
  FINANCIAL = "FINANCIAL",
  OTHER = "OTHER",
}

export type Borrower = {
  id?: string;
  email: string;
  type: ClientTypes;
  accommodationType: AccommodationType;
  nationality: string;
  civility: Civility;
  firstName: string;
  lastName: string;
  phone: string;
  status: BorrowerSituation | BorrowerLink;
  nbChildren: number;
  csp: CSP;
  contractType: ContractType;
  sirenSiret: string;
  liberalType: string;
  clientDocumentTypes?: ClientDocumentType[];
  case?: Case;
  isMaster: boolean;
};

export enum ClientDocumentTypeStatus {
  SUBMITTED = "SUBMITTED",
  PENDING = "PENDING",
  VALIDATED = "VALIDATED",
  UNSET = "UNSET",
  REFUSED = "REFUSED",
  UPLOADING = "UPLOADING",
}

export enum AdvencementStatus {
  DOCUMENTATION = "DOCUMENTATION",
  UNDER_REVIEW = "UNDER_REVIEW",
}

export enum CaseStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}
export type Case = {
  id: string;

  mandateNumber: string;

  reference: string;

  operationType: OperationType;

  use: UseType;

  nbBorrower: number;
  nbCommonChildren: number;
  borrowers: Borrower[];

  user: User;

  advencementStatus: AdvencementStatus;
  caseStatus: CaseStatus;
  userId: string;
  // part with 2 borrowers

  borrowersLink: BorrowerLink;

  livingTogether: boolean;
  // part with 2 borrowers and living together

  accommodationType: AccommodationType;
  // part with 1 borrower

  borrowerSituation: BorrowerSituation;

  acessToken: string;

  clientDocumentTypes: ClientDocumentType[];
};

export type ClientDocumentType = {
  document: Document;
  id: string;
  clientId: string;
  documentTypeId: string;
  caseId: string;
  budgetInsightConnectionIds: string[];
  status: ClientDocumentTypeStatus;
  documentType?: DocumentType;
  client?: Borrower;
};

export type BudgetInsightConnector = {
  id: string;
  name: string;
  color: string;
  slug: string;
  uuid: string;
  logo: string;
};
