import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Account } from "../../types/accountsList.type";

import { fetchAccountsList, fetchMyAccountsList } from "../../services/bi.api";
import "../../styles/accountsList.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { LocalStorageKey } from "../../types/localStorageEnum";
import { Roles } from "../../types/role.enum";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { routeType } from "../../routes";
import Loader from "../../components/common/loading";
import { useTranslation } from "react-i18next";
import CustomDataGrid from "../../components/common/customDataGrid";

function AccountsList() {
  const { t } = useTranslation();

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(false);

  let params = useParams();
  const [userId, setUserId] = useState<any>(params.userId);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const profile = localStorage.getItem(LocalStorageKey.PROFILE);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userName } = state || {};
  const getColumns = (userId: string): GridColDef[] => {
    return [
      {
        field: "connection",
        headerName: t("accounts.bank") as string,
        flex: 1,
        headerClassName: "table-header",
        valueGetter: (params) => {
          if (params.row.connection) {
            if (params.row.connection.connector) {
              return params.row.connection.connector.name;
            }
          }
        },
      },
      {
        field: "name",
        headerName: t("accounts.name") as string,
      },
      {
        field: "balance",
        headerName: t("accounts.balance") as string,
        flex: 1,
        headerClassName: "table-header",
        valueGetter: (params: GridValueGetterParams) => {
          return new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: params.row.currency.id,
            maximumFractionDigits: 2,
          }).format(params.row.balance);
        },
      },
      {
        field: "type",
        headerName: t("accounts.type") as string,
      },
      {
        field: "iban",
        headerName: t("accounts.iban") as string,
      },
      {
        field: "id",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => {
          return params;
        },
        renderCell: (params: GridRenderCellParams<string>) => {
          if (params.row) {
            return (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(routeType.transaction, {
                    state: {
                      userId,
                      accountId: params.row.id,
                      accountName: params.row.name,
                    },
                  });
                }}
              >
                {t("accounts.transactions")}
              </Button>
            );
          }
        },
      },
    ];
  };

  useEffect(() => {
    feedAccountsList();
  }, []);
  const feedAccountsList = async () => {
    setLoading(true);
    let userIdFromLocal = null;
    if (!userId) {
      userIdFromLocal = localStorage.getItem(LocalStorageKey.USER_ID);
      setUserId(userIdFromLocal);
      if (profile === Roles.CLIENT && !userId && !userIdFromLocal) {
        setLoading(false);
        enqueueSnackbar(t("errors.no-users-for-accounts"), {
          variant: "error",
        });
        return;
      }
    }
    try {
      const goodUserId = userId || userIdFromLocal;
      setColumns(getColumns(goodUserId));
      let accountsList;
      if (profile === Roles.CLIENT) {
        accountsList = await fetchMyAccountsList();
      } else {
        accountsList = await fetchAccountsList(goodUserId);
      }
      setAccounts(accountsList.accounts);
    } catch (err) {
      enqueueSnackbar(t("errors.fail-fetch-accounts"), { variant: "error" });
      navigate(routeType.home);
    }
    setLoading(false);
  };

  return (
    <div>
      {userName ? (
        <h2>{t("accounts.user-title", { userName })}</h2>
      ) : (
        <h2>{t("accounts.client-title")}</h2>
      )}
      {loading ? (
        <Loader />
      ) : userId ? (
        <CustomDataGrid rows={accounts} columns={columns} />
      ) : (
        <div>{t("accounts.no-user")}</div>
      )}
    </div>
  );
}

export default AccountsList;
