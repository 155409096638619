import {
  Box,
  BoxProps,
  Button,
  ButtonBase,
  Grid,
  Tooltip,
  Typography,
  TypographyProps,
  lighten,
  useTheme,
} from "@mui/material";
import { useState, useEffect, Component } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Borrower,
  Case,
  ClientDocumentType,
  ClientDocumentTypeStatus,
} from "../../types/caseForm.type";

import React from "react";
import "../../styles/case/caseValidation.scss";
import { ReactComponent as CommonFilesIcon } from "../../resources/icons/restitution/common-files.svg";
import { ReactComponent as PdfFileIcon } from "../../resources/icons/restitution/pdf-file.svg";
import Loader from "../common/loading";
import { ReactComponent as NotYetValidatedIcon } from "../../resources/icons/restitution/circle-validate-white.svg";
import { ReactComponent as ValidatedIcon } from "../../resources/icons/restitution/validated-white.svg";
import { ReactComponent as CancelIcon } from "../../resources/icons/cross.svg";

import { MultiStateDisplay } from "./MultiStateDisplay";
import {
  BudgetInsightConnection,
  BudgetInsightDocument,
  BudgetInsightSubscription,
  DocumentDisplayStatus,
  SubscriptionDisplayStatus,
} from "../../types/budgetInsight.type";
import { orderBy, uniq } from "lodash";
import {
  getBudgetInsightConnectionsForConnectionIds,
  getBudgetInsightDocumentSignedUrl,
  updateBudgetInsightDocumentsWithClientDocumentTypes,
} from "../../services/bi.api";
import { format } from "date-fns";
import BudgetInsightDocumentSelectBorrower from "./budgetInsightDocumentSelectBorrower";
import { AntSwitch } from "../common/switchCustom";
import BudgetInsightHeaderBorrowers from "./budgetInsightHeaderBorrowers";
import { DocumentType } from "../../types/documentList";

export enum DocumentTypeToManage {
  PROPERTY_TAX = "PROPERTY_TAX",
  TAX_RETURN = "TAX_RETURN",
  HOUSING_TAX = "HOUSING_TAX",
}
export type DocumentBorrowers = {
  [key: string]: {
    borrowers: string[];
    displayStatus: DocumentDisplayStatus;
    filteredDisplayed: boolean;
  };
};

export const mainBoxProps: BoxProps = {
  textAlign: "left",
  bgcolor: "primary.main",
  borderRadius: "4px",
  padding: "6px 12px",
  marginBottom: 1,
};

export const mainBoxTypoProps: TypographyProps = {
  fontWeight: "bold",
  color: "primary.contrastText",
};

export default function BudgetInsightTaxSelectDocument(props: {
  cdt: ClientDocumentType;
  aCase: Case;
  onSubmit: Function;
  onCancel: Function;
}) {
  const theme = useTheme();

  const mainBoxLightProps: BoxProps = {
    ...mainBoxProps,
    bgcolor: lighten(theme.palette.primary.main, 0.2),
  };
  const { t } = useTranslation();
  const params = useParams();
  const [yearsToDisplay, setYearsToDisplay] = useState<number[]>([]);
  const [documentsBorrowers, setDocumentsBorrowers] =
    useState<DocumentBorrowers>({});
  const [clientDocumentTypesToUpdate, setClientDocumentTypesToUpdate] =
    useState<ClientDocumentType[]>();
  const [budgetInsightConnections, setBudgetInsightConnections] =
    useState<BudgetInsightConnection[]>();
  const [budgetInsightDocuments, setBudgetInsightDocuments] = useState<
    BudgetInsightDocument[]
  >([]);
  const [selectedBorrowers, setSelectedBorrowers] = useState<string[]>([]);
  const [validatedBorrowers, setValidatedBorrowers] = useState<string[]>([]);
  const [hasCommonFeature, setHasCommonFeature] = useState(false);
  const [displayHiddenDocs, setDisplayHiddenDocs] = useState(false);

  useEffect(() => {
    (async () => {
      const cdtsToUpdate = props.aCase.clientDocumentTypes.filter((cdtTmp) =>
        ["PROPERTY_TAX", "TAX_RETURN", "HOUSING_TAX"].includes(
          cdtTmp.documentType?.name || ""
        )
      );
      setClientDocumentTypesToUpdate(cdtsToUpdate);

      setHasCommonFeature(cdtsToUpdate.find((c) => !c.clientId) !== undefined);
      const bIConnectionIds = uniq(
        cdtsToUpdate
          .flatMap((x) => x.budgetInsightConnectionIds)
          .filter(Boolean)
      );
      let connections = await getBudgetInsightConnectionsForConnectionIds(
        props.aCase.id,
        bIConnectionIds
      );
      const docBor: DocumentBorrowers = {};
      connections = connections.filter(
        (c) => c.subscriptions && c.subscriptions?.length > 0
      );

      let allDocuments: BudgetInsightDocument[] = [];

      connections.forEach((c) => {
        c.subscriptions!.filter(
          (s) => s.documents && s.documents?.length > 0
        ).forEach((s) => {
          s.documents!.forEach((d) => {
            docBor[d.id] = {
              filteredDisplayed: true,
              displayStatus: d.displayStatus,
              borrowers: d.clientDocumentType
                ? d.clientDocumentType.clientId
                  ? [d.clientDocumentType.clientId]
                  : props.aCase.borrowers.map((b) => b.id!)
                : [],
            };
            allDocuments.push(d);
          });
        });
      });
      const selectedByDefault = props.aCase.borrowers.map((b) => b.id!);
      if (selectedByDefault.length > 1) {
        selectedByDefault.push("both");
      }
      setSelectedBorrowers(selectedByDefault);
      const initialValidatedBorrowers: string[] = [];
      cdtsToUpdate
        .filter((c) => c.status === ClientDocumentTypeStatus.VALIDATED)
        .forEach((cdt) => {
          if (cdt.clientId) {
            initialValidatedBorrowers.push(cdt.clientId);
          } else {
            initialValidatedBorrowers.push("both");
          }
        });
      setValidatedBorrowers(initialValidatedBorrowers);

      setDocumentsBorrowers(docBor);

      allDocuments.sort((a, b) => {
        const dateA = new Date(a.effectiveDate);
        const dateB = new Date(b.effectiveDate);
        return dateA.getTime() - dateB.getTime();
      });

      if (allDocuments.length > 0) {
        const dates: number[] = [];
        let oldDate = new Date(allDocuments[0].effectiveDate).getFullYear();
        const newDate = new Date(
          allDocuments[allDocuments.length - 1].effectiveDate
        ).getFullYear();
        while (oldDate <= newDate) {
          dates.push(oldDate);
          oldDate++;
        }
        setYearsToDisplay(dates);
      }

      setBudgetInsightDocuments(allDocuments);

      setBudgetInsightConnections(connections);
    })();
  }, [params, props.aCase, props.cdt]);

  const saveAllData = async () => {
    const cdtToUpdate = clientDocumentTypesToUpdate
      ? clientDocumentTypesToUpdate.map((cdt) => {
          let status: ClientDocumentTypeStatus;

          if (cdt.clientId) {
            status = validatedBorrowers.includes(cdt.clientId)
              ? ClientDocumentTypeStatus.VALIDATED
              : ClientDocumentTypeStatus.SUBMITTED;
          } else {
            // not clientId --> its both borrowers
            status = validatedBorrowers.includes("both")
              ? ClientDocumentTypeStatus.VALIDATED
              : ClientDocumentTypeStatus.SUBMITTED;
          }

          return { id: cdt.id, status };
        })
      : [];

    const docsFilteredAndPrepared = budgetInsightDocuments
      .filter((d) => documentsBorrowers[d.id] !== undefined)
      .map((d) => {
        let cdtToAssign: string | null;
        if (documentsBorrowers[d.id].borrowers.length === 2) {
          cdtToAssign =
            clientDocumentTypesToUpdate?.find((cdt) => !cdt.clientId)?.id ||
            null;
        } else {
          if (documentsBorrowers[d.id].borrowers.length === 0) {
            cdtToAssign = null;
          } else {
            cdtToAssign =
              clientDocumentTypesToUpdate?.find(
                (cdt) => cdt.clientId === documentsBorrowers[d.id].borrowers[0]
              )?.id || null;
          }
        }

        return {
          id: d!.id,
          cdtId: cdtToAssign,
          displayStatus: documentsBorrowers[d.id].displayStatus,
        };
      });
    await updateBudgetInsightDocumentsWithClientDocumentTypes(
      props.aCase.id,
      docsFilteredAndPrepared,
      cdtToUpdate
    );
    props.onSubmit();
  };

  const toggleDisplayDocument = (docId: string) => {
    if (!documentsBorrowers[docId]) {
      return;
    }
    documentsBorrowers[docId].displayStatus =
      documentsBorrowers[docId].displayStatus === DocumentDisplayStatus.SHOWN
        ? DocumentDisplayStatus.HIDDEN
        : DocumentDisplayStatus.SHOWN;

    const hiddenNumber = Object.entries(documentsBorrowers).filter(
      ([key, doc]) => doc.displayStatus === DocumentDisplayStatus.HIDDEN
    ).length;
    if (hiddenNumber === 0) {
      setDisplayHiddenDocs(false);
    }
    setDocumentsBorrowers({ ...documentsBorrowers });
  };

  const toggleSelectedBorrower = (borrowerId: string) => {
    const selectedNew = selectedBorrowers.includes(borrowerId)
      ? selectedBorrowers.filter((sb) => sb !== borrowerId)
      : selectedBorrowers.concat([borrowerId]);
    setSelectedBorrowers(selectedNew);

    if (selectedNew.length === 0) {
      for (const key in documentsBorrowers) {
        documentsBorrowers[key].filteredDisplayed = false;
      }
    }
    if (selectedNew.length === 3) {
      for (const key in documentsBorrowers) {
        documentsBorrowers[key].filteredDisplayed = true;
      }
    }
    if (selectedNew.length > 0 && selectedNew.length < 3) {
      for (const key in documentsBorrowers) {
        let shouldBeDisplayed = false;
        for (const selected of selectedNew) {
          if (selected === "both") {
            shouldBeDisplayed =
              shouldBeDisplayed ||
              documentsBorrowers[key].borrowers.length === 2;
          } else {
            shouldBeDisplayed =
              shouldBeDisplayed ||
              (documentsBorrowers[key].borrowers.length === 1 &&
                documentsBorrowers[key].borrowers.includes(selected));
          }
        }
        documentsBorrowers[key].filteredDisplayed = shouldBeDisplayed;
      }
    }
    setDocumentsBorrowers(documentsBorrowers);
  };
  const toggleValidatedBorrower = (borrowerId: string) => {
    const selectedNew = validatedBorrowers.includes(borrowerId)
      ? validatedBorrowers.filter((sb) => sb !== borrowerId)
      : validatedBorrowers.concat([borrowerId]);
    setValidatedBorrowers(selectedNew);
  };

  const openDocUrl = async (docId: string) => {
    const url = await getBudgetInsightDocumentSignedUrl(docId);
    window.open(url, "_blank", "fullscreen=yes");
  };

  const displayDocumentsForType = (docType: DocumentTypeToManage) => {
    let documents: BudgetInsightDocument[] = [];
    let titleToDisplay = "";
    let prefix = "";
    switch (docType) {
      case DocumentTypeToManage.HOUSING_TAX:
        documents = budgetInsightDocuments.filter((d) =>
          d.name.includes("habitation")
        );
        titleToDisplay = "header.popup-validation-tax-title-housing-tax";
        prefix = "TH-";
        break;
      case DocumentTypeToManage.TAX_RETURN:
        documents = budgetInsightDocuments.filter((d) =>
          d.name.includes("revenus")
        );
        prefix = "R-";
        break;
      case DocumentTypeToManage.PROPERTY_TAX:
        documents = budgetInsightDocuments.filter((d) =>
          d.name.includes("taxes foncières")
        );
        titleToDisplay = "header.popup-validation-tax-title-property-tax";
        prefix = "TF-";
        break;

      default:
        break;
    }

    if (documents.length === 0) {
      return <></>;
    }
    return (
      <>
        {titleToDisplay !== "" && (
          <Grid
            marginTop={1}
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            spacing={4}
          >
            <Grid item xs={12 - yearsToDisplay.length * 2}>
              <Box {...mainBoxLightProps}>
                <Typography {...mainBoxTypoProps}>
                  {t(titleToDisplay)}
                </Typography>
              </Box>
            </Grid>
            {yearsToDisplay.map((year) => (
              <Grid key={`general-year-${year}`} item xs={2}></Grid>
            ))}
          </Grid>
        )}
        {documents
          ?.filter(
            (doc) =>
              documentsBorrowers[doc.id] &&
              documentsBorrowers[doc.id].filteredDisplayed &&
              (displayHiddenDocs
                ? true
                : documentsBorrowers[doc.id].displayStatus ===
                  DocumentDisplayStatus.SHOWN)
          )
          .map((doc, index) => (
            <Grid
              key={"sub-" + doc.id}
              container
              justifyContent={"flex-start"}
              textAlign={"left"}
              alignItems={"center"}
              spacing={2}
              marginTop={1}
              padding={0}
              className={
                documentsBorrowers[doc.id].displayStatus ===
                DocumentDisplayStatus.HIDDEN
                  ? "hidden-doc-line"
                  : ""
              }
            >
              <Grid
                container
                justifyContent={"flex-start"}
                textAlign={"left"}
                alignItems={"center"}
                wrap="nowrap"
                item
                xs={12 - yearsToDisplay.length * 2}
              >
                {documentsBorrowers[doc.id].displayStatus ===
                DocumentDisplayStatus.SHOWN ? (
                  <>
                    <BudgetInsightDocumentSelectBorrower
                      key={`bor-container-${doc.id}`}
                      borrowers={props.aCase.borrowers}
                      selected={documentsBorrowers[doc.id].borrowers}
                      hasCommonFeature={hasCommonFeature}
                      updateSelected={(selected: string[]) => {
                        documentsBorrowers[doc.id].borrowers = selected;
                        setDocumentsBorrowers(documentsBorrowers);
                      }}
                    />

                    <Box
                      marginRight={1}
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleDisplayDocument(doc.id)}
                    >
                      <CancelIcon />
                    </Box>
                  </>
                ) : (
                  <Box marginRight={1}>
                    <Button
                      onClick={() => {
                        toggleDisplayDocument(doc.id);
                      }}
                      variant="outlined"
                    >
                      {t("case.validation-btn-show")}
                    </Button>
                  </Box>
                )}
                <Tooltip title={doc.name}>
                  <Typography
                    noWrap={true}
                    fontStyle={
                      documentsBorrowers[doc.id].displayStatus ===
                      DocumentDisplayStatus.HIDDEN
                        ? "italic"
                        : ""
                    }
                  >
                    {doc.name}
                  </Typography>
                </Tooltip>
              </Grid>
              {yearsToDisplay.map((year) => (
                <Grid
                  key={`year-${doc.id}-${year}`}
                  item
                  xs={2}
                  textAlign={"center"}
                >
                  {new Date(doc.effectiveDate).getFullYear() === year && (
                    <>
                      <ButtonBase
                        onClick={() => {
                          openDocUrl(doc.id);
                        }}
                      >
                        <PdfFileIcon />
                      </ButtonBase>
                      <br />
                      <Typography
                        fontStyle={
                          documentsBorrowers[doc.id].displayStatus ===
                          DocumentDisplayStatus.HIDDEN
                            ? "italic"
                            : ""
                        }
                      >
                        {prefix + format(new Date(doc.effectiveDate), "yyyy")}
                      </Typography>
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
          ))}
      </>
    );
  };
  if (!props.aCase) {
    return <></>;
  }

  const hiddenDocs = Object.entries(documentsBorrowers).filter(
    ([key, doc]) => doc.displayStatus === DocumentDisplayStatus.HIDDEN
  );
  return (
    <>
      {/* Start Header Components */}

      <Grid container justifyContent={"flex-start"} spacing={2}>
        <Grid item xs={5} sm={4}>
          <Box {...mainBoxProps} marginBottom={5}>
            <Typography {...mainBoxTypoProps} textTransform={"uppercase"}>
              {t("header.popup-validation-tax")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <BudgetInsightHeaderBorrowers
        aCase={props.aCase}
        selectedBorrowers={selectedBorrowers}
        validatedBorrowers={validatedBorrowers}
        toggleValidate={(borrowerId: string) => {
          toggleValidatedBorrower(borrowerId);
        }}
        toggleFilter={(borrowerId: string) => {
          toggleSelectedBorrower(borrowerId);
        }}
        hasCommonFeature={hasCommonFeature}
      />

      {/* Start Data Component */}
      <Grid
        marginTop={1}
        container
        justifyContent={"flex-start"}
        alignItems={"center"}
        spacing={4}
      >
        <Grid item xs={12 - yearsToDisplay.length * 2}>
          <Box {...mainBoxLightProps}>
            <Typography {...mainBoxTypoProps}>
              {t("header.popup-validation-tax-title-tax-return")}
            </Typography>
          </Box>
        </Grid>
        {yearsToDisplay.map((year) => (
          <Grid key={`general-year-${year}`} item xs={2}>
            <Box {...mainBoxLightProps}>
              <Typography {...mainBoxTypoProps}>{year}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {displayDocumentsForType(DocumentTypeToManage.TAX_RETURN)}

      {displayDocumentsForType(DocumentTypeToManage.PROPERTY_TAX)}

      {displayDocumentsForType(DocumentTypeToManage.HOUSING_TAX)}

      {/* End Data Component */}
      <Grid container paddingTop={3} justifyContent={"flex-end"}>
        <Grid item xs={8} textAlign={"left"} container alignItems={"center"}>
          {hiddenDocs.length > 0 && (
            <>
              <Typography paddingRight={2}>
                {t("case.validation-hidden-docs")} : {hiddenDocs.length}
              </Typography>
              <Button
                onClick={() => {
                  setDisplayHiddenDocs(!displayHiddenDocs);
                }}
                variant="outlined"
              >
                {displayHiddenDocs
                  ? t("case.validation-btn-hide-docs")
                  : t("case.validation-btn-display-docs")}
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              props.onCancel();
            }}
            variant="outlined"
          >
            {t("global.cancel")}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={async () => {
              saveAllData();
            }}
            variant="contained"
          >
            {t("global.confirm")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
