import {
  Box,
  Grid,
  Button,
  Modal,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useTheme,
  Typography,
} from "@mui/material";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
  getCaseWithDocTypeForClient,
  getCaseWithDocTypeForGroupAndClient,
} from "../../services/case.api";
import {
  Case,
  ClientDocumentType,
  ClientDocumentTypeStatus,
} from "../../types/caseForm.type";
import { ReactComponent as ToProcessIcon } from "../../resources/icons/toProcess.svg";
import { ReactComponent as Processed } from "../../resources/icons/processed.svg";
import "../../styles/client/client.scss";
import React from "react";
import { clearBackContext, customBackContext } from "../../slice/back.slice";
import { routeType } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { Source } from "../../types/documentList";
import Loader from "../../components/common/loading";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getGroupDocumentTypes } from "../../services/utils-cdt";
import ClientBi from "./clientBi";
import { ClientDocumentBank } from "./clientDocumentBank";
import { selectUser } from "../../slice/login.slice";
import { ClientDocumentTaxAndProof } from "./clientDocumentTaxAndProof";
import { SessionStorageKey } from "../../types/sessionStorageEnum";

export function ClientDocumentTypeList(props: { isCoBorrower: boolean }) {
  const params = useParams();
  const [aCase, setCase] = useState<Case>();
  const [cdts, setCdts] = useState<ClientDocumentType[]>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openIdentitySelectModal, setOpenIdentitySelectModal] = useState(false);
  const theme = useTheme();
  const jwtPayload = useSelector(selectUser);
  const isFirstUse = sessionStorage.getItem(SessionStorageKey.FIRST_USE);
  useEffect(() => {
    (async () => {
      if (params.id && params.groupId) {
        const myCase = await getCaseWithDocTypeForClient(params.id, false);
        setCase(myCase);
        const myCdts = getGroupDocumentTypes(
          params.groupId,
          myCase.clientDocumentTypes
        );
        setCdts(
          orderBy(
            myCdts.map((cdt) => {
              cdt.documentType!.trasnslatedName = t(
                `doc-type.${cdt.documentType?.name}`
              );
              return cdt;
            }),
            (x) => x.documentType?.trasnslatedName,
            "asc"
          )
        );
      }
    })();
  }, [params.groupId, params.id, t]);

  // useEffect(() => {
  //   dispatch(
  //     customBackContext({
  //       route: routeType.client + `/${params.id}`,
  //       disabled: false,
  //     })
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // //unmonted backContext button
  // useEffect(() => {
  //   return () => {
  //     dispatch(clearBackContext());
  //   };
  // }, []);

  const generateDocTypeList = (selectedCdts: ClientDocumentType[]) => {
    return selectedCdts.map((cdt) => {
      return (
        <Grid
          item
          container
          direction={"row"}
          key={`cdt-display-${cdt.id}`}
          justifyContent={"space-between"}
          className="group-row"
          py={2}
          wrap="nowrap"
        >
          <Grid item flexGrow={1} zeroMinWidth>
            {!isFirstUse &&
              (cdt.status === ClientDocumentTypeStatus.UNSET ||
                cdt.status === ClientDocumentTypeStatus.REFUSED) && (
                <Box
                  position={"absolute"}
                  color={theme.palette.error.main}
                  marginLeft={-1.5}
                >
                  *
                </Box>
              )}
            <Typography textAlign={"left"} noWrap>
              {t(`doc-type.${cdt.documentType?.name}`)}
            </Typography>
          </Grid>
          <Grid item>
            <Box
              sx={{ cursor: "pointer" }}
              width={"min-content"}
              onClick={() => {
                if (
                  cdt.status === ClientDocumentTypeStatus.UNSET ||
                  cdt.status === ClientDocumentTypeStatus.REFUSED ||
                  cdt.documentType?.source === Source.BUDET_INSIGHT_DOCUMENT
                ) {
                  if (cdt.documentType?.globalCourtageCode === 211) {
                    navigate(routeType.client + "/upload-identity/" + cdt.id);
                  } else {
                    if (cdt.documentType?.source === Source.UPLOAD) {
                      navigate(routeType.client + "/upload/" + cdt.id);
                    } else if (
                      cdt.documentType?.source === Source.BUDET_INSIGHT_DOCUMENT
                    ) {
                      navigate(routeType.client + "/bi/" + cdt.id);
                    }
                  }
                }
              }}
            >
              {cdt.status === ClientDocumentTypeStatus.UNSET ||
              cdt.status === ClientDocumentTypeStatus.REFUSED ? (
                <ToProcessIcon
                  height={20}
                  width={20}
                  style={{ verticalAlign: "middle" }}
                  color={
                    cdt.status === ClientDocumentTypeStatus.REFUSED ||
                    !isFirstUse
                      ? "error"
                      : "primary"
                  }
                  fill={
                    cdt.status === ClientDocumentTypeStatus.REFUSED ||
                    !isFirstUse
                      ? theme.palette.error.main
                      : theme.palette.primary.main
                  }
                  stroke={
                    cdt.status === ClientDocumentTypeStatus.REFUSED ||
                    !isFirstUse
                      ? theme.palette.error.main
                      : theme.palette.primary.main
                  }
                />
              ) : cdt.documentType?.source === Source.BUDET_INSIGHT_DOCUMENT ? (
                <Tooltip title={t("client.bi-add-tooltip")}>
                  <AddCircleIcon color={"primary"}></AddCircleIcon>
                </Tooltip>
              ) : (
                <Processed
                  fill={theme.palette.primary.main}
                  stroke={theme.palette.primary.main}
                  style={{ verticalAlign: "middle" }}
                  height={22}
                  width={22}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Box
      sx={{
        pt: 0,
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      {!aCase && <Loader />}
      {aCase && params.id && params.groupId === "grp-auto-bank" && cdts && (
        <ClientDocumentBank
          aCase={aCase}
          isCoBorrower={props.isCoBorrower}
          //isMaster={}
          selectedDocumentTypes={cdts}
          clientId={params.id}
        />
      )}
      {aCase && params.id && params.groupId === "grp-auto-tax" && cdts && (
        <ClientDocumentTaxAndProof
          aCase={aCase}
          isTax={true}
          isCoBorrower={props.isCoBorrower}
          //isMaster={}
          selectedDocumentTypes={cdts}
          clientId={params.id}
        />
      )}
      {aCase && params.id && params.groupId === "grp-auto-proof" && cdts && (
        <ClientDocumentTaxAndProof
          aCase={aCase}
          isTax={false}
          isCoBorrower={props.isCoBorrower}
          //isMaster={}
          selectedDocumentTypes={cdts}
          clientId={params.id}
        />
      )}
      {aCase &&
        !["grp-auto-bank", "grp-auto-tax", "grp-auto-proof"].includes(
          params.groupId || ""
        ) && (
          <Grid
            container
            md={6}
            sx={{
              pt: 1,
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            {aCase.borrowers.map((borrower) => {
              if (props.isCoBorrower) {
                if (borrower.id === params.id) {
                  return "";
                }
              } else {
                if (borrower.id !== params.id) {
                  return "";
                }
              }
              const borrowersCdts =
                cdts?.filter((c) => c.clientId === borrower.id) || [];
              if (borrowersCdts.length === 0) {
                return "";
              }
              return (
                <React.Fragment key={borrower.id}>
                  <Grid item>
                    <div className="title">
                      {borrower.firstName} {borrower.lastName}
                    </div>
                  </Grid>
                  {borrowersCdts && generateDocTypeList(borrowersCdts)}
                </React.Fragment>
              );
            })}
            {aCase && cdts && cdts.some((x) => x.clientId === null) && (
              <>
                <Grid item mt={4}>
                  <div className="title">{t("case.common")}</div>
                </Grid>
                {generateDocTypeList(cdts.filter((x) => x.clientId === null))}
              </>
            )}
            <Grid item textAlign={"center"} marginTop={5}>
              <Button
                variant="outlined"
                className="title"
                onClick={() => {
                  navigate(routeType.client + "/" + params.id);
                }}
              >
                {t("global.back")}
              </Button>
            </Grid>
          </Grid>
        )}
    </Box>
  );
}
