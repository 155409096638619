import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getClient } from "../../services/user.api";
import { Borrower } from "../../types/caseForm.type";
import "../../styles/client/clientInformations.scss";
import { useTranslation } from "react-i18next";
import { countries } from "../../components/common/countrySelect";
import { routeType } from "../../routes";
import Loader from "../../components/common/loading";
import { useDispatch } from "react-redux";
import { customBackContext, clearBackContext } from "../../slice/back.slice";
export function ClientInformations() {
  const params = useParams();
  const [client, setClient] = useState<Borrower>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      customBackContext({
        disabled: true,
        className: "hide-back-button",
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (params.id) {
        setClient(await getClient(params.id));
      }
    })();
  }, [params.id]);

  const handleSubmit = () => {
    if (client && client.isMaster && client?.case?.nbBorrower === 2) {
      navigate(routeType.client + "/" + params.id + "/invite");
    } else {
      navigate(routeType.client + "/" + params.id);
    }
  };

  const displayClientInfo = (clientInfos: Borrower, first = true) => {
    if (!client) {
      return <></>;
    }
    return (
      <>
        <Grid item xs={12}>
          <div className="sub-title">
            {t(first ? "client.main-borrower" : "client.co-borrower")}
          </div>
          <br />
          <div className="text">
            {clientInfos.firstName} {clientInfos.lastName}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="sub-title">{t("client.phone")}</div>
          <br />
          <div className="text">{clientInfos.phone}</div>
        </Grid>
        <Grid item xs={12}>
          <div className="sub-title">{t("client.email")}</div>
          <br />
          <div className="text">{clientInfos.email}</div>
        </Grid>
        <Grid item xs={12}>
          <div className="sub-title">{t("client.csp")}</div>
          <br />
          <div className="text">
            {t(`case.borrower-csp-${clientInfos.csp}`)}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="sub-title">{t("client.nationality")}</div>
          <br />
          <div className="text">
            {countries.find((x) => x.id === clientInfos.nationality)?.label}
          </div>
        </Grid>
      </>
    );
  };

  return (
    <Box alignContent={"center"} sx={{ pt: 1 }}>
      {!client && <Loader />}

      {client && (
        <Grid container sx={{ textAlign: "left" }} spacing={2} rowSpacing={2}>
          <Grid item xs={12}>
            <div className="title">{t("client.informations")}</div>
          </Grid>
          <Grid item xs={12} md={6}>
            {displayClientInfo(client)}
            {client.case?.borrowersLink && (
              <Grid item xs={12}>
                <div className="sub-title">{t("client.civil-state")}</div>
                <br />
                <div className="text">
                  {" "}
                  {t(
                    `case.borrower-situation-${client.case?.borrowersLink}`
                  )}{" "}
                </div>
              </Grid>
            )}
          </Grid>

          {client.case?.nbBorrower === 2 && (
            <Grid item xs={12} md={6}>
              {(() => {
                const coBorrower = client.case?.borrowers.find(
                  (x) => x.id !== params.id
                );
                if (!coBorrower) {
                  return;
                }
                return displayClientInfo(coBorrower, false);
              })()}
            </Grid>
          )}
          <Grid item xs={12} md={5}>
            <div className="validate-button">
              <Button onClick={handleSubmit} variant="contained">
                {t("global.start")}
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
