import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/common/loading";
import { routeType } from "../routes";
import { getMe } from "../services/user.api";
import { clearBackContext, customBackContext } from "../slice/back.slice";
import { selectUser, userUpdate } from "../slice/login.slice";
import { JwtPayload } from "../types/JwtPayload.type";
import { LocalStorageKey } from "../types/localStorageEnum";
import { Roles } from "../types/role.enum";

export default function Home() {
  const navigate = useNavigate();
  const jwtPayload = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      customBackContext({
        className: "hide-back-button",
        shoulBlockIncrement: true,
      })
    );
  });

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  const redirectUser = async () => {
    let userPayload: JwtPayload | null = jwtPayload;
    if (
      (!userPayload || !userPayload.id) &&
      !localStorage.getItem(LocalStorageKey.KEY_TOKEN_LOCALSTORAGE)
    ) {
      navigate(routeType.login);
      return;
    }

    switch (userPayload.role) {
      case Roles.CLIENT:
        navigate(`${routeType.client}/${userPayload.id}/informations`);

        break;
      case Roles.ADMIN:
        navigate(routeType.userList);
        break;
      case Roles.USER:
        navigate(routeType.caseList);
        break;
      default:
        navigate(routeType.login);
        break;
    }
  };

  useEffect(() => {
    redirectUser();
  }, [jwtPayload, navigate]);

  return (
    <div>
      <Loader />
    </div>
  );
}
