import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { checkDocumentHash, getDocumentInfo } from "../services/upload.api";
import { customBackContext, clearBackContext } from "../slice/back.slice";
import { Document } from "../types/documentList";
import "../styles/documentInfo.scss";
import Loader from "../components/common/loading";
import { ReactComponent as RefusedIcon } from "../resources/icons/restitution/refused.svg";
import { ReactComponent as ValidatedIcon } from "../resources/icons/restitution/validated.svg";
import { set } from "lodash";

async function hash(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileArrayBuffer = event.target?.result as ArrayBuffer;
      const fileUint8Array = new Uint8Array(fileArrayBuffer);
      try {
        const arrayBufferEncrypt = await crypto.subtle.digest(
          "SHA-256",
          fileUint8Array
        );
        const hashUI8: Uint8Array = new Uint8Array(arrayBufferEncrypt);
        let hexstring = "",
          h;
        for (let i = 0; i < hashUI8.length; i++) {
          h = hashUI8[i].toString(16);
          if (h.length == 1) {
            h = "0" + h;
          }
          hexstring += h;
        }
        const p = Math.pow(2, Math.ceil(Math.log2(hexstring.length)));
        resolve(hexstring.padStart(p, "0"));
      } catch (error) {
        reject("");
      }
    };
    reader.onerror = (error) => reject("");
    reader.readAsArrayBuffer(file);
  });
}

export default function DocumentInfo({ isReport = false, withCheck = false }) {
  const fileInput = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const [document, setDocument] = useState<Document>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFileSubmitted, setIsSubmitted] = useState(false);
  const [isFileOk, setIsFileOk] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      customBackContext({
        disabled: true,
        className: "hide-back-button",
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (params.id) {
        setDocument(await getDocumentInfo(params.id));
      }
    })();
  }, [params.id]);

  const handleFileUpload = async (file: File) => {
    setIsLoading(true);
    setIsFileOk(false);
    setIsSubmitted(false);
    await new Promise((res, rej) => {
      setTimeout(() => {
        res(0);
      }, 1000);
    });
    try {
      const dataHash: string = await new Promise((resolve, reject) => {
        resolve(hash(file));
      });
      if (document && dataHash && dataHash !== "") {
        console.log(dataHash);
        const result = await checkDocumentHash(document?.id, dataHash);
        if (result && result["match"]) {
          setIsFileOk(true);
        } else {
          setIsFileOk(false);
        }
      }
    } catch (error) {
      setIsFileOk(false);
    }
    setIsSubmitted(true);

    setIsLoading(false);
  };
  return (
    <Container maxWidth="xl" className="document-info">
      <Grid
        container
        direction={"row"}
        alignItems={"left"}
        justifyContent={"flex-start"}
        textAlign={"left"}
      >
        <Grid item display={{ xs: "block", md: "block" }} mt={2} mx={0}>
          <img
            style={{ objectFit: "contain", maxWidth: "300px" }}
            alt={"Logo"}
            height={"30px"}
            src="/Lendy.png"
          />
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={"center"}
        minHeight={"70vh"}
      >
        <Grid item display={{ md: "block" }} md={6}>
          <Typography
            mb={2}
            mt={{ xs: 3, md: 0 }}
            textAlign={{ xs: "center", md: "left" }}
          >
            <span className="title-text">
              {t("login.simplify")}
              <span className="title-and">{t("login.&")}</span>
              {t("login.secure")}
            </span>
            <span className="title-subtext">{t("login.loan")}</span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          spacing={2}
          direction={"column"}
          textAlign={"center"}
        >
          <Grid item>
            <Typography className="main-title">
              {t("doc-info.main-title")}
            </Typography>
            <Typography
              className="subtitle"
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {t("doc-info.sub-title")}
            </Typography>
          </Grid>
          {document && (
            <>
              <Grid item className="info-group">
                <Grid
                  container
                  spacing={2}
                  direction={"column"}
                  className="info-group-container"
                >
                  <Typography
                    className="subtitle"
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    {t("doc-info.sub-title")}
                  </Typography>
                  <Grid
                    className="content-info"
                    item
                    container
                    flexDirection={"row"}
                    alignItems={{ xs: "flex-start", md: "center" }}
                    justifyContent={{ xs: "flex-start", md: "space-between" }}
                  >
                    <Typography className="label">
                      {t("doc-info.createdAt")}
                    </Typography>
                    <Typography className="value">
                      {format(new Date(document.createdAt), "dd/MM/yy HH:mm")}
                    </Typography>
                  </Grid>
                  <Grid
                    className="content-info"
                    item
                    container
                    flexDirection={"row"}
                    alignItems={{ xs: "flex-start", md: "center" }}
                    justifyContent={{ xs: "flex-start", md: "space-between" }}
                  >
                    <Typography className="label">
                      {t(`doc-info.source`)}
                    </Typography>
                    <Typography className="value">
                      {t(`doc-info.${document.source}`)}
                    </Typography>
                  </Grid>
                  <Grid
                    className="content-info"
                    item
                    container
                    flexDirection={"row"}
                    alignItems={{ xs: "flex-start", md: "flex-start" }}
                    justifyContent={{ xs: "flex-start", md: "space-between" }}
                  >
                    <Typography className="label">
                      {t(
                        document.users && document.users.length > 1
                          ? "doc-info.users"
                          : "doc-info.user"
                      )}
                    </Typography>
                    <Typography className="value" textAlign={"right"}>
                      {document.users &&
                        document.users.map((u) => (
                          <span key={u}>
                            {u}
                            <br />
                          </span>
                        ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {withCheck && (
                <>
                  {isLoading && (
                    <Grid
                      item
                      container
                      flexDirection={"column"}
                      justifyContent={"center"}
                      textAlign={"center"}
                      my={2}
                    >
                      <Loader />
                      <Typography className="loading-text">
                        {t("doc-info.loading")}
                      </Typography>
                    </Grid>
                  )}
                  {!isLoading && isFileSubmitted && (
                    <Grid
                      item
                      container
                      flexDirection={"row"}
                      justifyContent={"center"}
                      textAlign={"center"}
                      spacing={2}
                    >
                      {isFileOk ? (
                        <>
                          <Grid item>
                            <ValidatedIcon height={"25px"} />
                          </Grid>
                          <Grid item className="validation-text">
                            {t("doc-info.check-ok")}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item>
                            <RefusedIcon height={"25px"} />
                          </Grid>
                          <Grid item className="validation-text">
                            {t("doc-info.check-ko")}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                  {!isLoading && (
                    <>
                      <Button
                        className="next-button action"
                        onClick={() =>
                          fileInput.current && fileInput.current.click()
                        }
                        variant="contained"
                      >
                        {t("doc-info.btn-check")}
                      </Button>
                      {fileInput && (
                        <input
                          ref={fileInput}
                          style={{ display: "none" }}
                          accept="image/*,application/pdf"
                          type="file"
                          capture="environment"
                          onChange={(e) => {
                            if (
                              e.target &&
                              e.target.files &&
                              e.target.files.length > 0
                            ) {
                              handleFileUpload(e.target.files[0]);
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
