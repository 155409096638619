import { Button, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { postCreateNewUser } from "../services/user.api";
import { Roles } from "../types/role.enum";
import { User } from "../types/User.type";
import "../styles/createUser.scss";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import { selectUser } from "../slice/login.slice";

export default function CreateUser() {
  const jwtUser = useSelector(selectUser);
  const { t } = useTranslation();
  const [userType, setUserType] = useState(Roles.USER);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const isAdmin = jwtUser.isAdmin;

  const createNewUser = async () => {
    try {
      await postCreateNewUser(userType, email, selectedUserId);
      enqueueSnackbar(t("successes.user-created"), { variant: "success" });
      setUserType(Roles.USER);
      setEmail("");
      setSelectedUserId("");
    } catch (err) {
      console.log(" err =>  ", err);
      enqueueSnackbar(t("errors.create-user"), { variant: "error" });
    }
  };
  // recuperer le state (useSelector)

  const userToCreate = [];

  if (isAdmin) {
    userToCreate.push(
      { value: Roles.USER, label: t("create.user") },
      { value: Roles.ADMIN, label: t("create.admin") }
    );
  }

  return (
    <div className="container">
      <h2>{t("create.title")}</h2>
      <TextField
        className="form-elem"
        label={t("create.form-type")}
        value={userType}
        select
        onChange={(event) => setUserType(event.target.value as Roles)}
      >
        {userToCreate.map((user) => (
          <MenuItem key={user.value} value={user.value}>
            {user.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className="form-elem"
        type="email"
        id="magic-link-form-email"
        label={t("create.form-email")}
        placeholder={t("create.form-email-placeholder") as string}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />

      {userType === Roles.CLIENT && isAdmin && (
        <div>
          <TextField
            className="form-elem"
            label={t("create.form-user")}
            select
            value={selectedUserId}
            onChange={(event) => {
              setSelectedUserId(event.target.value);
            }}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.email}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
      <Button
        variant="contained"
        className="form-elem"
        disabled={!email || (userType === Roles.CLIENT && !selectedUserId)}
        onClick={() => {
          createNewUser();
        }}
      >
        {t("create.validate")}
      </Button>
    </div>
  );
}
