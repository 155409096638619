export type DocumentList = {
  documents: Document[];
  first_date: Date;
  last_date: Date;
};

export type Document = {
  id: string;
  date: Date;
  id_user: number;
  name: string;
  last_update: Date;
  url: string;
  type: DocumentType;
  createdAt: Date;
  subCategory: string;
  source: Source;
  subscription: {
    conenction: { connector: { name: string } };
  };
  users?: string[];
};

export enum Source {
  UPLOAD = "upload",
  BUDET_INSIGHT_DOCUMENT = "budgetInsightDocument",
}

export type DocumentGroup = {
  id: string;
  name: string;
  globalCourtageCode: number;
  parentId?: string;
  parent?: DocumentGroup;
};

export type DocumentType = {
  id: string;
  name: string;
  trasnslatedName?: string;
  globalCourtageCode: number;
  budgetInsightConnectorIds?: string[];
  source: Source;
  onlyCommon: boolean;
  onlyPersonal: boolean;
  group: DocumentGroup;
  groupId: string;
  parentId: string;
  unselected?: boolean;
};
