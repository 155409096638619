import React, { PropsWithChildren, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { store } from "./store/store";
import ResponsiveAppBar from "./components/common/nav-bar";
import ClientResponsiveAppBar from "./components/common/client-nav-bar";
import { Container, lighten } from "@mui/system";
import "./styles/common/containerApp.scss";
import Footer from "./components/common/footer";
import BackButton from "./components/common/backButton";
import { routeType } from "./routes";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import TranslationFR from "./locales/fr/translation.json";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LENDY_PRIMARY_COLOR } from "./constants";
import { selectUser } from "./slice/login.slice";
import { createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      fr: { translation: TranslationFR },
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",
    debug: true,
    ns: "translation",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function CustomThemeProvider(props: PropsWithChildren) {
  const jwtPayload = useSelector(selectUser);
  const [theme, setTheme] = useState(createTheme());
  useEffect(() => {
    let primary,
      secondary = null;
    if (jwtPayload.config?.primary) {
      primary = jwtPayload.config?.primary;
    }
    if (jwtPayload.config?.secondary) {
      secondary = jwtPayload.config?.secondary;
    }
    const newTheme = createTheme({
      typography: {
        fontFamily: "SF UI Text",
        fontSize: 13,
        body1: { textAlign: "center" },
        button: {
          textTransform: "none",
        },
      },

      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: lighten(primary ?? LENDY_PRIMARY_COLOR, 0.5),
              },
              "& .MuiOutlinedInput-root": {
                "&:hover:not(.Mui-disabled)": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: lighten(primary ?? LENDY_PRIMARY_COLOR, 0.5),
                  },
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      "1px solid " +
                      lighten(primary ?? LENDY_PRIMARY_COLOR, 0.5),
                  },
                },
              },
            },
          },
        },
        MuiButton: {
          variants: [
            {
              props: { variant: "outlined" },
              style: {
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  "box-shadow": "none",
                  border:
                    "1px solid " + lighten(primary ?? LENDY_PRIMARY_COLOR, 0.5),
                },
              },
            },
          ],
          styleOverrides: {
            root: {
              "box-shadow": "none",
              "&:hover": {
                "box-shadow": "none",
              },
            },
          },
        },
        MuiCssBaseline: {
          // custom css that will apply on all the app
          styleOverrides: `
      text-align:center;
      `,
        },
      },
      palette: {
        primary: {
          main: primary ?? LENDY_PRIMARY_COLOR,
          contrastText: "#ffffff",
          dark: primary ?? LENDY_PRIMARY_COLOR,

          light: lighten(primary ?? LENDY_PRIMARY_COLOR, 0.53),
        },
        secondary: {
          main: secondary ?? "#4E7CAD",
          contrastText: "#ffffff",
          dark: secondary ?? "#4E7CAD",
        },
      },
    });
    setTheme(newTheme);
  }, [jwtPayload]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* this will apply all the MuiCssBaseline css in all the app */}
      {props.children}
    </ThemeProvider>
  );
}

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <CustomThemeProvider>
        {/* this will apply all the MuiCssBaseline css in all the app */}
        <Main />
      </CustomThemeProvider>
    </BrowserRouter>
    <SnackbarProvider
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    />
  </Provider>
);

function Main() {
  const location = useLocation();
  const noHeaderRoutes: string[] = [
    routeType.webviewResolve,
    routeType.uploadDocument,
    routeType.uploadIdentityDocument,
    routeType.login,
  ];

  const noFooterRoutes: string[] = [
    routeType.webviewResolve,
    routeType.uploadDocument,
    routeType.uploadIdentityDocument,
  ];

  const clientSpecificRoutes: string[] = [
    routeType.uploadDocument,
    routeType.uploadIdentityDocument,
  ];
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_CLIENT_KEY || ""}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        {!noHeaderRoutes.includes(location.pathname) &&
          !location.pathname.match(/\/document\/info\//) &&
          !location.pathname.match(/\/document\/verification\//) && (
            <ResponsiveAppBar />
          )}
        {clientSpecificRoutes.includes(location.pathname) && (
          <ClientResponsiveAppBar />
        )}
        {/* {!noHEaderRoutes.includes(location.pathname) &&  !location.pathname.includes(routeType.client) &&
        !location.pathname.match(/case\/.*\/document/) &&
        location.pathname !== routeType.login && */}

        <Container
          maxWidth="xl"
          style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          className={
            location.pathname !== routeType.login
              ? "container-app"
              : "home-container"
          }
        >
          <BackButton />
          <App />
        </Container>

        {!noFooterRoutes.includes(location.pathname) && <Footer />}
      </div>
    </GoogleReCaptchaProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
