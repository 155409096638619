import { Box, Button, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../components/common/loading";
import { routeType } from "../../routes";
import { inviteCoBorrower } from "../../services/case.api";
import { getClient } from "../../services/user.api";
import { customBackContext, clearBackContext } from "../../slice/back.slice";
import { Borrower } from "../../types/caseForm.type";

export default function ClientIvite() {
  const params = useParams();
  const [client, setClient] = useState<Borrower>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      customBackContext({
        disabled: true,
        className: "hide-back-button",
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (params.id) {
        setClient(await getClient(params.id));
      }
    })();
  }, [params.id]);

  return (
    <Box alignContent={"center"} sx={{ pt: 1 }}>
      {!client && <Loader />}

      {client && (
        <Grid container sx={{ textAlign: "left" }} spacing={2} rowSpacing={2}>
          <Grid item xs={12}>
            <div className="title">
              {client.firstName} {client.lastName}
            </div>
          </Grid>

          <Grid item xs={12}>
            {t("client.invite-text")}{" "}
          </Grid>
          <Grid item xs={12}>
            {t("client.co-borrower-email")} :{" "}
            {(() => {
              const coBorrower = client.case?.borrowers.find(
                (x) => x.id !== params.id
              );
              if (!coBorrower) {
                return;
              }
              return `${coBorrower.email} `;
            })()}
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ width: "100%" }}
              onClick={async () => {
                if (client && client.id) {
                  await inviteCoBorrower(client.id);
                  navigate(routeType.client + "/" + params.id);
                }
              }}
              variant={"outlined"}
            >
              {t("client.invite-button")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ width: "100%" }}
              onClick={() => {
                navigate(routeType.client + "/" + params.id);
              }}
              variant="outlined"
            >
              {t("client.no-invite-button")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
