import { useEffect, useState } from "react";
import {
  getDocumentEventEmitter,
  getDocumentMobilePageEventEmitter,
  getUploadToken,
} from "../../services/upload.api";
import QRCode from "qrcode";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function Upload(props: {
  cdtId: string;
  setFiles: Function;
  files: any[];
  cancelOpen: Function;
  clickOpen: Function;
  openModal: Function;
  identityMode: boolean;
}) {
  const { t } = useTranslation();
  const [uploadToken, setUploadToken] = useState<string>();
  const [qrCode, setQrCode] = useState<string>();
  const [newFile, setNewFile] = useState<any>();
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    if (showQRCode) {
      const handleFileSubmited = (result: { data: string }) => {
        if (!showQRCode) {
          return;
        }
        const parsedData = JSON.parse(result.data);
        const buffer = Buffer.from(parsedData.documents.buffer.data);
        const newFile = new File(
          [buffer as BlobPart],
          parsedData.documents.name,
          {
            type: parsedData.documents.type,
          }
        );
        props.openModal();
        setNewFile(newFile);
      };
      const handleOpenMobilePage = (result: { data: string }) => {
        console.log(showQRCode);
        console.log("handleOpenMobilePage", result);
        if (!showQRCode) {
          return;
        }
        const parsedData = JSON.parse(result.data);
        console.log("mobilePageEvent", parsedData);
        props.openModal();
      };
      (async () => {
        const res = await getUploadToken(props.cdtId);
        setUploadToken(res.token);
      })();
      const eventSource = getDocumentEventEmitter(props.cdtId);
      eventSource.onmessage = handleFileSubmited;

      const eventSourcePageMobile = getDocumentMobilePageEventEmitter(
        props.cdtId
      );
      eventSourcePageMobile.onmessage = handleOpenMobilePage;
      return () => {
        eventSource.close();
        eventSourcePageMobile.close();
      };
    }
  }, [showQRCode]);

  useEffect(() => {
    if (!showQRCode) {
      return;
    }
    if (newFile) {
      props.setFiles([...props.files, newFile]);
      setNewFile(null);
    }
  }, [props, newFile]);

  useEffect(() => {
    (async () => {
      if (!uploadToken) {
        return;
      }
      setQrCode(
        await QRCode.toDataURL(
          `${process.env.REACT_APP_FRONT_END_URL}/upload/${
            props.identityMode ? "identity-document" : "document"
          }?token=${encodeURI(uploadToken)}&cdtId=${props.cdtId}`,
          { width: 300 }
        )
      );
    })();
  }, [uploadToken, props.identityMode]);

  return (
    <div>
      <Button
        className="next-button action"
        variant="contained"
        onClick={() => {
          setShowQRCode(true);
          props.clickOpen();
        }}
      >
        {t("upload.show-qrcode")}
      </Button>
      {qrCode && showQRCode && (
        <>
          <Button
            sx={{
              display: "block",
              marginTop: "20px",
              marginX: "auto",
              textDecoration: "underline",
            }}
            variant="text"
            onClick={() => {
              setShowQRCode(false);
              props.cancelOpen();
            }}
          >
            {t("global.cancel")}
          </Button>
          <Card
            sx={{
              justifyContent: "center",

              margin: "auto",
              marginTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <CardHeader
              title={t("upload.qrcode-title")}
              // subheader=
              // action={
              //   <CloseIcon
              //     onClick={() => {
              //       setShowQRCode(false);
              //     }}
              //   ></CloseIcon>
              // }
            ></CardHeader>
            <CardContent>
              <Typography textAlign={"left"}>
                {t("upload.qrcode-subheader")}
              </Typography>
            </CardContent>
            <CardMedia
              component={"img"}
              sx={{
                height: "20vh",
                width: "auto",
                textAlign: "center",
                margin: "auto",
              }}
              image={`${qrCode}`}
            />
          </Card>
        </>
      )}
    </div>
  );
}
