import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/loading";
import { routeType } from "../../routes";
import { fetchClientForUser } from "../../services/user.api";
import { User } from "../../types/User.type";
import { useTranslation } from "react-i18next";
export default function ClientList() {
  const { t } = useTranslation();
  const [clients, setClients] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { userId } = useParams();
  const { state } = useLocation();
  const { userName } = state || {};

  const columns: GridColDef[] = [
    { field: "email", headerName: t("clients.email") as string, flex: 1 },
    {
      field: "id",
      valueGetter: (params) => params,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <div>
            <Button
              variant="contained"
              disabled={!params.row.biConnected}
              onClick={() => {
                navigate(routeType.account + "/" + params.row.id, {
                  state: { userName: params.row.email },
                });
              }}
            >
              {t("clients.accounts")}
            </Button>
            &nbsp;
            <Button
              variant="contained"
              disabled={!params.row.biConnected}
              onClick={() => {
                navigate(routeType.document + "/" + params.row.id, {
                  state: { userName: params.row.email },
                });
              }}
            >
              {t("clients.documents")}
            </Button>
          </div>
        );
      },
      headerName: "",
      maxWidth: 500,
      flex: 1,
    },
  ];

  const getUsers = async () => {
    if (!userId) {
      return;
    }
    setLoading(true);
    const clients = await fetchClientForUser(userId);
    setClients(clients);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      {userName ? (
        <h2>{t("clients.title-admin", { userName })}</h2>
      ) : (
        <h2> {t("clients.title-user")}</h2>
      )}
      {loading ? (
        <Loader />
      ) : (
        <Box height={"400px"} width={"100%"}>
          <DataGrid
            rows={clients}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
      )}
    </div>
  );
}
