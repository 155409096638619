import { useTranslation } from "react-i18next";

export function NoPages() {
  const { t } = useTranslation();
  return (
    <div>
      <h2> {t("errors.404")} </h2>
    </div>
  );
}
