import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useTheme,
} from "@mui/material";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { routeType } from "../../routes";
import { getCase, validateCase } from "../../services/case.api";
import { Case, CaseStatus } from "../../types/caseForm.type";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

import { clearBackContext, customBackContext } from "../../slice/back.slice";
import Loader from "../../components/common/loading";
import DocumentTypeComponent from "../../components/case/DocumentTypeComponent";
import { MultiStateDisplay } from "../../components/case/MultiStateDisplay";
import { ReactComponent as OneTick } from "../../resources/icons/recap-tick.svg";
import { ReactComponent as DoubleTick } from "../../resources/icons/recap-double-tick.svg";
import { ReactComponent as CommonFilesIcon } from "../../resources/icons/restitution/common-files.svg";
import CaseStepper from "../../components/case/caseStepper";

export default function CaseRecap() {
  const [aCase, setCase] = useState<Case>();
  const [activeUsers, setActiveUsers] = useState<string[]>([]);
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    if (params.id) {
      dispatch(
        customBackContext({
          route: routeType.caseCreate + `/${params.id}/document`,
          className: "custom-back-button",
        })
      );
    }
  });

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!params.id) {
        enqueueSnackbar(t("errors.no-case-id"), { variant: "error" });
        navigate(-1);
      } else {
        const theCase = await getCase(params.id, true);
        setCase(theCase);
        if (theCase && theCase.borrowers) {
          const clientIds = theCase.borrowers
            .map((x: { id: string }) => x.id || null)
            .filter(Boolean) as string[];
          setActiveUsers(clientIds);
        }
      }
      setLoading(false);
    })();
  }, [dispatch, navigate, params.id]);

  const toggleUser = (userId?: string) => {
    if (!userId) {
      return;
    }
    if (activeUsers.includes(userId)) {
      setActiveUsers(activeUsers.filter((x) => x !== userId));
    } else {
      setActiveUsers([...activeUsers, userId]);
    }
  };

  const userIsActive = (userId?: string) => {
    return userId && activeUsers.includes(userId);
  };

  const validate = async () => {
    if (aCase?.id) {
      try {
        await validateCase(aCase?.id, {
          caseStatus: CaseStatus.PUBLISHED,
          master:
            activeUsers && activeUsers.length === 1
              ? activeUsers[0]
              : undefined,
        });
        enqueueSnackbar(t("successes.case-published"), { variant: "success" });
        navigate(routeType.caseList);
      } catch (err) {
        console.log("ERROR IN CASE RECAP ", err);
        enqueueSnackbar(t("errors.case-not-published"));
      }
    }
  };

  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <Box sx={{ flexGrow: 1 }}>
          <Box position={"relative"} pt={"1px"} m={"auto"}>
            <h3>
              <u>{t("case.recap")}</u>
            </h3>
          </Box>
          <CaseStepper nbSteps={3} position={1}></CaseStepper>
          <div>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12} lg={4}>
                <Grid container spacing={2} direction={"column"}>
                  <Grid item>
                    <h4>
                      {aCase?.borrowers[0].firstName +
                        " " +
                        aCase?.borrowers[0].lastName}
                    </h4>
                  </Grid>
                  {/* <Grid item maxHeight={"10px"} pt={"0px !important"}>
                    <OneTick fill={theme.palette.primary.main} />
                  </Grid> */}
                  <Grid item>
                    <MultiStateDisplay
                      currentState={
                        userIsActive(aCase?.borrowers[0].id)
                          ? "selected"
                          : "unselected"
                      }
                      label={aCase?.borrowers[0].email || ""}
                      mode={"selectableUnselectable"}
                      onChangeState={() => {
                        toggleUser(aCase?.borrowers[0].id);
                      }}
                    />
                  </Grid>

                  {orderBy(aCase?.borrowers[0]?.clientDocumentTypes, (x) =>
                    x.documentType ? +x?.documentType.globalCourtageCode : null
                  ).map((x) => {
                    if (!x || !x.documentType) {
                      return "";
                    } else {
                      return (
                        <Grid item>
                          <DocumentTypeComponent
                            key={x.id}
                            docType={x.documentType}
                            mode="neutral"
                            state="neutral"
                          />
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>
              {aCase && aCase.nbBorrower === 2 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Grid
                      container
                      spacing={2}
                      direction={"column"}
                      alignItems={"stretch"}
                    >
                      <Grid item>
                        <h4>&nbsp;</h4>
                      </Grid>
                      <Grid item marginTop={"6px"}>
                        <CommonFilesIcon height={"24px"} />
                      </Grid>
                      {orderBy(aCase?.clientDocumentTypes, (x) =>
                        x.documentType
                          ? +x?.documentType.globalCourtageCode
                          : null
                      )
                        .filter((x) => !x.clientId)
                        .map((x) => {
                          if (!x || !x.documentType) {
                            return "";
                          } else {
                            return (
                              <Grid item>
                                <DocumentTypeComponent
                                  key={x.id}
                                  docType={x.documentType}
                                  mode="neutral"
                                  state="neutral"
                                />
                              </Grid>
                            );
                          }
                        })}{" "}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4} direction={"column"}>
                    <Grid container spacing={2} direction={"column"}>
                      <Grid item>
                        <h4>
                          {aCase?.borrowers[1].firstName +
                            " " +
                            aCase?.borrowers[1].lastName}
                        </h4>
                      </Grid>
                      {/* <Grid item maxHeight={"10px"} pt={"0px !important"}>
                        <OneTick fill={theme.palette.primary.main} />
                      </Grid> */}
                      <Grid item>
                        <MultiStateDisplay
                          currentState={
                            userIsActive(aCase?.borrowers[1].id)
                              ? "selected"
                              : "unselected"
                          }
                          label={aCase?.borrowers[1].email || ""}
                          mode={"selectableUnselectable"}
                          onChangeState={() => {
                            toggleUser(aCase?.borrowers[1].id);
                          }}
                        />
                      </Grid>
                      {orderBy(aCase?.borrowers[1]?.clientDocumentTypes, (x) =>
                        x.documentType
                          ? +x?.documentType.globalCourtageCode
                          : null
                      ).map((x) => {
                        if (!x || !x.documentType) {
                          return "";
                        } else {
                          return (
                            <Grid item>
                              <DocumentTypeComponent
                                key={x.id}
                                docType={x.documentType}
                                mode="neutral"
                                state="neutral"
                              />
                            </Grid>
                          );
                        }
                      })}{" "}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Box>
      )}

      <Grid
        container
        direction="row"
        spacing={2}
        mt={2}
        alignItems="center"
        justifyContent={"conter"}
      >
        <Grid item xs={12} lg={8}></Grid>
        <Grid item xs={12} lg={2}>
          <Button
            onClick={() => {
              navigate(routeType.case + "/" + aCase?.id + "/document");
            }}
            variant="outlined"
            fullWidth
          >
            {t("global.modify")}
          </Button>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            fullWidth
            onClick={() => {
              setOpenValidationDialog(true);
            }}
            disabled={activeUsers.length === 0}
            variant="contained"
          >
            {t("case.submit")}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={openValidationDialog}
        onClose={() => {
          setOpenValidationDialog(false);
        }}
      >
        <>
          <DialogContent sx={{ padding: 3 }}>
            {t("case.validate-transmition")}
            <br />
            <br />
            <Button
              sx={{ m: 2 }}
              variant={"outlined"}
              onClick={() => {
                setOpenValidationDialog(false);
              }}
            >
              {t("global.cancel")}
            </Button>
            <Button
              sx={{ m: 2 }}
              variant={"contained"}
              onClick={() => {
                setOpenValidationDialog(false);
                validate();
              }}
            >
              {t("global.confirm")}
            </Button>
          </DialogContent>
        </>
      </Dialog>
    </div>
  );
}
