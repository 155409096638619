import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/loading";
import { routeType } from "../../routes";
import {
  fetchAllUsers,
  getConnectionTokenForUser,
} from "../../services/user.api";
import { User } from "../../types/User.type";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

export default function UserList() {
  const { t } = useTranslation();

  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const columns: GridColDef[] = [
    { field: "email", headerName: t("users.email") as string, flex: 1 },
    {
      field: "__",
      headerName: t("global.modify") as string,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <Button
            key={"user-access-list-" + params.row.id}
            variant="contained"
            onClick={() => {
              navigate(routeType.userEdit + "/" + params.row.id, {
                state: { userName: params.row.email },
              });
            }}
          >
            {t("global.modify")}
          </Button>
        );
      },
    },
    {
      field: "id",

      valueGetter: (params) => params,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <Button
            key={"user-access-list-" + params.row.id}
            variant="contained"
            onClick={async () => {
              const connectionToken = await getConnectionTokenForUser(
                params.row.id
              );
              navigator.clipboard.writeText(
                process.env.REACT_APP_FRONT_END_URL +
                  routeType.login +
                  "/" +
                  connectionToken.token
              );
              enqueueSnackbar(t("successes.link-copy-to-clipboard"), {
                variant: "success",
              });
            }}
          >
            {t("users.impersonate")}
          </Button>
        );
      },
      headerName: "",
      type: "actions",
      flex: 1,
      maxWidth: 250,
    },
  ];

  const getUsers = async () => {
    setLoading(true);
    const users = await fetchAllUsers();
    setUsers(users);
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <h2>{t("users.title")}</h2>

      {loading ? (
        <Loader />
      ) : (
        <Box height={"400px"} width={"100%"}>
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Box>
      )}
    </div>
  );
}
