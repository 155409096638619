import { GridValueGetterParams } from "@mui/x-data-grid";
import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDataGrid from "../../components/common/customDataGrid";
import { deleteCase, getCases } from "../../services/case.api";
import * as React from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Case, CaseStatus } from "../../types/caseForm.type";
import { Link, useNavigate } from "react-router-dom";
import { routeType } from "../../routes";
import { fr } from "date-fns/locale";
import { clearBackContext, customBackContext } from "../../slice/back.slice";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns/esm";
import { ReactComponent as CancelIcon } from "../../resources/icons/cross.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const detailStyles = {};

const MultiOptionsRendererHoc =
  (setOpenDeleteModal: any, setActionDeleteId: any) =>
  (cellValues: GridRenderCellParams<string>) => {
    //TODO: improve
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    //TODO: use links instead to be able to click properly if a new tab is needed etc
    //TODO: use translations
    let actions = [
      {
        label: "Modifier",
        action: async () => {
          navigate(routeType.caseCreate + "/" + cellValues.row.id);
        },
      },
      {
        label: "Examiner",
        action: async () => {
          navigate(
            routeType.caseCreate + "/" + cellValues.row.id + "/validate"
          );
        },
      },
      {
        label: "Supprimer",
        action: async () => {
          setActionDeleteId(cellValues.row.id);
          setOpenDeleteModal(true);
        },
      },
    ];

    if (cellValues.value !== CaseStatus.DRAFT) {
      actions = actions.filter((action) => action.label !== "Modifier");
    } else {
      actions = actions.filter((action) => action.label !== "Examiner");
    }

    return (
      <>
        <IconButton
          className="icon"
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          sx={{ color: "black", marginLeft: "25px" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          {actions.map((page, index) => (
            <MenuItem
              key={page.label}
              onClick={() => {
                page.action();
                handleCloseNavMenu();
              }}
            >
              <Typography
                sx={{
                  color: "black",
                }}
                textAlign="center"
              >
                {page.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

export default function CaseList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cases, setCases] = useState<Case[]>([]);
  const [clickedIndex, setClickedIndex] = React.useState(-1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [actionDeleteId, setActionDeleteId] = useState<string>();

  useEffect(() => {
    dispatch(
      customBackContext({
        disabled: true,
        className: "hide-back-button",
      })
    );
  }, []);

  //unmonted backContext button
  useEffect(() => {
    return () => {
      dispatch(clearBackContext());
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setCases(await getCases());
      } catch (err) {
        console.error("ERROR GET CASES ");
      }
    })();
  }, []);

  const columns = [
    {
      field: "reference",
      headerName: t("case.form-reference") as string,
      flex: 1,
      headerClassName: "table-header",
      renderCell: (cellValues: GridRenderCellParams) => {
        return (
          <div>
            {cellValues.row.borrowers && (
              <IconButton
                disabled={cellValues.row.borrowers.length !== 2}
                style={{
                  color:
                    cellValues.row.borrowers.length !== 2 ? "white" : "inherit",
                }}
                onClick={() => {
                  clickedIndex === cellValues.row.index &&
                  cellValues.row.borrowers &&
                  cellValues.row.borrowers.length === 2
                    ? setClickedIndex(-1)
                    : setClickedIndex(cellValues.row.index ?? -1);
                }}
              >
                {cellValues.row.index === clickedIndex ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: "_noField_lastname",
      headerName: t("case.form-lastname") as string,
      flex: 1,
      headerClassName: "table-header",
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.borrowers && params.row.borrowers.length > 0) {
          return params.row.borrowers[0].lastName;
        }
      },
      renderCell: (cellValues: GridRenderCellParams<string>) => {
        return (
          <Box>
            <div>{cellValues.value}</div>
            <Collapse in={cellValues.row.index === clickedIndex}>
              <Box sx={detailStyles}>
                {cellValues.row.borrowers &&
                  cellValues.row.borrowers.length === 2 &&
                  cellValues.row.borrowers[1].lastName}
              </Box>
            </Collapse>
          </Box>
        );
      },
    },
    {
      field: "_noField_firstname", // no field because 1) we can't reuse a field 2) we use valueGetter to get the value
      headerName: t("case.form-firstname") as string,
      flex: 1,
      headerClassName: "table-header",
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.borrowers && params.row.borrowers.length > 0) {
          return params.row.borrowers[0].firstName;
        }
      },
      renderCell: (cellValues: GridRenderCellParams<string>) => {
        return (
          <Box>
            <div>{cellValues.value}</div>
            <Collapse in={cellValues.row.index === clickedIndex}>
              <Box sx={detailStyles}>
                {cellValues.row.borrowers &&
                  cellValues.row.borrowers.length === 2 &&
                  cellValues.row.borrowers[1].firstName}
              </Box>
            </Collapse>
          </Box>
        );
      },
    },
    {
      field: "advencement",
      headerName: t("case.form-advencement") as string,
      flex: 0.8,
      headerClassName: "table-header",
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.borrowers && params.row.borrowers.length > 0) {
          return Math.round(params.row.advencement);
        }
      },
      renderCell: (cellValues: GridRenderCellParams<string>) => {
        //TODO: this looks fake about avancement, it seems that we do not have the information for each borrower
        return (
          <Box>
            <div>{cellValues.value}%</div>
            <Collapse in={cellValues.row.index === clickedIndex}>
              <Box sx={detailStyles}>{cellValues.value}%</Box>
            </Collapse>
          </Box>
        );
      },
    },
    {
      field: "_noField_lastConnection",
      headerName: t("case.form-last-connection") as string,
      flex: 1,
      headerClassName: "table-header",
      sortComparator: (elem1: Date, elem2: Date) => {
        if (!elem1) return -1;
        if (!elem2) return 1;
        return elem1.getTime() - elem2.getTime();
      },
      valueGetter: (params: GridValueGetterParams) => {
        if (
          params.row.borrowers &&
          params.row.borrowers.length > 0 &&
          params.row.borrowers[0].lastConnection
        ) {
          return new Date(params.row.borrowers[0].lastConnection);
        } else if (
          params.row.borrowers &&
          params.row.borrowers.length > 0 &&
          params.row.borrowers[1] &&
          params.row.borrowers[1].lastConnection
        ) {
          return new Date(params.row.borrowers[1].lastConnection);
        }
      },
      renderCell: (cellValues: GridRenderCellParams<string>) => {
        return (
          <Box>
            <div>
              {cellValues.value &&
                format(new Date(cellValues.value), "dd/MM/yy HH:mm")}
            </div>
            <Collapse in={cellValues.row.index === clickedIndex}>
              <Box sx={detailStyles}>
                {cellValues.row.borrowers &&
                  cellValues.row.borrowers.length === 2 &&
                  cellValues.row.borrowers[1].lastConnection &&
                  format(
                    new Date(cellValues.row.borrowers[1].lastConnection),
                    "dd/MM/yy HH:mm"
                  )}
              </Box>
            </Collapse>
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: t("case.form-created-at") as string,
      flex: 1,
      headerClassName: "table-header",
      sortComparator: (elem1: Date, elem2: Date) => {
        if (!elem1) return -1;
        if (!elem2) return 1;
        return elem1.getTime() - elem2.getTime();
      },
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row.borrowers[0].createdAt);
      },
      renderCell: (cellValues: GridRenderCellParams<string>) => {
        return (
          <Box>
            <div>
              {formatDistance(
                new Date(cellValues.row.borrowers[0].createdAt),
                new Date(),
                { locale: fr }
              )}
            </div>
            <Collapse in={cellValues.row.index === clickedIndex}>
              <Box sx={detailStyles}>
                {cellValues.row.borrowers &&
                  cellValues.row.borrowers.length === 2 &&
                  formatDistance(
                    new Date(cellValues.row.borrowers[1].createdAt),
                    new Date(),
                    { locale: fr }
                  )}
              </Box>
            </Collapse>
          </Box>
        );
      },
    },
    {
      field: "_noField_phone",
      headerName: t("case.form-phone") as string,
      flex: 1,
      headerClassName: "table-header",
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.borrowers && params.row.borrowers.length > 0) {
          return params.row.borrowers[0].phone;
        }
      },
      renderCell: (cellValues: GridRenderCellParams<string>) => {
        return (
          <Box>
            <div>{cellValues.value}</div>
            <Collapse in={cellValues.row.index === clickedIndex}>
              <Box sx={detailStyles}>
                {cellValues.row.borrowers &&
                  cellValues.row.borrowers.length === 2 &&
                  cellValues.row.borrowers[1].phone}
              </Box>
            </Collapse>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: t("case.form-status") as string,
      flex: 1,
      headerClassName: "table-header",
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.borrowers && params.row.borrowers.length > 0) {
          return params.row.advencementStatus
            ? t("case.case-status-" + params.row.advencementStatus)
            : "";
        }
      },
    },
    {
      field: "caseStatus",
      headerName: "",
      flex: 0.4,
      headerClassName: "table-header",
      sortable: false,
      renderCell: MultiOptionsRendererHoc(
        setOpenDeleteModal,
        setActionDeleteId
      ),
    },
  ];

  return (
    <div>
      <CustomDataGrid
        defaultSorting={[{ field: "createdAt", sort: "desc" }]}
        rows={cases.map((x, index) => ({ ...x, index }))}
        columns={columns}
      />
      <Dialog
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          {t("case.confirm-delete")}
          <br />
          <br />
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
            variant="outlined"
            sx={{ m: 2 }}
          >
            {t("global.cancel")}
          </Button>

          <Button
            sx={{ m: 2 }}
            variant="contained"
            onClick={async () => {
              if (actionDeleteId) {
                await deleteCase(actionDeleteId);
                setCases(await getCases());
              }
              setOpenDeleteModal(false);
            }}
          >
            {t("global.confirm")}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
