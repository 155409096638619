import { createSlice } from "@reduxjs/toolkit";
import { JwtPayload } from "../types/JwtPayload.type";

const initialState: { user: JwtPayload } = { user: {} as JwtPayload };

export const loginSlice = createSlice({
  name: "login",
  initialState,

  reducers: {
    userLogin: (state, action) => {
      state.user = action.payload as JwtPayload;
    },
    userLogout: (state) => {
      state.user = {} as JwtPayload;
    },
    userUpdate: (state, action) => {
      state.user = action.payload as JwtPayload;
    },
  },
});

export const selectUser = (state: { login: { user: JwtPayload } }) =>
  state.login.user;

export const { userLogin, userLogout, userUpdate } = loginSlice.actions;

export default loginSlice.reducer;
