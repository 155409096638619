import { Grid } from "@mui/material";
import "../../styles/case/caseValidation.scss";
import { ReactComponent as NotYetValidatedIcon } from "../../resources/icons/restitution/circle-validate.svg";
import { ReactComponent as ValidatedIcon } from "../../resources/icons/restitution/validated.svg";
import { MultiStateDisplayCore } from "./MultiStateDisplay";
import {
  LENDY_FILE_VALIDATED_COLOR,
  LENDY_PRIMARY_COLOR,
} from "../../constants";

export default function CaseDocumentBiRow(props: {
  biDoc: { name: string; url: string; access_token: string };
  isSelected: boolean;
  handleClick: any;
}) {
  const openDocUrl = async (url: string, access_token: string) => {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    });

    window.open(
      URL.createObjectURL(await res.blob()),
      "_blank",
      "fullscreen=yes"
    );
  };

  const extraProps = props.isSelected
    ? {
        borderColor: LENDY_FILE_VALIDATED_COLOR,
        bgColor: LENDY_FILE_VALIDATED_COLOR,
        typographyColor: "primary.contrastText",
      }
    : {
        borderColor: LENDY_PRIMARY_COLOR,
      };

  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={1} container direction="row" alignItems="center">
        &nbsp;
      </Grid>
      <Grid item xs={10}>
        <MultiStateDisplayCore
          label={props.biDoc.name}
          clickable={true}
          onClick={() => {
            openDocUrl(props.biDoc.url, props.biDoc.access_token);
          }}
          {...extraProps}
        />
      </Grid>
      <Grid item xs={1}>
        {props.isSelected ? (
          <ValidatedIcon cursor="pointer" onClick={props.handleClick} />
        ) : (
          <NotYetValidatedIcon cursor="pointer" onClick={props.handleClick} />
        )}
      </Grid>
    </Grid>
  );
}
