import { Borrower } from "../../types/caseForm.type";

export const displayClientName = (
  borrowers: Borrower[],
  originalClientId: string,
  isCoBorrower: boolean
) => {
  return (
    <>
      {borrowers.map((borrower) => {
        if (isCoBorrower) {
          if (borrower.id === originalClientId) {
            return "";
          }
        } else {
          if (borrower.id !== originalClientId) {
            return "";
          }
        }
        return (
          <div key={"borrower-" + borrower.id} className="title">
            {borrower.firstName} {borrower.lastName}
          </div>
        );
      })}
    </>
  );
};
