import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routeType } from "../../routes";
import { createAccessToken } from "../../services/bi.api";
import { getMe } from "../../services/user.api";
import { enqueueSnackbar } from "notistack";

import { userUpdate } from "../../slice/login.slice";
import "../../styles/client/budgetInsightWebView.scss";
import Loader from "../common/loading";

export default function BiIframe(props: {
  url: string;
  queryParams: URLSearchParams;
  redirectUrl: string;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [biUrl, setBiUrl] = useState<string>();

  useEffect(() => {
    const callBack = function (event: any) {
      if (event.origin === process.env.REACT_APP_GLITCH_DOMAIN) {
        window.location.href = props.url + "?" + props.queryParams.toString();
      }
    };
    window.addEventListener("message", callBack);
    setTimeout(() => {
      //this is here to handle the fact that the cookie checker server can be reached (it's a glitch.com code for the moment)
      //  we want to wait a little moment to be sure that the glith server can wake up  the first time it used
      if (!biUrl) {
        setBiUrl(`${props.url}?${props.queryParams.toString()}`);
      }
    }, 10000);
  }, []);

  const handleNewAccount = async (
    cdtId: string,
    code?: string,
    connectionId?: string
  ) => {
    if (!connectionId && !code) {
      enqueueSnackbar(t("errors.NO_BI_CODE"), { variant: "error" });
    } else {
      try {
        await createAccessToken(cdtId, code, connectionId);
        const payload = await getMe();
        dispatch(userUpdate(payload));
      } catch (err) {
        console.log("error BI connection : ", err);
        enqueueSnackbar("BI connection NoK");
      }
    }
  };
  return (
    <div className="iframe-container">
      <iframe
        id={"test-cookie"}
        title={"test-cookie"}
        width={"0%"}
        height={"0%"}
        src={process.env.REACT_APP_GLITCH_URL}
      ></iframe>
      {!biUrl && <Loader />}
      {biUrl && (
        <iframe
          title="bi-iframe"
          width={"100%"}
          height={"100%"}
          src={biUrl}
          style={{ overflow: "hidden" }}
          id="bi-iframe"
          onLoad={async (e: any) => {
            let targetUrl = "";
            try {
              targetUrl = e.target.contentWindow.window.location.href;
            } catch (err) {
              console.log("( => errr) ", err);
            }
            if (targetUrl && targetUrl.startsWith(props.redirectUrl)) {
              const url = new URL(targetUrl);
              const code = url.searchParams.get("code");
              const state = url.searchParams.get("state");
              const connectionId = url.searchParams.get("connection_id");

              if (state) {
                const [cdtId, userId, iframe] = state?.split("_");
                if (!iframe) {
                  return;
                }
                if (cdtId) {
                  await handleNewAccount(
                    cdtId,
                    code || undefined,
                    connectionId || undefined
                  );
                }
                navigate(routeType.client + "/" + userId);
              } else {
                navigate(routeType.home);
              }
            }
          }}
        ></iframe>
      )}
    </div>
  );
}
