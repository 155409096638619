import AccountsList from "../pages/legacy/accountsList";
import MagicLinkForm from "../pages/magicLinkForm";
import Login from "../pages/login";
import BudgetInsightWebView from "../pages/client/budgetInsightWebView";
import BudgetInsightWebViewResult from "../pages/client/budgetInsightWebViewResult";
import CreateUser from "../pages/createUser";
import { Roles } from "../types/role.enum";
import ProtectedRoute from "../pages/ProtectedRoutes";
import UserList from "../pages/admin/userList";
import ClientList from "../pages/legacy/clientList";
import DocumentList from "../pages/legacy/documentList";
import TransactionsList from "../pages/legacy/transactionsList";
import Home from "../pages/home";
import { NoPages } from "../pages/noPages";
import CaseForm from "../pages/case/caseForm";
import CaseList from "../pages/case/caseList";
import CaseDocumentForm from "../pages/case/caseDocumentForm";
import CaseRecap from "../pages/case/caseRecap";
import { ClientInformations } from "../pages/client/clientInformations";
import { ClientCgu } from "../pages/client/clientCgu";
import ClientInvite from "../pages/client/clientInvite";
import Client from "../pages/client/client";
import { ClientDocumentTypeList } from "../pages/client/clientDocumentTypeList";
import UploadDocument from "../pages/client/uploadDocument";
import UploadFile from "../pages/client/uploadFile";
import ClientBi from "../pages/client/clientBi";
import CaseDocumentValidation from "../pages/case/caseDocumentValidation";
import ClientStatut from "../pages/client/clientStatut";
import DocumentInfo from "../pages/documentInfo";
import EditUser from "../pages/admin/editUser";
import UploadIdentityFile from "../pages/client/uploadIdentityFile";

export enum routeType {
  account = "/account",
  login = "/login",
  home = "/",
  webview = "/webview",
  webviewResolve = "/webview/resolve",
  userCreate = "/user/create",
  userList = "/user/list",
  clientList = "/client/list",
  document = "/document",
  transaction = "/transaction",
  case = "/case",
  caseCreate = "/case",
  caseUpdate = "/case/:id",
  caseList = "/case/list",
  caseRecap = "/case/:id/document",
  client = "/client",
  upload = "/upload",
  uploadDocument = "/upload/document",
  uploadIdentityDocument = "/upload/identity-document",
  userEdit = "/user/edit",
}

const mainRoutes = [
  {
    path: routeType.home,
    element: <Home />,
  },
  {
    path: routeType.userEdit + "/:id",
    element: (
      <ProtectedRoute profiles={[Roles.ADMIN]}>
        <EditUser />
      </ProtectedRoute>
    ),
  },
  {
    path: routeType.document + "/info/:id",
    element: <DocumentInfo />,
  },
  {
    path: routeType.document + "/info/report/:id",
    element: <DocumentInfo isReport />,
  },
  {
    path: routeType.document + "/verification/:id",
    element: <DocumentInfo withCheck />,
  },
  {
    path: routeType.document + "/verification/report/:id",
    element: <DocumentInfo isReport withCheck />,
  },
  {
    path: routeType.account,
    children: [
      {
        path: "",
        element: <AccountsList />,
      },
      {
        path: ":userId",
        element: <AccountsList />,
      },
    ],
  },
  {
    path: routeType.transaction,
    element: <TransactionsList />,
  },
  {
    path: routeType.login,
    children: [
      {
        path: ":token",
        element: <Login />,
      },
      {
        path: "",
        element: <MagicLinkForm />,
      },
    ],
  },
  {
    path: routeType.webview,
    children: [
      { path: "", element: <BudgetInsightWebView /> },
      {
        path: routeType.webviewResolve,
        element: <BudgetInsightWebViewResult />,
      },
    ],
  },
  {
    path: routeType.userCreate,
    element: (
      <ProtectedRoute profiles={[Roles.ADMIN, Roles.USER]}>
        <CreateUser />
      </ProtectedRoute>
    ),
  },
  {
    path: routeType.userList,
    element: (
      <ProtectedRoute profiles={[Roles.ADMIN]}>
        <UserList />
      </ProtectedRoute>
    ),
  },
  {
    path: routeType.clientList + "/:userId",
    element: (
      <ProtectedRoute profiles={[Roles.ADMIN, Roles.USER]}>
        <ClientList />
      </ProtectedRoute>
    ),
  },
  {
    path: routeType.document,
    children: [
      {
        path: "",
        element: <DocumentList />,
      },
      {
        path: ":userId",
        element: <DocumentList />,
      },
    ],
  },
  {
    path: "case",
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute profiles={[Roles.ADMIN, Roles.USER]}>
            <CaseForm />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id",
        element: (
          <ProtectedRoute profiles={[Roles.ADMIN, Roles.USER]}>
            <CaseForm />
          </ProtectedRoute>
        ),
      },
      {
        path: "list",
        element: (
          <ProtectedRoute profiles={[Roles.ADMIN, Roles.USER]}>
            <CaseList />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/document/confirm",
        element: (
          <ProtectedRoute profiles={[Roles.ADMIN, Roles.USER]}>
            <CaseRecap />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/document",
        element: (
          <ProtectedRoute profiles={[Roles.ADMIN, Roles.USER]}>
            <CaseDocumentForm />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/validate",
        element: (
          <ProtectedRoute profiles={[Roles.ADMIN, Roles.USER]}>
            <CaseDocumentValidation />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NoPages />,
  },
  {
    path: "client",
    children: [
      {
        path: ":id/informations",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkId checkCgu>
            {" "}
            <ClientInformations />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/cgu",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkId>
            {" "}
            <ClientCgu />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/invite",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkId checkCgu>
            {" "}
            <ClientInvite />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkId checkCgu>
            <Client />{" "}
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/groupe/:groupId",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkId checkCgu>
            <ClientDocumentTypeList isCoBorrower={false} />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/co-emprunteur/groupe/:groupId",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkId checkCgu>
            <ClientDocumentTypeList isCoBorrower={true} />
          </ProtectedRoute>
        ),
      },
      {
        path: "upload/:cdtId",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkCgu>
            <UploadFile />
          </ProtectedRoute>
        ),
      },
      {
        path: "upload-identity/:cdtId",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkCgu>
            <UploadIdentityFile fromQrCode={false} />
          </ProtectedRoute>
        ),
      },
      {
        path: ":id/statut",
        element: (
          <ProtectedRoute profiles={[Roles.CLIENT]} checkId checkCgu>
            <ClientStatut />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: routeType.uploadDocument,
    element: <UploadDocument />,
  },
  {
    path: routeType.uploadIdentityDocument,
    element: <UploadIdentityFile fromQrCode={true} />,
  },
];

export default mainRoutes;
