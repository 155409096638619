import { Box, Grid, lighten, useTheme } from "@mui/material";

export default function CaseStepper(props: {
  nbSteps: number;
  position?: number;
}) {
  const theme = useTheme();

  const arraySteps = new Array(props.nbSteps).fill(1);
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={"center"}
      spacing={1}
      mb={2}
    >
      {arraySteps.map((_, index) => (
        <Grid item>
          <Box
            sx={{
              width: "110px",
              height: "10px",
              borderRadius: "5px",
              backgroundColor:
                index === props.position
                  ? theme.palette.primary.main
                  : lighten(theme.palette.primary.main, 0.8),
            }}
          >
            &nbsp;
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
